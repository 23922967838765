  import { Injectable,Inject,PLATFORM_ID } from "@angular/core";
  import { isPlatformBrowser,Location } from '@angular/common';
  import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
  import { SingletonService } from "./singleton.service";
  import { environment }     from '../../environments/environment';
  import * as _ from 'lodash';
  declare var window:any;
  @Injectable()
  export class EURedirectService  {
    countryData:Array<any>=environment.countryJsonData;
    constructor(
      public singletonServ:SingletonService, 
      private router: Router, 
      public location: Location,
      @Inject(PLATFORM_ID) public platformId: Object
      ) {
  
      }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
      const url: string = state.url;
      return this.redirect(url);
    }
  
    redirect(url: string): boolean {
      const baseSite = this.singletonServ.catalogVersion;
      if(!baseSite){
        if (this.getBrowserSessionData("prefered_lng")){
          const _data = JSON.parse(this.getBrowserSessionData("prefered_lng"));
           if(_data.isoCode=='EU'){
             return true;
           } 
        }
      }else{
        const _hostname = this.location['_locationStrategy']._platformLocation._location.hostname;
        const _index = _.findIndex(this.countryData,(o)=>{ 
              return (o.hostname == _hostname||o.serverName == _hostname);
        });
        if(_index !=-1){
          this.singletonServ.catalogVersion = this.countryData[_index];
          this.setBrowserSessionData("prefered_lng", JSON.stringify(this.countryData[_index]));
          const _country= this.countryData[_index];
          if(_country.isoCode=='EU'){
            return true;
          } 
        }
      } 
      // Navigate to the login page with extras
      this.router.navigate(["store","404"]);
      return false;
    }
    getBrowserSessionData(key){
      if (isPlatformBrowser(this.platformId)) {
        return this.singletonServ.getStoreData(key);
      }
    }
    setBrowserSessionData(key,data){
      if (isPlatformBrowser(this.platformId)) {
         this.singletonServ.setStoreData(key,data);
      }
    }
    removeBrowserSessionData(key){
      if (isPlatformBrowser(this.platformId)) {
        this.singletonServ.removeItem(key);
      }
    }
  }