import { Injectable,Inject,PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser,Location } from '@angular/common';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SingletonService } from "./singleton.service";
import { environment }     from '../../environments/environment';
import * as _ from 'lodash';
declare var window:any;
@Injectable()
export class USRedirectService  {
  countryData:Array<any>=environment.countryJsonData;
  constructor(
    public singletonServ:SingletonService, 
    private router: Router, 
    public location: Location,
    @Inject(PLATFORM_ID) public platformId: Object
    ) {

    }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const _hostname = window.location.hostname;
    if(_hostname){
        const _index = _.findIndex(this.countryData,(o)=>{ 
              return (o.hostname == _hostname||o.serverName == _hostname);
        });

         if (this.getBrowserSessionData("prefered_lng")){
          const _data = JSON.parse(this.getBrowserSessionData("prefered_lng"));
           if(_data.isoCode !='US'){
            this.router.navigate(["store","404"]);
            return false;
           } 
        }else if(_hostname.indexOf('.com') ==-1){
          this.router.navigate(["store","404"]);
          return false;
        } else if(_index !=-1){
          this.singletonServ.catalogVersion = this.countryData[_index];
          this.setBrowserSessionData("prefered_lng", JSON.stringify(this.countryData[_index]));
          const _country= this.countryData[_index];
          if(_country.isoCode !='US'){
            this.router.navigate(["store","404"]);
            return false;
          } 
        }
   } else if (this.getBrowserSessionData("prefered_lng")){
    const _data = JSON.parse(this.getBrowserSessionData("prefered_lng"));
     if(_data.isoCode !='US'){
      this.router.navigate(["store","404"]);
      return false;
     } 
  } 
  return true;
  }

  redirect(url: string): boolean {
    const baseSite = this.singletonServ.catalogVersion;
    if(!baseSite){
      if (this.getBrowserSessionData("prefered_lng")){
        const _data = JSON.parse(this.getBrowserSessionData("prefered_lng"));
         if(_data.isoCode=='US'){
          this.router.navigate(["store","404"]);
          return false;
         } 
      }
    }else{
      const _hostname = this.location['_locationStrategy']._platformLocation._location.hostname;
      const _index = _.findIndex(this.countryData,(o)=>{ 
            return (o.hostname == _hostname||o.serverName == _hostname);
      });
      if(_index !=-1){
        this.singletonServ.catalogVersion = this.countryData[_index];
        this.setBrowserSessionData("prefered_lng", JSON.stringify(this.countryData[_index]));
        const _country= this.countryData[_index];
        if(_country.isoCode !='US'){
          this.router.navigate(["store","404"]);
          return false;
        } 
      }
    } 
    // Navigate to the login page with extras
  
    return true;
  }
  getBrowserSessionData(key){
    if (isPlatformBrowser(this.platformId)) {
      return this.singletonServ.getStoreData(key);
    }
  }
  setBrowserSessionData(key,data){
    if (isPlatformBrowser(this.platformId)) {
       this.singletonServ.setStoreData(key,data);
    }
  }
  removeBrowserSessionData(key){
    if (isPlatformBrowser(this.platformId)) {
      this.singletonServ.removeItem(key);
    }
  }
}