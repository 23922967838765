<app-mb-bread-crumb *ngIf="breadcrumb" [breadcrumb]="breadcrumb"></app-mb-bread-crumb>
<div class="rd-marble-container">
  <picture>
    <source media="(max-width: 874px)" srcset="https://media.moltonbrown.co.uk/i/moltonbrown/mb-marble-background?fmt=auto&w=874&sm=aspect&aspect=3:1&$poi$&  1x,
    https://media.moltonbrown.co.uk/i/moltonbrown/mb-marble-background?fmt=auto&w=1748&sm=aspect&aspect=3:1&$poi$& 2x">
    <img class="img-fluid w-100"
    src="https://media.moltonbrown.co.uk/i/moltonbrown/mb-marble-background?fmt=auto&w=1440&sm=aspect&aspect=7.46:1"
    srcset="https://media.moltonbrown.co.uk/i/moltonbrown/mb-marble-background?fmt=auto&w=1440&sm=aspectc&aspect=7.46:1 1x,
          https://media.moltonbrown.co.uk/i/moltonbrown/mb-marble-background?fmt=auto&w=2880&sm=aspect&aspect=7.46:1 2x"
    alt="{{singletonServ?.translateData?.yourBag}}">
  </picture>
  <h1 class="rd-marble-title">{{bannerTitle}}</h1>
  </div>
<div class="my-account">
  <div *ngIf="!pageLoad">
   <section *ngIf="favouriteList">
  <div class="favourite" *ngIf="favouriteList.length !==0">
         <div class="row m-0">
         <div class="text-center col-sm-6 col-md-3 favourite_product" *ngFor="let product of favouriteList | slice:0:sliceCards">
                <div >
                <a routerLink="{{getRouterPath(product)}}" routerLinkActive="active" (click)="onShowProduct($event,product)">
                 <div class="prod-img">
                   <picture>
                     <source media="(max-width: 874px)"
                         srcset="{{product?.amplienceImageName}}?fmt=auto&w=308 1x, {{product?.amplienceImageName}}?fmt=auto&w=616 2x">
                     <source media="(min-width: 769px)"
                       srcset="{{product?.amplienceImageName}}?fmt=auto&w=308 1x, {{product?.amplienceImageName}}?fmt=auto&w=616 2x">
                     <img class="img-fluid w-100"
                     srcset="{{product?.amplienceImageName}}?fmt=auto&w=308"
                         srcset="{{product?.amplienceImageName}}?fmt=auto&w=308 1x, {{product?.amplienceImageName}}?fmt=auto&w=616 2x"
                         alt="{{product?.productDisplayName}}"
                       crossorigin="anonymous" />
                  </picture>
                 </div>
                </a>
                <p class="productDisplayName" [innerHtml]="product.productDisplayName"></p>
                <div class="pricePoint text-center" *ngIf="product.stock">

                         <h5 *ngIf="!product.originalPrice">{{
                           product.price ? product.price.formattedValue : "loading"
                         }}</h5>
                         <h5
                           class="price-format-discount pricePoint-discount"
                           *ngIf="product.originalPrice"
                         >
                           <del class="del-retail-price">{{
                             product.originalPrice
                           }}</del>
                           &nbsp;
                           <span class="discount-price" >{{
                             product.price ? product.price.formattedValue : "loading"
                           }}</span>
                         </h5>

                       <ng-template #outOfStock>
                         <div class="product_avial">
                           <span>
                             {{singletonServ.translateData?.quickViewData?.outOfStk}}
                           </span>
                         </div>
                       </ng-template>
                     </div>
                     <div *ngIf="isocode==='de' || isocode==='eu'"  class="fav-de-base-price">
                       <span class="product-base-price" *ngIf="product.basePriceForProduct" [innerHtml]="product.basePriceForProduct">
                       </span>
                     </div>

                </div>
                <div *ngIf="product.stock ;else outOfStock">
                  <div *ngIf="product.price" class="d-flex justify-content-center">
                     <button
                           class="btn-accent add-btn" data-bs-toggle="modal" data-bs-target="#viewBagModal"
                           *ngIf="product.stock.stockLevelStatus !=='outOfStock' ;else outOfStock"
                           (click)="addToBasket(product);">{{singletonServ.translateData.favourites.addToBasket}}&nbsp;&nbsp;<i class="icon-plus-lg prd-add-icon desktop-only"></i><i class="icon-plus-sm prd-add-icon mob-only"></i>
                       </button>
                     </div>
                </div>
                <p class="successMessage mob-only" id="{{ 'successMessage' + product.code }}" routerLink="/store/mbcart/mbBasket">{{singletonServ.translateData.addMessage1}}
                 <a routerLink="/store/mbcart/mbBasket">{{singletonServ.translateData.addMessage2}}</a>
               </p>

                <ng-template #outOfStock>
                   <p class="product_avial">
                     {{singletonServ.translateData?.quickViewData?.outOfStk}}
                   </p>
                </ng-template>
          </div>
          <div class="viewMoreSection mt-3">
           <div class="view_all_btm d-flex flex-column">
             <div class="backtotop underline d-flex justify-content-center" *ngIf="sliceCards>12" (click)="backtotop();" role="button" aria-label="Back to top">{{singletonServ.translateData?.categorylistProducts?.backtotopbtn}}</div>
             <div class="showcurcount d-flex justify-content-center" *ngIf="totalProducts>0 && this.siteSpecific">{{singletonServ.translateData.categorylistProducts.showing}} {{currentloadedproductcount}} {{singletonServ.translateData.categorylistProducts.outof}} {{totalProducts}}</div>
             <!--DE translation-->
     <div class="showcurcount d-flex justify-content-center" *ngIf="totalProducts>0 &&  !this.siteSpecific"> {{currentloadedproductcount}} {{singletonServ.translateData.categorylistProducts.outof}} {{totalProducts}} {{singletonServ.translateData.categorylistProducts.showing}}</div>
             <div class="d-flex justify-content-center" *ngIf="sliceCards>=12 && favouriteList?.length>sliceCards"><button class="View_all_products" (click)="showCards();" type="button">{{singletonServ.translateData?.categorylistProducts?.viewmorebtn}}</button></div>
         </div>
       </div>
          </div>
  </div>
 </section>
 </div>
</div>
<div [ngClass]="{'overlay-bg-show':pageLoad,'overlay-bg-hide':!pageLoad}" >
  <div class="loading-gif m-auto text-center mt-20">
    <span class="loading-txt" *ngIf="singletonServ.translateData"> {{singletonServ.translateData.loadingTxt.loading}} </span>
    <img class="img-fluid pt-40" src="/assets/Redesign/loading-icon.gif" alt="loading-icon"></div>
 </div>
