<!-- Footer Refactor Code [..] -->
 <!-- <pre>{{ footerData | json }}</pre> -->
 <footer class="mb_section__footer">
  <div class="mb__container">
    <div class="mb_section__footer_top">
      <div class="mb_section__footer_navs_items">
        <div 
          *ngFor="let column of footerData?.columns; let i = index"
          class="mb_section__footer_navs_item"> 
          <h3>{{ column?.heading }}</h3>
          <ul>
            <li *ngFor="let link of column?.links; let i = index">
              <ng-container *ngIf="link?.isCountrySelector">
                <ng-container *ngFor="let data of countries; let k = index">
                  <button 
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#countryChangeModal"
                    *ngIf="data.current">
                    {{ singletonServ?.translateData?.menu?.changeCountry }}

                    <img 
                      ngSrc="{{ '/assets/Redesign/flag-' + data.isoCode + '.svg' }}" 
                      alt="{{  data.name + ' flag' }}" 
                      width="24" 
                      height="16"
                    />
                  </button>
                </ng-container>
              </ng-container>

              <a 
                *ngIf="!link?.isCountrySelector" 
                [attr.href]="link?.link" 
                (click)="onLinkClick($event, link?.link)"
              >
                {{ link?.title }}
              </a>
            </li>
          </ul>          
        </div>
      </div>
    </div>
    <div class="mb_section__footer_bottom">
      <div class="mb_section__footer_bottom_inner">
        <div class="mb_section__footer_social_icons">
          <ul>
            <li>
              <a tabindex="0"
                href="https://www.facebook.com/{{(singletonServ.catalogVersion&&singletonServ.catalogVersion.isoCode==='US')?'moltonbrownusa': 'moltonbrown'}}"
                target="_blank">
                <img width="28" height="28" ngSrc="../../../assets/icons/facebook.svg"
                  alt="{{singletonServ?.translateData?.altText?.socialIconsFacebook}}" />
              </a>
            </li>
            <li>
              <a tabindex="0"
                href="https://www.instagram.com/{{(singletonServ.catalogVersion&&singletonServ.catalogVersion.isoCode==='US')?'moltonbrownusa': 'moltonbrown'}}/"
                target="_blank">
                <img width="28" height="28" ngSrc="../../../assets/icons/instagram.svg"
                  alt="{{singletonServ?.translateData?.altText?.socialIconsInstagram}}" />
              </a>
            </li>
            <li>
              <a tabindex="0"
                href="https://twitter.com/{{(singletonServ.catalogVersion&&singletonServ.catalogVersion.isoCode==='US')?'moltonbrownusa': 'moltonbrownuk'}}"
                target="_blank" class="text-white social-icon" role="button"><img width="28" height="28"
                  ngSrc="../../../assets/icons/twitter.svg"
                  alt="{{singletonServ?.translateData?.altText?.socialIconsTwitter}}" /></a>
            </li>
            <li>
              <a tabindex="0" href="https://www.youtube.com/user/MoltonBrownUK" class="text-white social-icon" target="_blank"
                role="button"><img width="28" height="28" ngSrc="../../../assets/icons/youtube.svg"
                  alt="{{singletonServ?.translateData?.altText?.socialIconsYoutube}}" /></a>
            </li>
            <li>
              <a tabindex="0" href="https://in.pinterest.com/moltonbrownuk/" class="text-white social-icon" target="_blank"
                role="button"><img width="28" height="28" ngSrc="../../../assets/icons/pinterest.svg"
                  alt="{{singletonServ?.translateData?.altText?.socialIconsPintrest}}" /></a>
            </li>
          </ul>
        </div>
        <div class="mb_section_logo_wrap mb_section__footer_logo_mobile">
          <div class="mb_section__logo" [innerHTML]="logo"></div>
        </div>
        <div class="mb_section__footer_payment_icons">
          <ul>
            <li><img alt="visa" width="40" height="28" ngSrc="/assets/Redesign/Visa.svg"></li>
            <li><img alt="Mastercard" width="40" height="28" ngSrc="/assets/Redesign/Mastercard.svg"></li>
            <li><img alt="amex" width="40" height="28" ngSrc="/assets/Redesign/Amex.svg"></li>
            <li><img alt="paypal" width="40" height="28" ngSrc="/assets/Redesign/PayPal.svg"></li>
            <li><img alt="applepay" width="40" height="28" ngSrc="/assets/Redesign/ApplePay.svg"></li>
            <li *ngIf="(isocode === 'DE' || isocode === 'GB' || isocode === 'US')">
              <img alt="klarna" width="40" height="28" ngSrc="/assets/Redesign/Klarna.svg">
            </li>
            <li><img alt="moltonbrown" width="40" height="28" ngSrc="/assets/Redesign/MoltonBrown.svg"></li>
          </ul>
        </div>
        <div class="mb_section__footer_copyrights">
          <div 
            [innerHTML]="footerData?.copyrightInformation?.copyrightText"
            *ngIf="footerData?.copyrightInformation?.copyrightText" 
            class="mb_section__footer_copyrights_text"></div>
          <div 
            [innerHTML]="footerData?.copyrightInformation?.allRightReserved"
            *ngIf="footerData?.copyrightInformation?.allRightReserved" 
            class="mb_section__footer_copyrights_text"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="mb_section_logo_wrap">
    <div class="mb_section__logo" [innerHTML]="logo"></div>
  </div>
</footer>   

<div
  class="modal"
  tabindex="-1"
  class="modal"
  id="countryChangeModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">

        <button
          type="button"
          class="close"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <i class="icon-close-lg"></i>
        </button>
      </div>
      <div class="modal-body">
        <h2>
          {{singletonServ?.translateData?.changeCountryModal?.title}}
        </h2>
        <p>
          {{singletonServ?.translateData?.changeCountryModal?.description}}
        </p>
      </div>
      <div class="modal-footer">
        <ul>
          <li
            class="country-change"
            *ngFor="let data of countries; let k = index"
            [hidden]="data.current"
            data-bs-dismiss="modal"
            aria-label="Close"
            (click)="onCancelModal(true, data)"
          >
          <a class="sub-item" *ngIf="country_code==='DE' && data.german_trans ">
            {{ data.german_trans }}
          </a>
          <a class="sub-item" *ngIf="country_code!=='DE'">
            {{ data.name }}
          </a>

          </li>
        </ul>
      </div>
    </div>
  </div>
</div>