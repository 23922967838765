import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SEOService } from "../services/seo.service";
import { Router } from '@angular/router';

declare var window:any;

@Component({
  selector: 'app-exclusive-games',
  templateUrl: './exclusive-games.component.html',
  styleUrls: ['./exclusive-games.component.scss']
})
export class ExclusiveGamesComponent implements OnInit, AfterViewInit {
  isUKSite: boolean = window.location.hostname && window.location.hostname.includes('moltonbrown.co.uk') ? true : false ;
  isDESite: boolean = window.location.hostname && window.location.hostname.includes('moltonbrown.de') ? true : false ;
  isUSSite: boolean = window.location.hostname && window.location.hostname.includes('moltonbrown.com') ? true : false ;
  constructor(private seoService: SEOService,public router: Router,
    @Inject(DOCUMENT) public dom) { }

  ngOnInit() {
    if(this.isUKSite){
      this.dom.getElementById('mbrr-iframe').src = "https://uk.moltonbrown.game/";
    } else if (this.isDESite){
      this.dom.getElementById('mbrr-iframe').src = "https://de.moltonbrown.game/";
    } else if (this.isUSSite){
      this.dom.getElementById('mbrr-iframe').src = "https://us.moltonbrown.game/";
    }
    
  }

  ngAfterViewInit() {
    // UK site META
    if (this.isUKSite) {
      this.seoService.setTabTitle('Exclusive Online Game | Win Daily Prizes | Molton Brown UK');
      this.seoService.updateMetaTitle('Exclusive Online Game | Win Daily Prizes | Molton Brown UK');
      this.seoService.updateDescription('Play Molton Brown’s exclusive online Christmas game for a chance to win daily prizes. Complete our fun game and receive an entry into our Grand Prize draw.');
    }
    
    // DE site META
    else if (this.isDESite){
      this.seoService.setTabTitle('Exklusives Online-Spiel | Tägliche Preise | Molton Brown DE');
      this.seoService.updateMetaTitle('Exklusives Online-Spiel | Tägliche Preise | Molton Brown DE');
      this.seoService.updateDescription('Spielen Sie Molton Browns exklusives Online-Spiel & gewinnen Sie 1 von 10 täglichen Preisen. Zum Schluss erhalten Sie ein Ticket für die große Verlosung.');
    }
    // US site META
    else if(this.isUSSite) {
      this.seoService.setTabTitle('Exclusive Online Game | Win Daily Prizes | Molton Brown US');
      this.seoService.updateMetaTitle('Exclusive Online Game | Win Daily Prizes | Molton Brown US');
      this.seoService.updateDescription('Play Molton Brown’s exclusive online Christmas game for a chance to win daily prizes. Complete our fun game and receive an entry into our Grand Prize draw.');
    }
    else {
      this.router.navigate(['store','404']);
    }

// re-sizer functionality
(function () {
    var iframe = document.getElementById('mbrr-iframe'),
      prevHeight,
      eventMethod = window.addEventListener ? "addEventListener" : "attachEvent",
      eventer = window[eventMethod],
      messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

  eventer(messageEvent,function(e) {
    if (!iframe) {
      iframe = document.getElementById('mbrr-iframe');
    }
     
    if (e.data.event == 'update:height' && prevHeight !== e.data.height) {
      iframe.style.height = e.data.height + 'px';
      prevHeight = e.data.height;
    }
  },false);
})();
    
  }

}
