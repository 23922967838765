import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CollectionCategorylpRoutingModule } from './collection-categorylp-routing.module';
import { CollectionCategorylpComponent } from './collection-categorylp.component';


@NgModule({
  declarations: [
    CollectionCategorylpComponent
  ],
  imports: [
    CommonModule,
    CollectionCategorylpRoutingModule
  ]
})
export class CollectionCategorylpModule { }
