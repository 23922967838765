import {
  Inject,
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
  OnDestroy,
  PLATFORM_ID,
} from "@angular/core";
import { FooterComponentService } from "./footer.service";
import { SingletonService } from "../../services/singleton.service";
import {Router, ActivatedRoute} from '@angular/router';
import { Subscription } from 'rxjs';
import { AmplienceService } from 'src/app/services/amplience.service';
import { DeliveryKey } from '../../amplience.constant';
import { environment } from "../../../environments/environment";
import { HeaderComponentService } from "../header/header.service";
import { isPlatformBrowser } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'], 
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit, AfterViewInit,OnDestroy {
  isAnnexCloudEnabled: boolean;
  countries = environment.countryJsonData;
  currentCountryCode: string;
  country_code: string;
  catalogDataSubscription: Subscription;
  footerAmplienceData: any ;
  countryText: any;
  enableJP: boolean;
  testDKey: any;
  previewId: any;
  VSEId:any;
  isocode:string;
  footerData: any = null;
  logo: SafeHtml;

  constructor (
    public router: Router,
    private headerServ: HeaderComponentService,
    public singletonServ: SingletonService,
    private ampService: AmplienceService,
    public route: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: Object 
  ) {
    const baseSite = this.singletonServ.catalogVersion;
    this.country_code = baseSite.isoCode;
    this.isocode = baseSite.isoCode;
  }

  async ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.previewId = params['previewId'];
      this.testDKey = params['testDKey'];
      this.VSEId = params['VSE'];
    });

    // Get SVG text from assets folder. (refactor code) [..]
    this.http.get('assets/Redesign/mb_footer_logo_refactor.svg', { responseType: 'text' })
    .subscribe(data => {
      this.logo = this.sanitizer.bypassSecurityTrustHtml(data);
    });

    // get footer content form amplience (refactor code) [..]
    if (this.previewId && this.testDKey === 'footer-refactor') {
      this.ampService.getContentByID(this.previewId, this.VSEId).subscribe(resp => {
        this.footerData = resp['content'] ?? null
      });
    } else if (!this.previewId) {
      this.ampService.getContentByKey(DeliveryKey.footerRefactor[this.isocode]).subscribe(resp => {
        this.footerData = resp['content'] ?? null
      });
    }
  }

 ngAfterViewInit(): void {
 this.catalogDataSubscription = this.singletonServ.getCatalogData().subscribe(data=>{
    console.log("Footer Subscriber resp", data)
   if(data)this.isAnnexCloudEnabled = data.enableAnnexCloud; // to be used for showing "Reward" link
 })
 }

 ngOnDestroy() {
   if(this.catalogDataSubscription) this.catalogDataSubscription.unsubscribe();
 }


  // external redirect and internal route for foooter nav links. (refactor code) [..]
  onLinkClick(event: MouseEvent, url: string): void {
    event.preventDefault();
    event.stopPropagation();

    console.log(url, event.ctrlKey, event.metaKey, event.button)

    if ((event.ctrlKey || event.metaKey) && event.button === 0) {
      window.open(url, '_blank');
    } else {
      if (url.startsWith('http')) {
        window.location.href = url;
      } else {
        this.router.navigate([url]);
      }
    }
  }

 onCancelModal(bol, data) {
  
  if (data.isoCode != "JP") {
    this.countryText = data;
    this.enableJP = false;
  } else {
    this.countryText = data;
    this.enableJP = true;
  }
  if (!this.enableJP) {
    const baseSite = this.singletonServ.catalogVersion;
    let user;
    
    if (this.getBrowserSessionData(baseSite.reg)) {
      user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
    }
    if (bol) {
      this.setBrowserSessionData(
        "prefered_lng",
        JSON.stringify(this.countryText)
      );
      const _isoCode = this.countryText.isoCode;
      this.countries.map((obj) => {
        if (_isoCode == obj.isoCode) {
          obj["current"] = true;
        } else {
          obj["current"] = false;
        }
      });
      const _obj = {
        baseSiteChange: true,
      };
      if (this.getBrowserSessionData(baseSite.reg)) {
        if (!this.getBrowserSessionData(this.countryText.reg)) {
          const currentUser = { email: "" };
          currentUser.email = user.email;
          this.setBrowserSessionData(
            this.countryText.reg,
            JSON.stringify(currentUser)
          );
          if (user.token) {
            this.headerServ
              .getUserData(baseSite, user.token, user.email)
              .subscribe(
                (response) => {
                  this.singletonServ.loggedIn = true;
                  const userDtls = JSON.parse(
                    this.getBrowserSessionData(this.countryText.reg)
                  );
                  userDtls["isExpressCheckout"] = response[
                    "isExpressCheckout"
                  ]
                    ? true
                    : false;
                  this.setBrowserSessionData(
                    this.countryText.reg,
                    JSON.stringify(currentUser)
                  );
                },
                (err) => {}
              );
          }
          this.singletonServ.catalogVersion = JSON.parse(
            this.getBrowserSessionData("prefered_lng")
          );
          this.singletonServ.sendMessage(_obj);
          this.goToStore();
        } else {
          this.singletonServ.catalogVersion = JSON.parse(
            this.getBrowserSessionData("prefered_lng")
          );
          this.singletonServ.sendMessage(_obj);
          this.goToStore();
        }
      } else {
        this.singletonServ.catalogVersion = JSON.parse(
          this.getBrowserSessionData("prefered_lng")
        );
        this.singletonServ.sendMessage(_obj);
        this.goToStore();
      }
    }
  } else {
    window.location.href = this.countryText.query;
  }
}
getBrowserSessionData(key) {
  if (isPlatformBrowser(this.platformId)) {
    return this.singletonServ.getStoreData(key);
  }
}
setBrowserSessionData(key, data) {
  if (isPlatformBrowser(this.platformId)) {
    this.singletonServ.setStoreData(key, data);
  }
}
goToStore() {
  const baseSite = this.singletonServ.catalogVersion;
  if (baseSite) {
    this.singletonServ.alarmTime = undefined;
    this.singletonServ.sessionStarts = false;
    if (this.getBrowserSessionData(baseSite.csCustomer)) {
      if (baseSite.isoCode == "GB") {
        this.singletonServ.alarmTime = undefined;
        this.singletonServ.sessionStarts = false;
        const ukurl = baseSite.query + "?ASM=true";
        window.location.href = ukurl;
      } else {
        this.singletonServ.alarmTime = undefined;
        this.singletonServ.sessionStarts = false;
        const _biturl = baseSite.query + "&ASM=true";
        window.location.href = _biturl;
      }
    } else {
      this.singletonServ.alarmTime = undefined;
      this.singletonServ.sessionStarts = false;
      window.location.href = baseSite.query;
    }
  }
}
OnLinkClick(event,url){
  event.preventDefault();
  event.stopPropagation();
  if((event.ctrlKey && event.which === 1) || event.metaKey){        
  window.open(url);
  }else{
      this.router.navigate([url]);
  }
}
}