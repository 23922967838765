import { Component, OnInit, AfterViewInit} from '@angular/core';
import {SingletonService} from '../../../services/singleton.service';
import { DeviceDetectorService } from "ngx-device-detector";
import { Location } from "@angular/common";
import { GtmMethodService } from '../../../services/gtmmethods.service';
import { SEOService } from '../../../services/seo.service';
@Component({
  selector: 'app-collection-subcat-lp',
  templateUrl: './collection-subcat-lp.component.html',
  styleUrls: ['./collection-subcat-lp.component.scss']
})
export class CollectionSubcatLpComponent implements OnInit, AfterViewInit {
  breadcrumb: Array<any>;
  path: string;
  countryCode: string;
  constructor (
    public deviceService: DeviceDetectorService,
    public location: Location,
    public singletonServ:SingletonService,
    public gtmServe:GtmMethodService,
    private seoService: SEOService
  ){
  }
  ngOnInit() {
    const baseSite = this.singletonServ.catalogVersion;
    this.countryCode = baseSite.isoCode;
    this.path=this.location.path();
    const _pathName = this.path.split("/");
    const _pageName = _pathName[_pathName.length-1].toLowerCase();
    this.getBreadCrumbs(_pageName);
    this.seoService.createCanonicalURL(this.path);
  }
  ngAfterViewInit(){
    const _baseSite = this.singletonServ;    
    const pageType = 'Collections Landing Page';
    this.gtmServe.gtmPageCategorisation(_baseSite,pageType);
  }
  getBreadCrumbs(urlPageName){
    const isMobile = this.deviceService.isMobile();
      if(isMobile){
        if(urlPageName == 'wild-mint-lavandin'){
          if(this.countryCode == 'DE'){
            this.breadcrumb=[{name: 'Home',route:'/store/index'},{name: 'Wild Mint Lavandin Kollektion'}];
          }
          else{
            this.breadcrumb=[{name: 'Home',route:'/store/index'},{name: 'Wild Mint Lavandin Collection'}];
          }
        }
        else if(urlPageName == 'marvellous-mandarin'){
          if(this.countryCode == 'DE'){
            this.breadcrumb=[{name: 'Home',route:'/store/index'},{name: 'Marvellous Mandarin Kollektion'}];
          }
          else{
            this.breadcrumb=[{name: 'Home',route:'/store/index'},{name: 'Marvellous Mandarin Collection'}];
          }
          
        }
        
      }else{
        if(urlPageName == 'wild-mint-lavandin'){
          if(this.countryCode == 'DE'){
            this.breadcrumb=[{name: 'Wild Mint Lavandin Collection Kollektion'}];
          }
          else{
            this.breadcrumb=[{name: 'Wild Mint Lavandin Collection'}];
          }
          
        }
        else if(urlPageName == 'marvellous-mandarin'){
          if(this.countryCode == 'DE'){
            this.breadcrumb=[{name: 'Marvellous Mandarin Kollektion'}];
          }
          else{
            this.breadcrumb=[{name: 'Marvellous Mandarin Collection'}];
          }
        }
      }
  }
}
