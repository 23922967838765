import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FooterComponent} from '../footer/footer.component';
import {FooterComponentService} from '../footer/footer.service';
import { RouterModule } from '@angular/router';
import { AmplienceService } from '../../services/amplience.service';
import { HeaderComponentService } from "../header/header.service";
import { NgOptimizedImage } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgOptimizedImage,
  ],
  declarations: [FooterComponent],
  providers: [FooterComponentService,AmplienceService,HeaderComponentService],
  exports: [FooterComponent]
})
export class FooterModule { }
