<div class="mb-breadcrumb col-sm-12" *ngIf="listData">
  <a href="" class="mb-breadcrumb-indicator" (click)="goToHome()">&nbsp;<span class="brd-home" *ngIf="singletonServ.translateData">{{(singletonServ.translateData.breadcrumb.home)?singletonServ.translateData.breadcrumb.home:''}}</span></a>
  <a  href=""
     class="mb-breadcrumb-indicator" 
     *ngFor="let data of listData;let k=index;let isFirst = first" 
     [ngClass]="{
       'active-class':data?.search
      }"  
      [class.active]="isFirst"
     (click)="onbreadcrumbClick($event,data,k)" 
     >
     <span innerHtml="{{(data?.categoryDisplayName)? (data.categoryDisplayName| titlecase) : (data?.name ==='FAQs'?data?.name:data?.name | titlecase)}}"></span>
  </a>
</div>
<!-- mobile crumbs -->
<div class="bread-crumb-mob" *ngIf="listData"> 
  <ng-container  *ngFor="let data of listData;let i =index">
    <a class="category-root" *ngIf="i === listData.length-2 " (click)="onbreadcrumbClick($event,data,i)">
      <span innerHtml="{{(data?.categoryDisplayName)? (data.categoryDisplayName| titlecase) : (data?.name ==='FAQs'?data?.name:data?.name | titlecase)}}"></span>
   </a>
  </ng-container>
     
 </div>
