import { Inject,Component,ViewEncapsulation,ViewChild,ElementRef,HostListener, AfterViewInit } from '@angular/core';
import {FeatureComponentService} from "../../features/features.service";
import {MetaService} from "../../services/meta.service";
import { SingletonService } from "../../services/singleton.service";
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { GtmMethodService } from '../../services/gtmmethods.service';
import { Location, DOCUMENT } from "@angular/common";
import { environment }     from '../../../environments/environment';
declare var AmpCa: any;
declare var constructMobData:any;
declare var updateSize:any;
declare var invokeFunction:any;
@Component({
  selector: 'app-candle-care-tips',
  templateUrl: './candle-care-tips.component.html',
  styleUrls: ['./candle-care-tips.component.scss']
})
export class CandleCareTipsComponent implements AfterViewInit {
  @ViewChild("candlecaretip") candlecaretip: ElementRef;
  @HostListener("window:resize", [])
  onResizeScreen(){
    constructMobData();
    updateSize();
  }
  @HostListener("window:scroll", ["$event"])
  windowScroll() {
  }
  param1: string;
  socialShareText:any;
  twitterMessage:string;
  gmailMessage:string;
  enableSection:boolean;
  category:any;
  locationAddress:any;
  ukcountrysite:any;
  uscountrysite:any;
  enviromentSpecific:any=environment;
  constructor(
    @Inject(DOCUMENT) public dom,
    public singletonServ: SingletonService,
    public metaService: MetaService,
    public gtmServe: GtmMethodService,
    public router: Router,
    public featureServ:FeatureComponentService,
    private route: ActivatedRoute,
    public  location: Location
  ) {
    this.route.queryParams.subscribe(params => {
      this.param1 = params['api'];    
  });
   }
  
  ngAfterViewInit(){
    const baseSite = this.singletonServ.catalogVersion;
    if(baseSite){
      this.setLang(baseSite.lngCode);
   } 
  }
  setLang(lng) {
    const that=this; 
    this.featureServ.getStaticContent(lng).subscribe((response:any) => {
      const _data:Array<any> = response['feature']['candle-care-tips'];
      that.constructFeaturelayoutContent(_data);
    });
  }
  
  constructFeaturelayoutContent(_data:any){
    this.setAmpContent(_data);
  }
  getCategoryPath(obj, targetId) {
    const _slapth= obj.path+'/';
    if (obj.path === targetId || _slapth === targetId) {
      return obj;
    }
    if (obj.pages) {
      for (let item of obj.pages) {
        const check = this.getCategoryPath(item, targetId);
        if (check) {
          return check;
        }
      }
    }
    return null;
  }
  setAmpContent(obj) {
    const that=this;
    const baseSite = this.singletonServ.catalogVersion;
      AmpCa.utils = new AmpCa.Utils();
      let api;
      if(this.param1==undefined){
        api="https://c1.amplience.net";
      }
      else{
        api="https://"+this.param1;
      }
      AmpCa.utils.getHtmlServiceData({
        auth: {
          baseUrl: api,
          id: obj.content,
          store: "moltonbrown",
          templateName: obj.templateName,
          locale: baseSite.locale
        },
        callback: function(data) {
          if (data) {
            that.candlecaretip.nativeElement.innerHTML = data;
          }
          invokeFunction();
          that.socialShareText=obj;
          that.category=obj;
          that.twitterMessage=obj.TwitterMessage;
          that.gmailMessage=obj.GmailMessage;
          that.locationAddress= that.location['_platformStrategy']._platformLocation.location;
        }
      });
  }

}
