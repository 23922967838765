import { Inject,Component, OnInit,ViewEncapsulation,ViewChild,ElementRef,HostListener, AfterViewInit}from '@angular/core';
import {FeatureComponentService} from "../../features/features.service";
import {MetaService} from "../../services/meta.service";
import { SingletonService } from "../../services/singleton.service";
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { GtmMethodService } from '../../services/gtmmethods.service';
declare var AmpCa: any;
@Component({
  selector: 'app-pay-with-klarna',
  templateUrl: './pay-with-klarna.component.html',
  styleUrls: ['./pay-with-klarna.component.scss']
})
export class PayWithKlarnaComponent implements OnInit,AfterViewInit {
  @ViewChild("paywithklarna") paywithklarna: ElementRef;
  param1: string;
  constructor(
    public singletonServ: SingletonService,
    public metaService: MetaService,
    public gtmServe: GtmMethodService,
    public router: Router,
    public featureServ:FeatureComponentService,
    private route: ActivatedRoute,
  ) { 
    this.route.queryParams.subscribe(params => {
      this.param1 = params['api'];    
  });
  }

  ngOnInit() {
    this.metaService.createCanonicalURL();
  }
  ngAfterViewInit(){
    const baseSite = this.singletonServ.catalogVersion;
    if(baseSite){
      this.setLang(baseSite.lngCode);
   } 
  }
  setLang(lng) {
    const that=this; 
    this.featureServ.getStaticContent(lng).subscribe((response:any) => {
      const _data:Array<any> = response['feature']['pay-with-klarna'];
      that.constructFeaturelayoutContent(_data);
    });
  }
  
  constructFeaturelayoutContent(_data:any){
    this.setAmpContent(_data);
  }
  setAmpContent(obj) {
    const that=this;
    const baseSite = this.singletonServ.catalogVersion;
      AmpCa.utils = new AmpCa.Utils();
      let api;
      if(this.param1==undefined){
        api="https://c1.amplience.net";
      }
      else{
        api="https://"+this.param1;
      }
      AmpCa.utils.getHtmlServiceData({
        auth: {
          baseUrl: api,
          id: obj.content,
          store: "moltonbrown",
          templateName: obj.templateName,
          locale: baseSite.locale
        },
        callback: function(data) {
          if (data) {
            that.paywithklarna.nativeElement.innerHTML = data;
          }
        }
      });
  }
}
