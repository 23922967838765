export const PATH = {
  STORE_PORTAL_STORES:'/storeportal/stores',
  GWPCATEGORY:'/users/:email/carts/:cartCode/getPromoProducts?categoryCode=:categoryCode',
  REMOVE_ENTRY:"/users/:email/carts/:code/entries/:entry",
  UPDATE_ENNTRY:"/users/:email/carts/:code/entries/:entry/?fields=FULL",
  VERIFY_CATEGORY:"/products/search?query=:relevance:allCategories:=:categoryCode&pageSize=12&fields=FULL&sort=",
  YMARKETING:"/getCustomerDetails?sapOutBoundId=:sapOutBoundId",
  UPDATESUBSCRIBER:"/sendRegistrationDataToYmkt",
  CSRF_TOKEN:"https://my300363.s4hana.ondemand.com/sap/opu/odata/sap/CUAN_CONTENT_PAGE_RESULT_SRV/$metadata",
  UNSUBSCRIBEYMARKETING:'https://my300363.s4hana.ondemand.com/sap/opu/odata/sap/CUAN_CONTENT_PAGE_RESULT_SRV/ResultHeaders',
  PICKERADDTOBASKET:'/users/:email/carts/:cartCode/addPromoProducts',
  FFCATEGORYPATH:'/products/search/:personaId',
  FEEBACK_FORMS:'/contactUsForm?formType=:formType',
  STORE_FEEBACK_FORMS:'/storeContactUsForm?formType=:formType',
  STOREPORTAL_LOGIN:'/storelogin?storeName=:storeName&password=:password',
  STORE_ORDERS:'/storeorders?storeName=:storeName&orderStatus=:orderStatus',
  ORDER_CHECKIN:'/ordercheckin?storeName=:storeName&status=:status',
  KLARNA_SESSION:"/users/:email/carts/:cartCode/klarnasession",
  KLARNA_AUTHORIZATION:"/users/:email/carts/:cartCode/klarnaAuthorize?authorizationToken=:authorizationToken",
  JWT_TOKEN:'/users/:email/carts/:cartCode/generateJWT',
  PAYMENT_AUTHENTICATION:'/users/:email/carts/:cartCode/securepayment?sessionID=:sessionID',
  VALIDATE_PAYMENT:'/users/:email/carts/:cartCode/validatepayment?responseJWT=:responseJWT&transactionID=:transactionID',
  EXPRESS_PAYMENT:"/users/:email/carts/:cartCode/securepayment?sessionID=:sessionID&isExpresscheckout=true&profileID=:profileID",
  EXPRESS_VALIDATE_PAYMENT:'/users/:email/carts/:cartCode/validatepayment?responseJWT=:responseJWT&transactionID=:transactionID&isExpresscheckout=true&profileID=:profileID',
  ORDERCONFIRMATION: "/orders/:orderId/?fields=FULL",
  POSTORDER: "/postorder/:orderId/",
  ORDER_DETAILS: "/users/:email/orders/:orderCode/?fields=FULL",
  GIFT_CARD:"/users/:email/carts/:cartCode/giftcard",
  GIVEX_LOGIN:"/users/:email/givexlogin",
  GIVEX_REGISTRATION:"/users/:email/givexlogin/givexregister/newregistration",
  CHECK_BALANCE:"/users/:email/givexlogin/checkbalance",
  CHECK_GIFTBALANCE:"/checkbalance",
  TRANSFER_BALANCE:"/users/:email/transferbalance?FromGiftCardNumber=:FromGiftCardNumber&ToGiftCardNumber=:ToGiftCardNumber",
  REG_GIVEX_PAYMENT_DETAIL:"/users/:email/carts/:cartCode/paymentdetails",
  EXPRESS_CHECK_PATH:"/users/:email/carts/:cartCode/expressCheckout?isAdyencheckout=true",
  PAYERID_PATH:"/users/:email/carts/:cartCode/paypalpayment?payerID=:payerID&systemIP=:systemIP&fields=FULL",
  PAYPAL_PATH:"/users/:email/carts/:cartCode/paypalsetservice",
  PICK_MIX_BUNDLEPRICE: "/getBundlePrice",
  RETRIEVE_PASSWORD: "/forgottenpasswordtokens?userId=:email",
  STORES: "/kao/stores",
  CHECK_STORE_PATH: "/stores/:storeName",
  BI_REPORT:"/bireports?siteId=:siteId",
  FIND_POSTCODE:"https://services.postcodeanywhere.co.uk/PostcodeAnywhere/Interactive/FindByPostcode/v1.00/json3.ws?&Postcode=:postCode&Key=PZ52-UH91-BW19-BT37",
  POSTAL_ADDRESS:"https://services.postcodeanywhere.co.uk/PostcodeAnywhere/Interactive/RetrieveById/v1.20/json3.ws?&Key=PZ52-UH91-BW19-BT37&Id=:postCode",
  FIND_LOQATE:"https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws?&Text=:Text&Countries=:Countries&Container=:Container&Key=NN41-KM25-TY31-EB56",
  RETRIEVE_LOQATE:"https://api.addressy.com/Capture/Interactive/Retrieve/v1.2/json3.ws?&Key=NN41-KM25-TY31-EB56&Id=:Id",
  REG_DEBIT_PAYMENT: "/users/:email/carts/:cartCode/payment?isAdyencheckout=true",
  APPLEPAYMET_PATH:"/users/:email/carts/:orderId/authorizeApplepayPayment?isAdyencheckout=true",
  SPLIT_PAYMENT:"/users/:email/carts/:cartCode/giftcard/splitpayment?firstName=:firstName&lastName=:lastName&givexCardNumber=:givexCardNumber&givexPinNumber=:givexPinNumber&amount=:amount&isAdyencheckout=true",
  EXPRESS_SPLIT_PAYMENT:"/users/:email/carts/:cartCode/giftcard/splitpayment?firstName=:firstName&lastName=:lastName&givexCardNumber=:givexCardNumber&givexPinNumber=:givexPinNumber&amount=:amount&isExpresscheckout=true&profileID=:profileID&isAdyencheckout=true",
  EXPRESS_DBPAYMENT:"/users/:email/carts/:cartCode/payment?isExpresscheckout=true&profileID=:profileID&isAdyencheckout=true",
  EDIT_CARD: "/users/:email/editCreditCards?profileID=:profileID&isAdyencheckout=true",
  CREATE_CARD: "/users/:email/postcreditcards?isAdyencheckout=true",
  REMOVE_CARD: "/users/:email/deleteCreditCard?profileID=:profileID&isAdyencheckout=true",
  CREATE_USER_OC_PATH :"/users?orderCode=:orderCode",
  CREATE_USER_PATH:"/users",
  DEFAULT_CARD:"/users/:email/setDefaultCard?profileID=:profileID",
  NEWSLETTER_SIGNUP:"/sendNewsLetterToYmkt",
  ORDER_HISTORY_DETAILS: "/users/:email/orders/:orderCode/?fields=FULL",
  ORDER_HISTORY_PATH:"/users/:email/orders?pageSize=123&fields=FULL&sort=:sort",
  PICK_MIX_PATH:"/products/search?query=:relavance:allCategories:categoryId",
  DELIVERY_METHODS:"/users/:email/carts/:cartCode/expDeliveryMethods",
  EU_DL_METHODS:"/users/:email/carts/:cartCode/expdeliveryMethodsDEandEU?countryCode=:countryCode",
  US_DELIVERY_SERVICES:'/users/:email/carts/:cartCode/expusdeliveryservices?deliveryGroup=:deliveryGroup',
  SET_DELIVERY_METHOD:"/users/:email/saveDefaultDeliveryMethod?deliveryMethod=:deliveryMethod",
  OS_FULLCART:'/users/:email/carts/:cartCode?fields=FULL&deliveryGroup=',
  GET_INTERNATIONAL_DELIVERY_METHOD:"/users/:email/carts/:cartCode/InternationalDelivery?countryCode=:countryCode",
  SET_INTERNATIONAL_DELIVERY_METHOD: "/users/:email/carts/:cartCode/SetIntDeliveryModeToCart?countryCode=:countryCode&deliveryCode=International-Delivery",
  GFS_PATH:"/users/:email/carts/:cartCode/gfs/stores?postCode=:postalCode&latitude=:latitude&longitude=:longitude",
  GUEST_CART_SHIFTING_PATH: "/users/anonymous/carts/:cartCode/doubleLoginCartMerge/?oldCartId=:oldCartCode&toMergeCartGuid=:mergeCartGuid",
  FIND_STORE: "/stores/?latitude=:latitude&longitude=:longitude",
  SET_STORE:"/users/:email/carts/:cartCode/setStore?deliveryCode=ClickAndCollect",
  SET_PHONENUMBER:"/users/:email/carts/:cartCode/gfs/setPhoneNumber?phoneNumber=:phoneNumber",
  CLICKANDCOLLECT_ADDRESS_PATH: "/users/:email/carts/:cartCode/clickAndCollect/addresses/delivery",
  SHIPPING_ADDRESS:"/users/:email/carts/:cartCode/addresses/delivery",
  PRODUCT_DATA_PATH:"/products/:productCode?categoryCode=:categoryCode&email=:userEmail",
  CATEGORY_PRODUCTS:"/products/:categoryCode&pageSize=:pageSize&fields=FULL&sort=:sort",
  DELIVERY_SERVICE: "/users/:email/carts/:cartCode/NamedDeliveryServices?baseSiteId=:catalogversionId",
  DELIVERY_METHOD_TO_CART: "/users/:email/carts/:cartCode/selectDeliveryMethod?deliveryCode=:deliverycode",
  CATEGORY_SEARCH_PRODUCTS: "/products/search?query=:searchValue&pageSize=:pageSize&fields=FULL&sort=:sort",
  CATEGORY_SEARCH_RESULTS: "/products/search?query=:searchValue",
  CHANGE_BILLING_ADDRESS:"/users/:email/carts/:cartCode/addresses/changeBillingAddress",
  CARD_DETAILS:"/users/:email/getCardDetails?isAdyencheckout=true",
  US_DELIVERY_MODES:  "/users/:email/carts/:cartCode/usdeliveryservices?deliveryGroup=:deliveryGroup",
  DELIVERY_METHOD: "/users/:email/carts/:cartCode/DeliveryMethods",
  USER_CARTDETAILS: "/users/:email/carts/:cartID/?fields=FULL",
  DELIVERY_NAMED_DAY_SERVICE:"/users/:email/carts/:cartCode/SetNamedDeliveryModeToCart?deliveryCode=:deliveryCode",
  GUEST_SHIPPING_ADDRESS:"/users/anonymous/carts/:cartId/addresses/delivery",
  CONFIRM_ADDRESS: "/users/:email/carts/:cartCode/confirmAddress?addressCode=:addressId",
  PICK_MIX_BUNDLE_PATH: "/users/:email/carts/:cartCode/addBundle",
  GIFT_BOX:"/users/:email/carts/:cartCode/giftBox",
  REMOVE_BUNDLE_PATH:"/users/:email/carts/:cartCode/removeBundle?bundleNo=:bundleNo",
  UPDATE_BUNDLE: "/users/:email/carts/:cartCode/entries/bundleNo/:bundleNo?qty=:qty",
  ADD_TO_BASKET:"/users/:email/carts/:cartCode/entries?fields=FULL",
  GENERATTE_CART:"/users/:email/carts",
  ADD_FAVOURITES:"/users/:email/wishlist/add?code=:code",
  CHANGE_PASSWORD:"/users/:email/password?userId=:userId&old=:currentPassword&new=:newPassword",
  UNSUBSCRIBE:"/unsubscription?OutboundId=:OutboundId&userEmail=:userEmail",
  ANANONYMOUSCART:"/users/anonymous/carts/:cartCode/email?email=:email",
  REPEAT_ORDER:"/users/:email/carts/:cartCode/repeatOrder?code=:orderCode&cartId=:cartId",
  PROMOCODE:"/users/:email/carts/:cartCode/applyPromo?voucherId=:voucherId",
  REMOVE_PROMO:  "/users/:email/carts/:cartCode/vouchers/:coupon",
  PROFILE:"/users/:email?isAdyencheckout=true",
  EMARSYS_USER_PREFERENCE: "/getContactDetails/:user",
  EMARSYSPROFILE: "/updateContactDetails/:user",
  REMOVE_FAVOURITE: "/users/:email/wishlist/remove/?code=:productCode",
  FAVOURITES:"/users/:email/wishlist/retrieve",
  WISHLISTS:"/users/:email/listofWishList",
  NEWWISHLIST:"/users/:email/createWishList?wishListName=:wishListName&isDefaultWishList=:isDefaultWishList",
  SETDEFAULTWISHLIST : "/users/:email/updateWishList/:wishListID?isDefaultWishList=true",
  REMOVEWISHLIST: "/users/:email/removeWishlist/:wishListID",
  REMOVE_WISHLIST: "/users/:email/wishlist/:wishListID/removeEntry/?code=:productCode",
  SHARE_WISHLIST: "/share/wishlist/:wishlistID",
  UPDATEWISHLIST: "/users/:email/wishlist/:wishListID/editWishlistName?wishlistName=:newwishlistname",
  MERGE_CART:"/users/:email/carts?oldCartId=:oldCartId&toMergeCartGuid=:newCartId",
  CREATE_ADDRESS: "/users/:email/addresses",
  RETREIVE_ADDRESS: "/users/:email/profile/addresses",
  UPDATE_ADDRESS:"/users/:email/addresses/:addressId",
  REGISTER_CART:"/users/:email/carts",
  CURRENT_CART:"/users/:email/carts/current?fields=FULL",
  SITE_AUTENTICATION: "/oauth/token?client_id=clientcq&client_secret=password&grant_type=password",
  CART_TOKEN_PATH: "oauth/token?client_id=clientcq&client_secret=password&grant_type=client_credentials",
  catalog:"/catalogs",
  AMP_CDN_PATH: "https://moltonbrown.cdn.content.amplience.net",

  CS_ORDER_SEARCH:'/assistedservicewebservices/customers/search?orderId=:orderId&baseSite=:ASMbaseSite&access_token=:agentToken',

  CS_SEARCH:"/assistedservicewebservices/customers/search?query=:email&baseSite=:ASMbaseSite&access_token=:agentToken",

  CS_OAUTH:"oauth/token?client_id=asm&client_secret=password&grant_type=password&username=:agent&password=:password",
  RECAPTCHA :"https://www.google.com/recaptcha/api/siteverify?secret=6Le1rMQUAAAAABglfnjnaQq8Pxo_aetC5ZIFsuWg&response=",
  DOUBLE_OPTIN_CONFIRMATION : "/doubleOptInConfirmation",
  ANNEX_TOKEN :"/ac/token/",


  SUBSCRIBE_ANNEX_NEWSLETTER : "/custom/action/",


  CREATE_ANNEX_CLOUD: "/users/:email/optin/status?optIn=:optin",
  CART_LOYALTY_ANNEX_CLOUD: "/users/:email/ac/carts/:cartId",
  giftBoxDetails: "/getGiftBoxDetails",
  addGiftBundle: "/users/:email/carts/:cartCode/addGiftBundle",
  addToWishList:"/users/:email/wishlist/:wishListId/addproducts?code=:code",
  //Adyen endpoints
  ADYEN_PAYMENT_METHODS : "/users/:userId/carts/:cartId/adyenpaymentmethods",
  checkBalance:"/users/:email/carts/:cartCode/giftcard/checkBalance?givexCardNumber=:cardNumber&givexPinNumber=:pinNumber",
  ADYEN_PAYMENT_DETAILS : "/users/:userId/carts/:cartId/adyenpaymentdetails",
  ADYEN_PAYMENT_DETAILS_THREEDS : "/users/:userId/carts/:cartId/saveCardAdyenPaymentDetails?subscriptionId=:subscriptionId",
  KICKBOX: "/kickbox/:email",
  FEATURED_FLAGS : "/featureFlags"
};

export const SERVER_PATHS = {
  DEV: "https://api.cxur-kaocorpor1-d5-public.model-t.cc.commerce.ondemand.com/kaowebservices/v2/"
};
export const gme = {
  googleMapKey: "AIzaSyCFrOkkgTPx00zyCBW5kFhQXri5v1xfYxg"
};
export const frk = {
  FRKey: "SzhYZGpYdXV4MGZnMWRzWG5pWDBDekJi",
};


export const US_COUNTRIES = [
  {
    name: "United States",
    isocode: "US",
    id: "US-US",
    states: [
      { name: "Alabama", isocode: "AL" },
      { name: "Alaska", isocode: "AK" },
      { name: "Arizona", isocode: "AZ" },
      { name: "Arkansas", isocode: "AR" },
      { name: "California", isocode: "CA" },
      { name: "Colorado", isocode: "CO" },
      { name: "Connecticut", isocode: "CT" },
      { name: "Delaware", isocode: "DE" },
      { name: "District of Columbia", isocode: "DC" },
      { name: "Florida", isocode: "FL" },
      { name: "Georgia", isocode: "GA" },
      { name: "Hawaii", isocode: "HI" },
      { name: "Idaho", isocode: "ID" },
      { name: "Illinois", isocode: "IL" },
      { name: "Indiana", isocode: "IN" },
      { name: "Iowa", isocode: "IA" },
      { name: "Kansas", isocode: "KS" },
      { name: "Kentucky", isocode: "KY" },
      { name: "Louisiana", isocode: "LA" },
      { name: "Maine", isocode: "ME" },
      { name: "Maryland", isocode: "MD" },
      { name: "Massachusetts", isocode: "MA" },
      { name: "Michigan", isocode: "MI" },
      { name: "Minnesota", isocode: "MN" },
      { name: "Mississippi", isocode: "MS" },
      { name: "Missouri", isocode: "MO" },
      { name: "Montana", isocode: "MT" },
      { name: "Nebraska", isocode: "NE" },
      { name: "Nevada", isocode: "NV" },
      { name: "New Hampshire", isocode: "NH" },
      { name: "New Jersey", isocode: "NJ" },
      { name: "New Mexico", isocode: "NM" },
      { name: "New York", isocode: "NY" },
      { name: "North Carolina", isocode: "NC" },
      { name: "North Dakota", isocode: "ND" },
      { name: "Ohio", isocode: "OH" },
      { name: "Oklahoma", isocode: "OK" },
      { name: "Oregon", isocode: "OR" },
      { name: "Pennsylvania", isocode: "PA" },
      { name: "Rhode Island", isocode: "RI" },
      { name: "South Carolina", isocode: "SC" },
      { name: "South Dakota", isocode: "SD" },
      { name: "Tennessee", isocode: "TN" },
      { name: "Texas", isocode: "TX" },
      { name: "Utah", isocode: "UT" },
      { name: "Vermont", isocode: "VT" },
      { name: "Virginia", isocode: "VA" },
      { name: "Washington", isocode: "WA" },
      { name: "West Virginia", isocode: "WV" },
      { name: "Wisconsin", isocode: "WI" },
      { name: "Wyoming", isocode: "WY" }
    ]
  },
  {
    name: "Canada",
    isocode: "CA",
    id: "US-CA",
    states: [
      {
        name: "Alberta",
        isocode: "AB"
      },
      {
        name: "British Columbia",
        isocode: "BC"
      },
      {
        name: "Manitoba",
        isocode: "MB"
      },
      {
        name: "New Brunswick",
        isocode: "NB"
      },
      {
        name: "Newfoundland and Labrador",
        isocode: "NL"
      },
      {
        name: "Northwest Territories",
        isocode: "NT"
      },
      {
        name: "Nova Scotia",
        isocode: "NS"
      },
      {
        name: "Nunavut",
        isocode: "NU"
      },
      {
        name: "Ontario",
        isocode: "ON"
      },
      {
        name: "Prince Edward Island",
        isocode: "PE"
      },
      {
        name: "Quebec",
        isocode: "QC"
      },
      {
        name: "Saskatchewan",
        isocode: "SK"
      },
      {
        name: "Yukon Territory",
        isocode: "YT"
      }
    ]
  },
  { name: "US Virgin Islands", isocode: "VI", id: "US-VI" }
];
export const countries = [
  {
    name: "American Samoa",

    isocode: "AS"
  },
  {
    name: "Anguilla",

    isocode: "AI"
  },
  {
    name: "Antigua",

    isocode: "AG"
  },
  {
    name: "Argentina",

    isocode: "AR"
  },
  {
    name: "Armenia",

    isocode: "AM"
  },
  {
    name: "Aruba",

    isocode: "AW"
  },
  {
    name: "Australia",

    isocode: "AU"
  },
  {
    name: "Bahamas",

    isocode: "BS"
  },
  {
    name: "Barbados",

    isocode: "BB"
  },
  
  /*{
    name: "Belarus",

    isocode: "BY"
  },
  */
  {
    name: "Belize",

    isocode: "BZ"
  },
  {
    name: "Bermuda",

    isocode: "BM"
  },
  {
    name: "Bhutan",

    isocode: "BT"
  },
  {
    name: "Bolivia",

    isocode: "BO"
  },
  {
    name: "Bosnia and Herzegovina",

    isocode: "BA"
  },
  {
    name: "Brazil",

    isocode: "BR"
  },
  {
    name: "British Virgin Islands",

    isocode: "VG"
  },
  {
    name: "Cape Verde",

    isocode: "CV"
  },
  {
    name: "Cayman Islands",

    isocode: "KY"
  },
  {
    name: "Chile",

    isocode: "CL"
  },
  {
    name: "China",

    isocode: "CN"
  },
  {
    name: "Colombia",

    isocode: "CO"
  },
  {
    name: "Cook Islands",

    isocode: "CK"
  },
  {
    name: "Costa Rica",

    isocode: "CR"
  },
  {
    name: "Dominica",

    isocode: "DM"
  },
  {
    name: "Dominican Republic",

    isocode: "DO"
  },
  {
    name: "Ecuador",

    isocode: "EC"
  },
  {
    name: "El Salvador",

    isocode: "SV"
  },
  {
    name: "Falkland Islands (Malvinas)",

    isocode: "FK"
  },
  {
    name: "Faroe Islands",

    isocode: "FO"
  },
  {
    name: "Fiji",

    isocode: "FJ"
  },
  {
    name: "French Guiana",

    isocode: "GF"
  },
  {
    name: "French Polynesia",

    isocode: "PF"
  },
  {
    name: "Georgia",

    isocode: "GA"
  },
  {
    name: "Gibraltar",

    isocode: "GI"
  },
  {
    name: "Greenland",

    isocode: "GL"
  },
  {
    name: "Grenada",

    isocode: "GD"
  },
  {
    name: "Guadeloupe",

    isocode: "GP"
  },
  {
    name: "Guam",

    isocode: "GU"
  },
  {
    name: "Guatemala",

    isocode: "GT"
  },
  {
    name: "Guyana",

    isocode: "GY"
  },
  {
    name: "Honduras",

    isocode: "HN"
  },
  {
    name: "Hong Kong",

    isocode: "HK"
  },
  {
    name: "India",

    isocode: "IN"
  },
  {
    name: "Israel",

    isocode: "IL"
  },
  {
    name: "Japan",

    isocode: "JP"
  },
  {
    name: "Kazakhstan",

    isocode: "KZ"
  },
  {
    name: "Macao",

    isocode: "MO"
  },
  {
    name: "Macedonia",

    isocode: "MK"
  },
  {
    name: "Maldives",

    isocode: "MV"
  },
  {
    name: "Marshall Islands",

    isocode: "MH"
  },
  {
    name: "Martinique",

    isocode: "MQ"
  },
  {
    name: "Mauritius",

    isocode: "MU"
  },
  {
    name: "Mexico",

    isocode: "MX"
  },
  {
    name: "Moldova Republic of",

    isocode: "MD"
  },
  {
    name: "Montserrat",

    isocode: "MS"
  },
  {
    name: "Morocco",

    isocode: "MA"
  },
  {
    name: "Myanmar (Burma)",

    isocode: "MM"
  },
  {
    name: "Nepal",

    isocode: "NP"
  },
  {
    name: "Netherlands Antilles",

    isocode: "AN"
  },
  {
    name: "New Caledonia",

    isocode: "NC"
  },
  {
    name: "New Zealand",

    isocode: "NZ"
  },
  {
    name: "Nicaragua",

    isocode: "NI"
  },
  {
    name: "Niue",

    isocode: "NU"
  },
  {
    name: "Norfolk Island",

    isocode: "NF"
  },
  {
    name: "Northern Mariana Islands",

    isocode: "MP"
  },
  {
    name: "Palau",

    isocode: "PW"
  },
  {
    name: "Panama",

    isocode: "PA"
  },
  {
    name: "Papua New Guinea",

    isocode: "PG"
  },
  {
    name: "Paraguay",

    isocode: "PY"
  },
  {
    name: "Peru",

    isocode: "PE"
  },
  {
    name: "Philippines",

    isocode: "PH"
  },
  {
    name: "Puerto Rico",

    isocode: "PR"
  },
  {
    name: "Western Samoa",

    isocode: "WS"
  },
  {
    name: "Saint Kitts And Nevis",

    isocode: "KN"
  },
  {
    name: "Serbia",

    isocode: "RS"
  },
  {
    name: "Singapore",

    isocode: "SG"
  },
  {
    name: "Solomon Islands",

    isocode: "SB"
  },
  {
    name: "South Africa",

    isocode: "ZA"
  },
  {
    name: "South Korea",

    isocode: "KR"
  },
  {
    name: "Seychelles",

    isocode: "SC"
  },
  {
    name: "Sri Lanka",

    isocode: "LK"
  },
  {
    name: "Suriname",

    isocode: "SR"
  },
  {
    name: "St.Lucia",

    isocode: "LC"
  },
  {
    name: "St.Pierre and Miquelon",

    isocode: "PM"
  },
  {
    name: "St.Vincent and the Grenadines",

    isocode: "VC"
  },
  {
    name: "Taiwan",

    isocode: "TW"
  },
  {
    name: "Tajikistan",

    isocode: "TJ"
  },
  {
    name: "Tonga",

    isocode: "TO"
  },
  {
    name: "Trinidad and Tobago",

    isocode: "TT"
  },
  {
    name: "Turkmenistan",

    isocode: "TM"
  },
  {
    name: "Turks and Caicos Islands",

    isocode: "TC"
  },
  {
    name: "Tuvalu",

    isocode: "TV"
  },
  {
    name: "United Kingdom",

    isocode: "GB"
  },
  {
    name: "Uruguay",

    isocode: "UY"
  },
  {
    name: "Uzbekistan",

    isocode: "UZ"
  },
  {
    name: "Venezuela",

    isocode: "VE"
  }
];

export const UK_ONLY_LOQATE_COUNTRIES = [
  {
    name: "Australia",

    isocode: "AU"
  },
  {
    name: "Brazil",

    isocode: "BR"
  },
  {
    name: "Guernsey",

    isocode: "GU"
  },
  {
    name: "Hong Kong",

    isocode: "HK"
  },
  {
    name: "India",

    isocode: "IN"
  },
  {
    name: "Isle of Man",

    isocode: "IMN"
  },
  {
    name: "Japan",

    isocode: "JP"
  },
  {
    name: "Jersey",

    isocode: "JEY"
  },
  {
    name: "South Korea",

    isocode: "KR"
  },
  {
    name: "Mexico",

    isocode: "MX"
  },
  {
    name: "Singapore",

    isocode: "SG"
  },
  {
    name: "South Africa",

    isocode: "ZA"
  },
  {
    name: "United Kingdom",

    isocode: "GB"
  },
  {
    name: "Taiwan",

    isocode: "TW"
  },

];

export const DE_COUNTRIES = [
  {
    name: "Deutschland",

    isocode: "DE"
  },
  {
    name: "Österreich",

    isocode: "AT"
  }
];
export const EUROPEAN_COUNTRIES = [
  {
    name: "Albania",

    isocode: "AL"
  },
  {
    name: "Andorra",

    isocode: "AD"
  },
  {
    name: "Austria",

    isocode: "AT"
  },
  {
    name: "Belgium",

    isocode: "BE"
  },
  {
    name: "Bulgaria",

    isocode: "BG"
  },
  {
    name: "Croatia",

    isocode: "HR"
  },
  {
    name: "Czech Republic",

    isocode: "CZ"
  },
  {
    name: "Denmark",

    isocode: "DK"
  },
  {
    name: "Estonia",

    isocode: "EE"
  },
  {
    name: "Finland",

    isocode: "FI"
  },
  {
    name: "Hungary",

    isocode: "HU"
  },
  {
    name: "Iceland",

    isocode: "IS"
  },
  {
    name: "Ireland",

    isocode: "IE"
  },
  {
    name: "Liechtenstein",

    isocode: "LI"
  },
  {
    name: "Lithuania",

    isocode: "LT"
  },
  {
    name: "Luxembourg",

    isocode: "LU"
  },
  {
    name: "Malta",

    isocode: "MT"
  },
  {
    name: "Monaco",

    isocode: "MC"
  },
  {
    name: "Netherlands",

    isocode: "NL"
  },
  {
    name: "Norway",

    isocode: "NO"
  },
  {
    name: "Poland",

    isocode: "PL"
  },
  {
    name: "Portugal",

    isocode: "PT"
  },
  {
    name: "San Marino",

    isocode: "SM"
  },
  {
    name: "Slovak Republic",

    isocode: "SK"
  },
  {
    name: "Slovenia",

    isocode: "SI"
  },
  {
    name: "Sweden",

    isocode: "SE"
  },
  {
    name: "Switzerland",

    isocode: "CH"
  },
  // {
  //   name: "Ukraine",

  //   isocode: "UA"
  // }
];
export const EMARSYS_COUNTRIES = [
  { name: "Albania",
    emcode: "2"},
 { name: "Algeria",
    emcode: "3"},
 { name: "Andorra",
    emcode: "4"},
 { name: "Angola",
    emcode: "5"},
 { name: "Antigua and Barbuda",
    emcode: "6"},
 { name: "Argentina",
    emcode: "7"},
 { name: "Armenia",
    emcode: "8"},
 { name: "Australia",
    emcode: "9"},
 { name: "Austria",
    emcode: "10"},
 { name: "Azerbaijan",
    emcode: "11"},
 { name: "Bahamas",
    emcode: "12"},
 { name: "Bahrain",
    emcode: "13"},
 { name: "Bangladesh",
    emcode: "14"},
 { name: "Barbados",
    emcode: "15"},
 { name: "Belarus",
    emcode: "16"},
 { name: "Belgium",
    emcode: "17"},
 { name: "Belize",
    emcode: "18"},
 { name: "Benin",
    emcode: "19"},
 { name: "Bhutan",
    emcode: "20"},
 { name: "Bolivia",
    emcode: "21"},
 { name: "Bosnia and Herzegovina",
    emcode: "22"},
 { name: "Botswana",
    emcode: "23"},
 { name: "Brazil",
    emcode: "24"},
 { name: "Brunei Darussalam",
    emcode: "25"},
 { name: "Bulgaria",
    emcode: "26"},
 { name: "Burkina ",
    emcode: "Faso	27"},
 { name: "Burma	",
    emcode: "28"},
 { name: "Burundi",
    emcode: "29"},
 { name: "Cambodia",
    emcode: "30"},
 { name: "Cameroon",
    emcode: "31"},
 { name: "Canada",
    emcode: "32"},
 { name: "Cape Verde",
    emcode: "33"},
 { name: "Central African Republic",
    emcode: "34"},
 { name: "Chad",
    emcode: "35"},
 { name: "Chile	",
    emcode: "36"},
 { name: "China",
    emcode: "37"},
 { name: "Colombia",
    emcode: "38"},
 { name: "Comoros	",
    emcode: "39"},
 { name: "Congo",
    emcode: "40"},
 { name: "Congo, Democratic Republic of the",
    emcode: "41"},
 { name: "Costa Rica",
    emcode: "42"},
 { name: "Cote d’Ivoire",
    emcode: "43"},
 { name: "Croatia	",
    emcode: "44"},
 { name: "Cuba",
    emcode: "45"},
 { name: "Cyprus",
    emcode: "46"},
 { name: "Czech Republic",
    emcode: "47"},
 { name: "Denmark",
    emcode: "48"},
 { name: "Djibouti",
    emcode: "49"},
 { name: "Dominica",
    emcode: "50"},
 { name: "Dominican Republic",
    emcode: "51"},
 { name: "Ecuador",
    emcode: "52"},
 { name: "Egypt",
    emcode: "53"},
 { name: "El Salvador",
    emcode: "54"},
 { name: "Equatorial Guinea",
    emcode: "55"},
 { name: "Eritrea",
    emcode: "56"},
 { name: "Estonia",
    emcode: "57"},
 { name: "Ethiopia",
    emcode: "58"},
 { name: "Fiji",
    emcode: "59"},
 { name: "Finland	",
    emcode: "60"},
 { name: "France",
    emcode: "61"},
 { name: "Gabon",
    emcode: "62"},
 { name: "Gambia, The",
    emcode: "63"},
 { name: "Georgia	",
    emcode: "64"},
 { name: "Germany	",
    emcode: "65"},
 { name: "Ghana	",
    emcode: "66"},
 { name: "Greece	",
    emcode: "67"},
 { name: "Grenada	",
    emcode: "68"},
 { name: "Guatemala	",
    emcode: "69"},
 { name: "Guinea	",
    emcode: "70"},
 { name: "Guinea-Bissau	",
    emcode: "71"},
 { name: "Guyana",
    emcode: "72"},
 { name: "Haiti	",
    emcode: "73"},
 { name: "Honduras",
    emcode: "74"},
 { name: "Hungary	",
    emcode: "75"},
 { name: "Iceland",
    emcode: "76"},
 { name: "India	",
    emcode: "77"},
 { name: "Indonesia",
    emcode: "78"},
 { name: "Iran	",
    emcode: "79"},
 { name: "Iraq",
    emcode: "80"},
 { name: "Ireland",
    emcode: "81"},
 { name: "Israel",
    emcode: "82"},
 { name: "Italy	",
    emcode: "83"},
 { name: "Jamaica	",
    emcode: "84"},
 { name: "Japan	",
    emcode: "85"},
 { name: "Jordan",
    emcode: "86"},
 { name: "Kazakhstan",
    emcode: "87"},
 { name: "Kenya	",
    emcode: "88"},
 { name: "Kiribati",
    emcode: "89"},
 { name: "Korea, North	",
    emcode: "90"},
 { name: "Korea, South	",
    emcode: "91"},
 { name: "Kuwait	",
    emcode: "92"},
 { name: "Kyrgyzstan",
    emcode: "93"},
 { name: "Laos	",
    emcode: "94"},
 { name: "Latvia",
    emcode: "95"},
 { name: "Lebanon",
    emcode: "96"},
 { name: "Lesotho",
    emcode: "97 "},
 { name: "Liberia	",
    emcode: "98"},
 { name: "Libya	",
    emcode: "99"},
 { name: "Liechtenstein",
    emcode: "100"},
 { name: "Lithuania	",
    emcode: "101"},
 { name: "Luxembourg	",
    emcode: "102"},
 { name: "Macedonia",
    emcode: "103"},
 { name: "Madagascar	",
    emcode: "104"},
 { name: "Malawi	",
    emcode: "105"},
 { name: "Malaysia",
    emcode: "106"},
 { name: "Maldives",
    emcode: "107"},
 { name: "Mali	",
    emcode: "108"},
 { name: "Malta	",
    emcode: "109"},
 { name: "Marshall Islands	",
    emcode: "110"},
 { name: "Mauritania",
    emcode: "111"},
 { name: "Mauritius	",
    emcode: "112"},
 { name: "Mexico	",
    emcode: "113"},
 { name: "Micronesia",
    emcode: "114"},
 { name: "Moldova	",
    emcode: "115"},
 { name: "Monaco	",
    emcode: "116"},
 { name: "Mongolia	",
    emcode: "117"},
 { name: "Morocco",
    emcode: "118"},
 { name: "Mozambique",
    emcode: "119"},
 { name: "Myanmar	",
    emcode: "120"},
 { name: "Namibia",
    emcode: "121"},
 { name: "Nauru	",
    emcode: "122"},
 { name: "Nepal",
    emcode: "123"},
 { name: "The Netherlands	",
    emcode: "124"},
 { name: "New Zealand",
    emcode: "125"},
 { name: "Nicaragua	",
    emcode: "126"},
 { name: "Niger	",
    emcode: "127"},
 { name: "Nigeria	",
    emcode: "128"},
 { name: "Norway	",
    emcode: "129"},
 { name: "Oman	",
    emcode: "130"},
 { name: "Pakistan	",
    emcode: "131"},
 { name: "Palau	",
    emcode: "132"},
 { name: "Panama",
    emcode: "134"},
 { name: "Papua New Guinea",
    emcode: "135"},
 { name: "Paraguay	",
    emcode: "136"},
 { name: "Peru",
    emcode: "137"},
 { name: "Philippines",
    emcode: "138"},
 { name: "Poland	",
    emcode: "139"},
 { name: "Portugal	",
    emcode: "140"},
 { name: "Qatar	",
    emcode: "141"},
 { name: "Romania",
    emcode: "142"},
 { name: "Russia	",
    emcode: "143"},
 { name: "Rwanda	",
    emcode: "144"},
 { name: "St. Kitts and Nevis",
    emcode: "145"},
 { name: "St. Lucia	",
    emcode: "146"},
 { name: "St. Vincent and The Grenadines	",
    emcode: "147"},
 { name: "Samoa	",
    emcode: "148"},
 { name: "San Marino",
    emcode: "149"},
 { name: "São Tomé and Príncipe	",
    emcode: "150"},
 { name: "Saudi Arabia	",
    emcode: "151"},
 { name: "Senegal	",
    emcode: "152"},
 { name: "Serbia	",
    emcode: "153"},
 { name: "Seychelles	",
    emcode: "154"},
 { name: "Sierra Leone",
    emcode: "155"},
 { name: "Singapore	",
    emcode: "156"},
 { name: "Slovakia",
    emcode: "157"},
 { name: "Slovenia	",
    emcode: "158"},
 { name: "Solomon Islands",
    emcode: "159"},
 { name: "Somalia	",
    emcode: "160"},
 { name: "South Africa	",
    emcode: "161"},
 { name: "Spain	",
    emcode: "162"},
 { name: "Sri Lanka	",
    emcode: "163"},
 { name: "Sudan	",
    emcode: "164"},
 { name: "Suriname	",
    emcode: "165"},
 { name: "Swaziland	",
    emcode: "166"},
 { name: "Sweden	",
    emcode: "167"},
 { name: "Switzerland	",
    emcode: "168"},
 { name: "Syria	",
    emcode: "169"},
 { name: "Taiwan	",
    emcode: "170"},
 { name: "Tajikistan	",
    emcode: "171"},
 { name: "Tanzania	",
    emcode: "172"},
 { name: "Thailand	",
    emcode: "173"},
 { name: "Togo	",
    emcode: "174"},
 { name: "Tonga	",
    emcode: "175"},
 { name: "Trinidad and Tobago",
    emcode: "176"},
 { name: "Tunisia	",
    emcode: "177"},
 { name: "Turkey	",
    emcode: "178"},
 { name: "Turkmenistan	",
    emcode: "179"},
 { name: "Tuvalu	",
    emcode: "180"},
 { name: "Uganda	",
    emcode: "181"},
 { name: "Ukraine	",
    emcode: "182"},
 { name: "United Arab Emirates",
    emcode: "183"},
 { name: "United Kingdom	",
    emcode: "184"},
 { name: "United States of America",
    emcode: "185"},
 { name: "Uruguay	",
    emcode: "186"},
 { name: "Uzbekistan",
    emcode: "187"},
 { name: "Vanuatu",
    emcode: "188"},
 { name: "Vatican City	",
    emcode: "189"},
 { name: "Venezuela	",
    emcode: "190"},
 { name: "Vietnam	",
    emcode: "191"},
 { name: "Western Sahara",
    emcode: "192"},
 { name: "Yemen	",
    emcode: "193"},
 { name: "Yugoslavia",
    emcode: "194"},
 { name: "Zaire	",
    emcode: "195"},
 { name: "Zambia	",
    emcode: "196"},
 { name: "Zimbabwe	",
    emcode: "197"},
 { name: "Greenland",
    emcode: "198"},
 { name: "Virgin Islands",
    emcode: "199"},
 { name: "Canary Islands",
    emcode: "201"},
 { name: "Montenegro",
    emcode: "202"},
 { name: "Gibraltar",
    emcode: "203"},
 { name: "Netherlands Antilles",
    emcode: "204"},
 { name: "Hong Kong",
    emcode: "205"},
 { name: "Macau	",
    emcode: "206"},
 { name: "Isle of Man",
    emcode: "257"},
 { name: "East Timor",
    emcode: "258"},
 { name: "Kosovo	",
    emcode: "259"},
 { name: "Åland Islands",
    emcode: "260"},
 { name: "Anguilla",
    emcode: "261"},
 { name: "Aruba	",
    emcode: "262"},
 { name: "Bermuda	",
    emcode: "263"},
 { name: "Bouvet Island",
    emcode: "264"},
 { name: "British Indian Ocean Territory	",
    emcode: "265"},
 { name: "Cayman Islands	",
    emcode: "266"},
 { name: "Christmas Island",
    emcode: "267"},
 { name: "Cocos (Keeling) Islands",
    emcode: "268"},
 { name: "Cook Islands	",
    emcode: "269"},
 { name: "Curaçao	",
    emcode: "270"},
 { name: "Falkland Islands	",
    emcode: "271"},
 { name: "French Guiana	",
    emcode: "272"},
 { name: "French Polynesia	",
    emcode: "273"},
 { name: "French Southern and Antarctic Lands",
    emcode: "274"},
 { name: "Guadeloupe	",
    emcode: "275"},
 { name: "Guernsey",
    emcode: "276"},
 { name: "Heard Island and McDonald Islands",
    emcode: "277"},
 { name: "Jersey	",
    emcode: "278"},
 { name: "Martinique	",
    emcode: "279"},
 { name: "Mayotte	",
    emcode: "280"},
 { name: "Montserrat	",
    emcode: "281"},
 { name: "New Caledonia",
    emcode: "282"},
 { name: "Niue	",
    emcode: "283"},
 { name: "Norfolk Island",
    emcode: "284"},
 { name: "Palestinian territories",
    emcode: "285"},
 { name: "Pitcairn Islands",
    emcode: "286"},
 { name: "Réunion	",
    emcode: "287"},
 { name: "Saint Barthélemy	",
    emcode: "288"},
 { name: "Saint Helena",
    emcode: "289"},
 { name: "Saint Martin",
    emcode: "290"},
 { name: "Saint Pierre and Miquelon",
    emcode: "291"},
 { name: "Sint Maarten	",
    emcode: "292"},
 { name: "South Georgia and the South Sandwich Islands	",
    emcode: "293"},
 { name: "South Sudan	",
    emcode: "294"},
 { name: "Svalbard and Jan Mayen	",
    emcode: "295"},
 { name: "Tokelau	",
    emcode: "296"},
 { name: "Turks and Caicos Islands	",
    emcode: "297"},
 { name: "United States Minor Outlying Islands",
    emcode: "298"},
 { name: "Wallis and Futuna	",
    emcode: "299"},
 { name: "Faroe Islands	",
    emcode: "300"},
];