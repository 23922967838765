<section class="d-flex justify-content-center rootCat-landingPage">
    <div class="rootCat-hero-banner"> 
    </div>
    <div class="rootCat-container-1">
    </div>
    <div class="rootCat-container-2">
    </div>
    <div class="rootCat-container-3">
    </div>
    <div class="rootCat-container-4">
    </div>
    <div class="rootCat-container-5">
    </div>
    <div class="rootCat-container-6">
    </div>
    <div class="rootCat-container-7">
    </div>
    <div class="rootCat-container-8">
    </div>
</section>
