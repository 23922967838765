<div class="nav-bar no-margin tab-hide" [ngClass]="{'hide-module' : previewId, 'show-this-module':showMegaMenu }">
    <div class="mainmenu">
        <ul class="nav justify-content-center mainmenu-container" (mouseleave)="onmouseLeave()">
            <li tabindex="0" class="sub-dropdown  dropbtn mb-productCategory"  [ngClass]="{
                        'dropbtn-bg':item.bg,
                        'dropbtn-bg-none':!item.bg,
                        'hide-menu-cntnt':!item.name
                    }" *ngFor="let item of catalogmenu; let i = index" (mouseover)="onHoverCategory($event,item,i)" (keydown.enter)="onKeydown($event)" (keydown.esc)="onKeyescape($event)"
                [hidden]="item.order ===100 || !item.categoryDisplayName">
                <a tabindex="0" [ngClass]="{'sale-highlight':item.categoryDisplayName ==='Sale'}"
                    routerLink="{{getRouterPath(item)}}" routerLinkActive="active" [ngStyle]="{'color':item.fontColour}"
                    (click)="onShowMbCategory($event,item)" [innerHtml]="item.categoryDisplayName | safe: 'html'">

                </a>
                <!-- Mega menu Desktop -->
                <ng-container *ngIf="item.amplienceData">
                    <div class="sub-menu edi-block" [ngClass]="{'submenu-ds-block':item.bg,'submenu-ds-none':!item.bg, 'stories-block': item.categoryDisplayName==='Stories',
                    'bathbody-block': item.categoryDisplayName==='Bath & Body','fragrance-block': item.categoryDisplayName==='Fragrance','gift-block': item.categoryDisplayName==='Gifts' || item.categoryDisplayName==='Geschenke'
                }"
                      >
                        <div class="container desktop-only">
                            <div class="row Link-section mt-20 colContainer">
                                <!-- column 1 -->
                                <div class="col-md-3 col-sm-12 grid-1">
                                    <div class="Link-section">
                                        <div class="sub_category" [ngClass]="l2links['L2Link-cta']?.callToActionText === 'Fragrance Finder' ? 'fragrance-finder' : '' || l2links['L2Link-cta']?.callToActionText === 'All Fragrance' ? 'all-fragrance' : ''|| l2links['L2Link-cta']?.callToActionText === 'Fragrance Type' ? 'fragrance-type' : ''" *ngFor="let l2links of item.amplienceData?.column1?.L2Links">
                                            <div class="l2-title no-pointer" *ngIf="!l2links['L2Link-cta']?.callToActionUrl" [ngClass]="l2links.L3Links?'withl3Links' : ''">
                                                {{l2links["L2Link-cta"]?.callToActionText}}</div>
                                            <a class="l2-title clickable" *ngIf="l2links['L2Link-cta']?.callToActionUrl" [routerLink]="l2links['L2Link-cta']?.callToActionUrl"
                                            (click)="l2links['L2Link-cta']?.callToActionUrl && OnLinkClick($event, l2links['L2Link-cta']?.callToActionUrl,item)" [ngClass]="!l2links.L3Links?'nol3Links' : ''">
                                                {{l2links["L2Link-cta"]?.callToActionText}}</a>
                                            <div class="submenu-title" *ngIf="l2links?.L3Links">
                                                <ng-container *ngFor="let l3Links of l2links.L3Links">
                                                    <a class="l3-title" [routerLink]="l3Links['L3link-cta']?.callToActionUrl"
                                                        [ngClass]="{'clickable':l3Links['L3link-cta']?.callToActionUrl}"
                                                        (click)="OnLinkClick($event, l3Links['L3link-cta']?.callToActionUrl,item)">
                                                        {{l3Links["L3link-cta"]?.callToActionText}}</a>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- column 2 -->
                                <div class="col-md-3 col-sm-12 grid-2"
                                    [ngClass]="{'d-flex':!item.amplienceData?.column2?.images && item.amplienceData?.column2?.images}">
                                    <ng-container *ngIf="item.amplienceData?.column2?.images?.length > 0 ; else col2linkSection">
                                         <!--one image-->
                                         <div class="row p-0 m-0" *ngIf="item.amplienceData?.column2?.images.length===1">
                                            <div class="col-md-12 m-0 p-0" *ngIf="item.amplienceData?.column2?.images[0];let col2Image1"
                                            (click)="OnLinkClick($event,col2Image1?.imageCaptionLink,item)">
                                            <a class="galTitle clickable" [routerLink]="col2Image1?.imageCaptionLink" (click)="gtmPromoclicks(item.amplienceData?.column2?.images[0])">{{col2Image1?.imageCaption}}</a>
                                            <div class="galimg"><img class="img-fluid" width="700" height="323" (click)="gtmPromoclicks(item.amplienceData?.column2?.images[0])"
                                                ngSrc="//media.moltonbrown.co.uk/i/{{col2Image1?.diImage?.image?.endpoint}}/{{col2Image1?.diImage?.image?.name}}?fmt=auto&w=350"
                                                    srcset="//media.moltonbrown.co.uk/i/{{col2Image1?.diImage?.image?.endpoint}}/{{col2Image1?.diImage?.image?.name}}?fmt=auto&w=350 1x,  //media.moltonbrown.co.uk/i/{{col2Image1?.diImage?.image?.endpoint}}/{{col2Image1?.diImage?.image?.name}}?fmt=auto&w=700 2x"
                                                    title="{{col2Image1?.imageCaption}}" alt="{{col2Image1?.imageAltText}}" /> </div>
                                        </div>
                                        </div>
                                         <!--two images with aspect ratio-->
                                        <div class="row p-0 m-0" *ngIf="item.amplienceData?.column2?.images.length===2">
                                        <div class="col-md-12 m-0 p-0" *ngIf="item.amplienceData?.column2?.images[0];let col2Image1"
                                        (click)="OnLinkClick($event,col2Image1?.imageCaptionLink,item)">
                                            <a class="galTitle clickable" [routerLink]="col2Image1?.imageCaptionLink" (click)="gtmPromoclicks(item.amplienceData?.column2?.images[0])">{{col2Image1?.imageCaption}}</a>
                                            <div class="galimg"><img class="img-fluid" width="700" height="323" (click)="gtmPromoclicks(item.amplienceData?.column2?.images[0])"
                                                ngSrc="//media.moltonbrown.co.uk/i/{{col2Image1?.diImage?.image?.endpoint}}/{{col2Image1?.diImage?.image?.name}}?fmt=auto&w=350&{{col2Image1?.diImage?.query}}"
                                                    srcset="//media.moltonbrown.co.uk/i/{{col2Image1?.diImage?.image?.endpoint}}/{{col2Image1?.diImage?.image?.name}}?fmt=auto&w=350&{{col2Image1?.diImage?.query}} 1x,  //media.moltonbrown.co.uk/i/{{col2Image1?.diImage?.image?.endpoint}}/{{col2Image1?.diImage?.image?.name}}?fmt=auto&w=700&{{col2Image1?.diImage?.query}} 2x"
                                                    title="{{col2Image1?.imageCaption}}" alt="{{col2Image1?.imageAltText}}" /> </div>
                                        </div>
                                        <div class="col-md-12 m-0 p-0" *ngIf="item.amplienceData?.column2?.images[1];let col2Image2"
                                        (click)="OnLinkClick($event,col2Image2?.imageCaptionLink,item)">
                                            <a class="galTitle clickable" [routerLink]="col2Image2?.imageCaptionLink" (click)="gtmPromoclicks(item.amplienceData?.column2?.images[1])">{{col2Image2?.imageCaption}}</a>
                                            <div class="galimg"><img class="img-fluid" width="700" height="323" (click)="gtmPromoclicks(item.amplienceData?.column2?.images[1])"
                                                ngSrc="//media.moltonbrown.co.uk/i/{{col2Image2?.diImage?.image?.endpoint}}/{{col2Image2?.diImage?.image?.name}}?fmt=auto&w=350&{{col2Image1?.diImage?.query}}"
                                                    srcset="//media.moltonbrown.co.uk/i/{{col2Image2?.diImage?.image?.endpoint}}/{{col2Image2?.diImage?.image?.name}}?fmt=auto&w=350&{{col2Image1?.diImage?.query}} 1x,  //media.moltonbrown.co.uk/i/{{col2Image2?.diImage?.image?.endpoint}}/{{col2Image2?.diImage?.image?.name}}?fmt=auto&w=700&{{col2Image1?.diImage?.query}} 2x"
                                                    title="{{col2Image2?.imageCaption}}" alt="{{col2Image2?.imageAltText}}" /> </div>
                                        </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #col2linkSection>
                                        <div class="Link-section">
                                            <div class="sub_category" *ngFor="let l2links of item.amplienceData?.column2?.L2Links">
                                                <div class="l2-title no-pointer" *ngIf="!l2links['L2Link-cta']?.callToActionUrl">
                                                    {{l2links["L2Link-cta"]?.callToActionText}}</div>
                                                <a class="l2-title clickable" *ngIf="l2links['L2Link-cta']?.callToActionUrl" [routerLink]="l2links['L2Link-cta']?.callToActionUrl"
                                                (click)="l2links['L2Link-cta']?.callToActionUrl && OnLinkClick($event, l2links['L2Link-cta']?.callToActionUrl,item)">
                                                    {{l2links["L2Link-cta"]?.callToActionText}}</a>
                                                <div class="submenu-title" *ngIf="l2links.L3Links">
                                                    <ng-container *ngFor="let l3Links of l2links.L3Links">
                                                        <a class="l3-title" [routerLink]="l3Links['L3Link-cta']?.callToActionUrl"
                                                            [ngClass]="{'clickable':l3Links['L3Link-cta']?.callToActionUrl}"
                                                            (click)="OnLinkClick($event, l3Links['L3Link-cta']?.callToActionUrl,item)">
                                                            {{l3Links["L3Link-cta"]?.callToActionText}}</a>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                                <!-- column 3 -->
                                <div class="col-md-3 col-sm-12 grid-3">
                                    <ng-container *ngIf="item.amplienceData?.column3?.images?.length > 0 ; else col3linkSection">
                                        <!--one image-->
                                        <div class="row p-0 m-0" *ngIf="item.amplienceData?.column3?.images.length===1">
                                            <div class="col-md-12 m-0 p-0" *ngIf="item.amplienceData?.column3?.images[0];let col3Image1"
                                            (click)="OnLinkClick($event,col3Image1?.imageCaptionLink,item)">
                                            <a class="galTitle clickable" [routerLink]="col3Image1?.imageCaptionLink" (click)="gtmPromoclicks(item.amplienceData?.column3?.images[0])">{{col3Image1?.imageCaption}}</a>
                                            <div class="galimg"><img class="img-fluid" width="700" height="323" (click)="gtmPromoclicks(item.amplienceData?.column3?.images[0])"
                                                ngSrc="//media.moltonbrown.co.uk/i/{{col3Image1?.diImage?.image?.endpoint}}/{{col3Image1?.diImage?.image?.name}}?fmt=auto&w=350"
                                                    srcset="//media.moltonbrown.co.uk/i/{{col3Image1?.diImage?.image?.endpoint}}/{{col3Image1?.diImage?.image?.name}}?fmt=auto&w=350 1x,  //media.moltonbrown.co.uk/i/{{col3Image1?.diImage?.image?.endpoint}}/{{col3Image1?.diImage?.image?.name}}?fmt=auto&w=700 2x"
                                                    title="{{col3Image1?.imageCaption}}" alt="{{col3Image1?.imageAltText}}" /> </div>
                                        </div>
                                        </div>
                                        <!--two images with aspect ratio-->
                                        <div class="row p-0 m-0" *ngIf="item.amplienceData?.column3?.images.length===2">
                                        <div class="col-md-12 m-0 p-0" *ngIf="item.amplienceData?.column3?.images[0];let col3Image1"
                                        (click)="OnLinkClick($event,col3Image1?.imageCaptionLink,item)">
                                            <a class="galTitle clickable" [routerLink]="col3Image1?.imageCaptionLink" (click)="gtmPromoclicks(item.amplienceData?.column3?.images[0])">{{col3Image1?.imageCaption}}</a>
                                            <div class="galimg"><img class="img-fluid" width="700" height="323" (click)="gtmPromoclicks(item.amplienceData?.column3?.images[0])"
                                                ngSrc="//media.moltonbrown.co.uk/i/{{col3Image1?.diImage?.image?.endpoint}}/{{col3Image1?.diImage?.image?.name}}?fmt=auto&w=350&{{col3Image1?.diImage?.query}}"
                                                    srcset="//media.moltonbrown.co.uk/i/{{col3Image1?.diImage?.image?.endpoint}}/{{col3Image1?.diImage?.image?.name}}?fmt=auto&w=350&{{col3Image1?.diImage?.query}} 1x,  //media.moltonbrown.co.uk/i/{{col3Image1?.diImage?.image?.endpoint}}/{{col3Image1?.diImage?.image?.name}}?fmt=auto&w=700&{{col3Image1?.diImage?.query}} 2x"
                                                    title="{{col3Image1?.imageCaption}}" alt="{{col3Image1?.imageAltText}}" /> </div>
                                        </div>
                                        <div class="col-md-12 m-0 p-0" *ngIf="item.amplienceData?.column3?.images[1];let col3Image2"
                                        (click)="OnLinkClick($event,col3Image2?.imageCaptionLink,item)">
                                            <a class="galTitle clickable" [routerLink]="col3Image2?.imageCaptionLink" (click)="gtmPromoclicks(item.amplienceData?.column3?.images[1])">{{col3Image2?.imageCaption}}</a>
                                            <div class="galimg"><img class="img-fluid" width="700" height="323" (click)="gtmPromoclicks(item.amplienceData?.column3?.images[1])"
                                                ngSrc="//media.moltonbrown.co.uk/i/{{col3Image2?.diImage?.image?.endpoint}}/{{col3Image2?.diImage?.image?.name}}?fmt=auto&w=350&{{col3Image2?.diImage?.query}}"
                                                    srcset="//media.moltonbrown.co.uk/i/{{col3Image2?.diImage?.image?.endpoint}}/{{col3Image2?.diImage?.image?.name}}?fmt=auto&w=350&{{col3Image2?.diImage?.query}} 1x,  //media.moltonbrown.co.uk/i/{{col3Image2?.diImage?.image?.endpoint}}/{{col3Image2?.diImage?.image?.name}}?fmt=auto&w=700&{{col3Image2?.diImage?.query}} 2x"
                                                    title="{{col3Image2?.imageCaption}}" alt="{{col3Image2?.imageAltText}}" /> </div>
                                        </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #col3linkSection>
                                        <div class="Link-section">
                                            <div class="sub_category" *ngFor="let l2links of item.amplienceData?.column3?.L2Links">
                                                <div class="l2-title no-pointer" *ngIf="!l2links['L2Link-cta']?.callToActionUrl">
                                                    {{l2links["L2Link-cta"]?.callToActionText}}</div>
                                                <a class="l2-title clickable" *ngIf="l2links['L2Link-cta']?.callToActionUrl" [routerLink]="l2links['L2Link-cta']?.callToActionUrl"
                                                (click)="l2links['L2Link-cta']?.callToActionUrl && OnLinkClick($event, l2links['L2Link-cta']?.callToActionUrl,item)">
                                                    {{l2links["L2Link-cta"]?.callToActionText}}</a>
                                                <div class="submenu-title" *ngIf="l2links.L3Links">
                                                    <ng-container *ngFor="let l3Links of l2links.L3Links">
                                                        <a class="l3-title" [routerLink]="l3Links['L3Link-cta']?.callToActionUrl"
                                                            [ngClass]="{'clickable':l3Links['L3Link-cta']?.callToActionUrl}"
                                                            (click)="OnLinkClick($event, l3Links['L3Link-cta']?.callToActionUrl,item)">
                                                            {{l3Links["L3Link-cta"]?.callToActionText}}</a>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                                <!-- column 4 -->
                                <div class="col-md-3 col-sm-12 grid-4">
                                    <ng-container *ngIf="item.amplienceData?.column4?.images?.length > 0 ; else col4linkSection">
                                         <!--one image-->
                                         <div class="row p-0 m-0" *ngIf="item.amplienceData?.column4?.images.length===1">
                                            <div class="col-md-12 m-0 p-0" *ngIf="item.amplienceData?.column4?.images[0];let col4Image1"
                                            (click)="OnLinkClick($event,col4Image1?.imageCaptionLink,item)">
                                            <a class="galTitle clickable" [routerLink]="col4Image1?.imageCaptionLink" (click)="gtmPromoclicks(item.amplienceData?.column4?.images[0])">{{col4Image1?.imageCaption}}</a>
                                            <div class="galimg"><img class="img-fluid" width="700" height="323" (click)="gtmPromoclicks(item.amplienceData?.column4?.images[0])"
                                                ngSrc="//media.moltonbrown.co.uk/i/{{col4Image1?.diImage?.image?.endpoint}}/{{col4Image1?.diImage?.image?.name}}?fmt=auto&w=350"
                                                    srcset="//media.moltonbrown.co.uk/i/{{col4Image1?.diImage?.image?.endpoint}}/{{col4Image1?.diImage?.image?.name}}?fmt=auto&w=350 1x, //media.moltonbrown.co.uk/i/{{col4Image1?.diImage?.image?.endpoint}}/{{col4Image1?.diImage?.image?.name}}?fmt=auto&w=700 2x"
                                                    title="{{col4Image1?.imageCaption}}" alt="{{col4Image1?.imageAltText}}" /> </div>
                                        </div>
                                        </div>
                                        <!--two images with aspect ratio-->
                                        <div class="row p-0 m-0" *ngIf="item.amplienceData?.column4?.images.length===2">
                                        <div class="col-md-12 m-0 p-0" *ngIf="item.amplienceData?.column4?.images[0];let col4Image1"
                                        (click)="OnLinkClick($event,col4Image1?.imageCaptionLink,item)">
                                            <a class="galTitle clickable" [routerLink]="col4Image1?.imageCaptionLink" (click)="gtmPromoclicks(item.amplienceData?.column4?.images[0])">{{col4Image1?.imageCaption}}</a>
                                            <div class="galimg"><img class="img-fluid" width="700" height="323" (click)="gtmPromoclicks(item.amplienceData?.column4?.images[0])"
                                                ngSrc="//media.moltonbrown.co.uk/i/{{col4Image1?.diImage?.image?.endpoint}}/{{col4Image1?.diImage?.image?.name}}?fmt=auto&w=350&{{col4Image1?.diImage?.query}}"
                                                    srcset="//media.moltonbrown.co.uk/i/{{col4Image1?.diImage?.image?.endpoint}}/{{col4Image1?.diImage?.image?.name}}?fmt=auto&w=350&{{col4Image1?.diImage?.query}} 1x, //media.moltonbrown.co.uk/i/{{col4Image1?.diImage?.image?.endpoint}}/{{col4Image1?.diImage?.image?.name}}?fmt=auto&w=700&{{col4Image1?.diImage?.query}} 2x"
                                                    title="{{col4Image1?.imageCaption}}" alt="{{col4Image1?.imageAltText}}" /> </div>
                                        </div>
                                        <div class="col-md-12 m-0 p-0" *ngIf="item.amplienceData?.column4?.images[1];let col4Image2"
                                        (click)="OnLinkClick($event,col4Image2?.imageCaptionLink,item)">
                                            <a class="galTitle clickable" [routerLink]="col4Image2?.imageCaptionLink" (click)="gtmPromoclicks(item.amplienceData?.column4?.images[1])">{{col4Image2?.imageCaption}}</a>
                                            <div class="galimg"><img class="img-fluid" width="700" height="323" (click)="gtmPromoclicks(item.amplienceData?.column4?.images[1])"
                                                ngSrc="//media.moltonbrown.co.uk/i/{{col4Image2?.diImage?.image?.endpoint}}/{{col4Image2?.diImage?.image?.name}}?fmt=auto&w=350&{{col4Image2?.diImage?.query}}"
                                                    srcset="//media.moltonbrown.co.uk/i/{{col4Image2?.diImage?.image?.endpoint}}/{{col4Image2?.diImage?.image?.name}}?fmt=auto&w=350&{{col4Image2?.diImage?.query}} 1x, //media.moltonbrown.co.uk/i/{{col4Image2?.diImage?.image?.endpoint}}/{{col4Image2?.diImage?.image?.name}}?fmt=auto&w=700&{{col4Image2?.diImage?.query}} 2x"
                                                    title="{{col4Image2?.imageCaption}}" alt="{{col4Image2?.imageAltText}}" /> </div>
                                        </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #col4linkSection>
                                        <div class="Link-section">
                                            <div class="sub_category" *ngFor="let l2links of item.amplienceData?.column4?.L2Links">
                                                <div class="l2-title no-pointer" *ngIf="!l2links['L2Link-cta']?.callToActionUrl">
                                                    {{l2links["L2Link-cta"]?.callToActionText}}</div>
                                                    <a class="l2-title clickable" [routerLink]="l2links['L2Link-cta']?.callToActionUrl"  *ngIf="l2links['L2Link-cta']?.callToActionUrl"
                                                (click)="l2links['L2Link-cta']?.callToActionUrl && OnLinkClick($event, l2links['L2Link-cta']?.callToActionUrl,item)">
                                                    {{l2links["L2Link-cta"]?.callToActionText}}</a>
                                                <div class="submenu-title" *ngIf="l2links.L3Links">
                                                    <ng-container *ngFor="let l3Links of l2links.L3Links">
                                                        <a class="l3-title" [routerLink]="l3Links['L3Link-cta']?.callToActionUrl"
                                                            [ngClass]="{'clickable':l3Links['L3Link-cta']?.callToActionUrl}"
                                                            (click)="OnLinkClick($event, l3Links['L3Link-cta']?.callToActionUrl,item)">
                                                            {{l3Links["L3Link-cta"]?.callToActionText}}</a>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!-- Mega menu Desktop ends-->
            </li>
        </ul>
    </div>
</div>


    <!-- sidenav starts -->
    <div #mySidenav id="mySidenav" class="sidenav"
        *ngIf="singletonServ.translateData"
        [ngClass]="{
             'mb-menu-block':sidemenuStatus,
             'mb-menu-none':!sidemenuStatus
            }">
            <input type="hidden" id="sidenavInput">
            <div class="flex-container flex-box">
                <div *ngIf="singletonServ.loggedIn;else joinBlock" class="flex-box flex-row my-account-login">
                    <i class="icon-user-md"></i><a (click)="onTapAccount()">{{(singletonServ.translateData.menu.myAccount)?singletonServ.translateData.menu.myAccount:''}}</a>
                </div>
                <ng-template #joinBlock>
                    <div class="flex-box flex-row my-account-login">
                        <i class="icon-user-md"></i><a (click)="onTapLogin()">{{(singletonServ.translateData.menu.login)?singletonServ.translateData.menu.login:''}}</a>
                    </div>
                </ng-template>
                <div class="flex-box flex-row store"><i class="icon-pin-md"></i><a (click)="onFindStoreClick()">{{(singletonServ.translateData.menu.stores)?singletonServ.translateData.menu.stores:''}}</a></div>
            </div>
            <div class="menu-wrapper">
                <!-- L1 start -->
                <div class="main-menu main-menu-l1" style="height: auto;">
                    <ul class="mob-scroll" role="navigation">

                        <li *ngFor="let sidemenu of singletonServ.menudata; let menuId= index"
                            [hidden]="sidemenu.order ===100 || !sidemenu.categoryDisplayName" aria-controls="panel-1" role="tablist">
                          <ng-container>
                                <a *ngIf="sidemenu.amplienceData" [innerHtml]="sidemenu.categoryDisplayName |titlecase | safe: 'html'"
                                    (click)="showNextCategory($event, false)" aria-label="link" aria-live="assertive"
                                    class="main-nav-parent menu sub-nav-category">
                                </a>
                                <a (click)="showNextCategory($event, '/store/'+sidemenu.name+'/'+sidemenu.id)"
                                    [innerHtml]="sidemenu.categoryDisplayName |titlecase | safe: 'html'" *ngIf="!sidemenu.amplienceData"
                                    aria-label="link" aria-live="assertive" class="main-nav-parent menu">
                                </a>

                            </ng-container>
                            <!-- L2 start -->
                            <ng-container *ngIf="sidemenu.amplienceData">
                                <div class="main-menu">
                                    <div class="back-btn-header"  (click)="showPrevCategory($event)">
                                        <label class="back-btn" for="item-2">{{(singletonServ.translateData.menu.back)?singletonServ.translateData.menu.back:''}}
                                        </label>
                                    </div>
                                    <ul>
                                        <!-- column 1 L2 start -->
                                        <li aria-controls="column1 panel-2" role="tablist"
                                            *ngFor="let l2links of sidemenu.amplienceData?.column1?.L2Links; let subId= index">
                                            <a (click)="showNextCategory($event, l2links['L2Link-cta']?.callToActionUrl)"
                                                aria-label="link" aria-live="assertive" [routerLink]="l2links['L2Link-cta']?.callToActionUrl"
                                                [innerHtml]="l2links['L2Link-cta']?.callToActionText | safe: 'html'"
                                                class="column1 isL2" [ngClass]="{'sub-nav-category':l2links.L3Links}">
                                            </a>
                                            <!-- L3 start -->
                                            <div class="main-menu level3">
                                                <div class="back-btn-header"  (click)="showPrevCategory($event)">
                                                    <label class="back-btn" for="item-2-3">
                                                        {{(singletonServ.translateData.menu.back)?singletonServ.translateData.menu.back:''}}
                                                    </label>
                                                </div>
                                                <ul>
                                                    <li aria-controls="panel-3" role="tablist"
                                                        *ngFor="let l3Links of l2links.L3Links; let trackId= index">
                                                        <a (click)="showNextCategory($event, l3Links['L3link-cta']?.callToActionUrl)"
                                                            aria-label="link" aria-live="assertive" [routerLink]="l3Links['L3link-cta']?.callToActionUrl"
                                                            [innerHtml]="l3Links['L3link-cta']?.callToActionText | safe: 'html'"
                                                            class="column1 isL3"></a>

                                                    </li>
                                                </ul>
                                            </div>
                                            <!-- L3 end -->
                                        </li>
                                        <!-- column 1 L2 end -->
                                        <!-- column 2 L2 start -->
                                        <li aria-controls="column2 panel-2" role="tablist"
                                            *ngFor="let l2links of sidemenu.amplienceData?.column2?.L2Links; let subId= index">
                                            <a (click)="showNextCategory($event, l2links['L2Link-cta']?.callToActionUrl)"
                                                aria-label="link" aria-live="assertive" [routerLink]="l2links['L2Link-cta']?.callToActionUrl"
                                                [innerHtml]="l2links['L2Link-cta']?.callToActionText | safe: 'html'"
                                                class="column2 isL2" [ngClass]="{'sub-nav-category':l2links.L3Links}">
                                            </a>
                                            <!-- L3 start -->
                                            <ng-container *ngIf="l2links.L3Links">
                                                <div class="main-menu level3">
                                                    <div class="back-btn-header" (click)="showPrevCategory($event)">
                                                        <label class="back-btn" for="item-2-3" >
                                                            {{(singletonServ.translateData.menu.back)?singletonServ.translateData.menu.back:''}}
                                                        </label>
                                                    </div>
                                                    <ul>
                                                        <li aria-controls="panel-3" role="tablist"
                                                            *ngFor="let l3Links of l2links.L3Links">
                                                            <a aria-label="link" aria-live="assertive"
                                                                (click)="showNextCategory($event, l3Links['L3Link-cta']?.callToActionUrl)"
                                                                [routerLink]="l3Links['L3Link-cta']?.callToActionUrl" [innerHtml]="l3Links['L3Link-cta']?.callToActionText | safe: 'html'"
                                                                class="column2 isL3"></a>

                                                        </li>
                                                    </ul>
                                                </div>
                                            </ng-container>
                                            <!-- L3 end -->
                                        </li>
                                        <!-- column 2 L2 end -->
                                        <!-- column 3 L2 start -->
                                        <li aria-controls="column3 panel-2" role="tablist"
                                            *ngFor="let l2links of sidemenu.amplienceData?.column3?.L2Links; let subId= index">
                                            <a (click)="showNextCategory($event, l2links['L2Link-cta']?.callToActionUrl)"
                                                aria-label="link" aria-live="assertive" [routerLink]="l2links['L2Link-cta']?.callToActionUrl"
                                                [innerHtml]="l2links['L2Link-cta']?.callToActionText | safe: 'html'"
                                                class="column3 isL2" [ngClass]="{'sub-nav-category':l2links.L3Links}">
                                            </a>
                                            <!-- L3 start -->
                                            <ng-container *ngIf="l2links.L3Links">
                                                <div class="main-menu level3">
                                                    <div class="back-btn-header"  (click)="showPrevCategory($event)">
                                                        <label class="back-btn" for="item-2-3">
                                                            {{(singletonServ.translateData.menu.back)?singletonServ.translateData.menu.back:''}}
                                                        </label>
                                                    </div>
                                                    <ul>
                                                        <li aria-controls="panel-3" role="tablist"
                                                            *ngFor="let l3Links of l2links.L3Links">
                                                            <a aria-label="link" aria-live="assertive"
                                                                (click)="showNextCategory($event, l3Links['L3Link-cta']?.callToActionUrl)"
                                                                [routerLink]="l3Links['L3Link-cta']?.callToActionUrl"
                                                                [innerHtml]="l3Links['L3Link-cta']?.callToActionText | safe: 'html'"
                                                                class="column3 isL3"></a>

                                                        </li>
                                                    </ul>
                                                </div>
                                            </ng-container>
                                            <!-- L3 end -->
                                        </li>
                                        <!-- column 3 L2 end -->
                                        <!-- column 4 L2 start -->
                                        <li aria-controls="column4 panel-2" role="tablist"
                                            *ngFor="let l2links of sidemenu.amplienceData?.column4?.L2Links; let subId= index">
                                            <a (click)="showNextCategory($event, l2links['L2Link-cta']?.callToActionUrl)"
                                                aria-label="link" aria-live="assertive" [routerLink]="l2links['L2Link-cta']?.callToActionUrl"
                                                [innerHtml]="l2links['L2Link-cta']?.callToActionText | safe: 'html'"
                                                class="column4 isL2" [ngClass]="{'sub-nav-category':l2links.L3Links}">
                                            </a>
                                            <!-- L3 start -->
                                            <ng-container *ngIf="l2links.L3Links">
                                                <div class="main-menu level3">
                                                    <div class="back-btn-header"  (click)="showPrevCategory($event)">
                                                        <label class="back-btn" for="item-2-3" >
                                                            {{(singletonServ.translateData.menu.back)?singletonServ.translateData.menu.back:''}}
                                                        </label>
                                                    </div>
                                                    <ul>
                                                        <li aria-controls="panel-3" role="tablist"
                                                            *ngFor="let l3Links of l2links.L3Links">
                                                            <a aria-label="link" aria-live="assertive" [routerLink]="l3Links['L3Link-cta']?.callToActionUrl"
                                                                (click)="showNextCategory($event, l3Links['L3Link-cta']?.callToActionUrl)"
                                                                [innerHtml]="l3Links['L3Link-cta']?.callToActionText | safe: 'html'"
                                                                class="column4 isL3"></a>

                                                        </li>
                                                    </ul>
                                                </div>
                                            </ng-container>
                                            <!-- L3 end -->
                                        </li>
                                        <!-- column 4 L2 end -->
                                            <!--L2 Image wrapper-->
                                        <div *ngIf="
                                        sidemenu.amplienceData?.column2?.images?.length > 0 ||
                                        sidemenu.amplienceData?.column3?.images?.length > 0 ||
                                        sidemenu.amplienceData?.column4?.images?.length > 0">
                                        <div class="menu-imagewrapper">
                                            <div *ngIf="sidemenu.amplienceData?.column2?.images?.length > 0" class="col-sm-12">
                                                <div *ngIf="sidemenu.amplienceData?.column2?.images[0];let col2Image1" class="l2-imagewrapper" (click)="showNextCategory($event, col2Image1?.imageCaptionLink)">
                                                    <div class="col-sm-12 menu-image-title">{{col2Image1?.imageCaption}}</div>
                                                    <div class="col-sm-12 menu-image"><img class="l2-image" width="564" height="564"
                                                        ngSrc="//media.moltonbrown.co.uk/i/{{col2Image1?.diImage?.image?.endpoint}}/{{col2Image1?.diImage?.image?.name}}?fmt=auto&w=282&{{col2Image1?.diImage?.query}}"
                                                        srcset="//media.moltonbrown.co.uk/i/{{col2Image1?.diImage?.image?.endpoint}}/{{col2Image1?.diImage?.image?.name}}?fmt=auto&w=282&{{col2Image1?.diImage?.query}} 1x,
                                                        //media.moltonbrown.co.uk/i/{{col2Image1?.diImage?.image?.endpoint}}/{{col2Image1?.diImage?.image?.name}}?fmt=auto&w=564&{{col2Image1?.diImage?.query}} 2x"
                                                            title="{{col2Image1?.imageCaption}}" alt="{{col2Image1?.imageAltText}}" /> </div>
                                                </div>
                                                <div *ngIf="sidemenu.amplienceData?.column2?.images[1];let col2Image2" class="l2-imagewrapper" (click)="showNextCategory($event, col2Image2?.imageCaptionLink)">
                                                    <div class="col-sm-12 menu-image-title">{{col2Image2?.imageCaption}}</div>
                                                    <div class="col-sm-12 menu-image"><img class="l2-image" width="564" height="564"
                                                        ngSrc="//media.moltonbrown.co.uk/i/{{col2Image2?.diImage?.image?.endpoint}}/{{col2Image2?.diImage?.image?.name}}?fmt=auto&w=282&{{col2Image2?.diImage?.query}}"
                                                        srcset="//media.moltonbrown.co.uk/i/{{col2Image2?.diImage?.image?.endpoint}}/{{col2Image2?.diImage?.image?.name}}?fmt=auto&w=282&{{col2Image2?.diImage?.query}} 1x,
                                                        //media.moltonbrown.co.uk/i/{{col2Image2?.diImage?.image?.endpoint}}/{{col2Image2?.diImage?.image?.name}}?fmt=auto&w=564&{{col2Image2?.diImage?.query}} 2x"
                                                            title="{{col2Image2?.imageCaption}}" alt="{{col2Image2?.imageAltText}}" /> </div>
                                                </div>
                                            </div>
                                            <div *ngIf="sidemenu.amplienceData?.column3?.images?.length > 0" class="col-sm-12">
                                                <div *ngIf="sidemenu.amplienceData?.column3?.images[0];let col3Image1" class="l2-imagewrapper" (click)="showNextCategory($event, col3Image1?.imageCaptionLink)">
                                                    <div class="col-sm-12 menu-image-title">{{col3Image1?.imageCaption}}</div>
                                                    <div class="col-sm-12 menu-image"><img class="l2-image" width="564" height="564"
                                                        ngSrc="//media.moltonbrown.co.uk/i/{{col3Image1?.diImage?.image?.endpoint}}/{{col3Image1?.diImage?.image?.name}}?fmt=auto&w=282&{{col3Image1?.diImage?.query}}"
                                                        srcset="//media.moltonbrown.co.uk/i/{{col3Image1?.diImage?.image?.endpoint}}/{{col3Image1?.diImage?.image?.name}}?fmt=auto&w=282&{{col3Image1?.diImage?.query}} 1x,
                                                        //media.moltonbrown.co.uk/i/{{col3Image1?.diImage?.image?.endpoint}}/{{col3Image1?.diImage?.image?.name}}?fmt=auto&w=564&{{col3Image1?.diImage?.query}} 2x"
                                                            title="{{col3Image1?.imageCaption}}" alt="{{col3Image1?.imageAltText}}" /> </div>
                                                </div>
                                                <div *ngIf="sidemenu.amplienceData?.column3?.images[1];let col3Image2" class="l2-imagewrapper" (click)="showNextCategory($event, col3Image2?.imageCaptionLink)">
                                                    <div class="col-sm-12 menu-image-title">{{col3Image2?.imageCaption}}</div>
                                                    <div class="col-sm-12 menu-image"><img class="l2-image" width="564" height="564"
                                                        ngSrc="//media.moltonbrown.co.uk/i/{{col3Image2?.diImage?.image?.endpoint}}/{{col3Image2?.diImage?.image?.name}}?fmt=auto&w=282&{{col3Image2?.diImage?.query}}"
                                                        srcset="//media.moltonbrown.co.uk/i/{{col3Image2?.diImage?.image?.endpoint}}/{{col3Image2?.diImage?.image?.name}}?fmt=auto&w=282&{{col3Image2?.diImage?.query}} 1x,
                                                        //media.moltonbrown.co.uk/i/{{col3Image2?.diImage?.image?.endpoint}}/{{col3Image2?.diImage?.image?.name}}?fmt=auto&w=564&{{col3Image2?.diImage?.query}} 2x"
                                                            title="{{col3Image2?.imageCaption}}" alt="{{col3Image2?.imageAltText}}" /> </div>
                                                </div>
                                            </div>
                                            <div *ngIf="sidemenu.amplienceData?.column4?.images?.length > 0" class="col-sm-12">
                                                <div *ngIf="sidemenu.amplienceData?.column4?.images[0];let col4Image1" class="l2-imagewrapper" (click)="showNextCategory($event, col4Image1?.imageCaptionLink)">
                                                    <div class="col-sm-12 menu-image-title">{{col4Image1?.imageCaption}}</div>
                                                    <div class="col-sm-12 menu-image"><img class="l2-image" width="564" height="564"
                                                        ngSrc="//media.moltonbrown.co.uk/i/{{col4Image1?.diImage?.image?.endpoint}}/{{col4Image1?.diImage?.image?.name}}?fmt=auto&w=282&{{col4Image1?.diImage?.query}}"
                                                        srcset="//media.moltonbrown.co.uk/i/{{col4Image1?.diImage?.image?.endpoint}}/{{col4Image1?.diImage?.image?.name}}?fmt=auto&w=282&{{col4Image1?.diImage?.query}} 1x,
                                                        //media.moltonbrown.co.uk/i/{{col4Image1?.diImage?.image?.endpoint}}/{{col4Image1?.diImage?.image?.name}}?fmt=auto&w=564&{{col4Image1?.diImage?.query}} 2x"
                                                            title="{{col4Image1?.imageCaption}}" alt="{{col4Image1?.imageAltText}}" /> </div>
                                                </div>
                                                <div *ngIf="sidemenu.amplienceData?.column4?.images[1];let col4Image2" class="l2-imagewrapper" (click)="showNextCategory($event, col4Image2?.imageCaptionLink)">
                                                    <div class="col-sm-12 menu-image-title">{{col4Image2?.imageCaption}}</div>
                                                    <div class="col-sm-12 menu-image"><img class="l2-image" width="564" height="564"
                                                        ngSrc="//media.moltonbrown.co.uk/i/{{col4Image2?.diImage?.image?.endpoint}}/{{col4Image2?.diImage?.image?.name}}?fmt=auto&w=282&{{col4Image2?.diImage?.query}}"
                                                        srcset="//media.moltonbrown.co.uk/i/{{col4Image2?.diImage?.image?.endpoint}}/{{col4Image2?.diImage?.image?.name}}?fmt=auto&w=282&{{col4Image2?.diImage?.query}} 1x,
                                                        //media.moltonbrown.co.uk/i/{{col4Image2?.diImage?.image?.endpoint}}/{{col4Image2?.diImage?.image?.name}}?fmt=auto&w=564&{{col4Image2?.diImage?.query}} 2x"
                                                            title="{{col4Image2?.imageCaption}}" alt="{{col4Image2?.imageAltText}}" /> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <!--L2 Image wrapper end-->
                                    </ul>
                                </div>
                            </ng-container>
                            <!-- L2 end -->


                        </li>

                    </ul>
                </div>
                <!-- L1 end -->
            </div>
            <div class="mobile-menu-footer" *ngIf="footerAmplienceData as footerData"  [ngClass]="{'hide-module' : previewId, 'show-this-module':showFooter }">
                <!-- <div class="menu-item"><a href="{{footerData['join-guest-list']?.callToActionUrl}}">{{footerData['join-guest-list']?.callToActionText}}</a></div> -->
                <div>
                    <ul>
                        <li
                        class="country-change"
                        *ngFor="let data of countries; let k = index"
                        [hidden]="!data.current"
                        data-bs-toggle="modal"
                        data-bs-target="#countryChangeModal"
                      >
                        <a class="footer-sub-item"> Change Country </a>
                        <img width="24" height="16" ngSrc="{{ '/assets/Redesign/flag-' + data.isoCode + '.svg' }}" alt="{{  data.name + ' flag' }}"
                        />

                      </ul>
                </div>
                <div class="menu-item"><a href="{{footerData['customer-service']['links'][0]['link-cta']?.callToActionUrl}}">{{footerData['customer-service']['links'][0]['link-cta']?.callToActionText}}</a></div>
                <div class="menu-item"><a href="store/customer-service">{{footerData['customer-service']?.heading}}</a></div>
                <div class="menu-item"><a href="{{footerData['hotel-and-corporates']['links'][0]['link-cta']?.callToActionUrl}}">{{footerData['hotel-and-corporates']['links'][0]['link-cta']?.callToActionText}}</a></div>
                <div class="menu-item"><a href="{{footerData['about-moltonbrown']['links'][0]['link-cta']?.callToActionUrl}}">{{footerData['about-moltonbrown']['links'][0]['link-cta']?.callToActionText}}</a></div>
                <div class="menu-item"><a href="{{footerData['customer-service']['links'][5]['link-cta']?.callToActionUrl}}">{{footerData['customer-service']['links'][5]['link-cta']?.callToActionText}}</a></div>
            </div>
    </div>


    <!-- sidenav ends -->
    <div
        class="catalog-backdrop"
        (swipe)="swipMenu($event)"
        (mouseenter)="onhidesubmenu()"
        (click)="toggleMenu($event)"
        [ngClass]="{
             'catalog-backdrop-block':mouseEnter
            }">
      </div>


    <div
    class="modal"
    id="popupmobilecountryChange"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    >
    <div class="modal-dialog modal-full" role="document">
      <div class="modal-content">
        <div class="modal-body modal-country-height" *ngIf="singletonServ.translateData">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img
            ngSrc="/assets/imgs/livedata/close_black.png" alt="close" width="18" height="18"
            /></button
          ><br />

          <div class="text-center cg-country mb-cg-country">{{(singletonServ.translateData.changeCountrypopup.Heading)?singletonServ.translateData.changeCountrypopup.Heading:''}}</div>

          <p class="cg-country-sub text-center">
            {{singletonServ.translateData.changeCountrypopup.middleText}}
          </p>

          <p class="text-center">
            {{singletonServ.translateData.changeCountrypopup.textSet1}}
          </p>

          <p class="text-center">
            {{singletonServ.translateData.changeCountrypopup.textSet2}}
          </p>

          <p class="text-center">
            {{singletonServ.translateData.changeCountrypopup.textSet3}}<br />
            Molton Brown
          </p>
          <div class="form-inline text-center">
            <button
              class=" countryPop countryPopCancel"
              (click)="onCancelModal(false)"
              data-dismiss="modal"
            >
            {{singletonServ.translateData.menu.cancel}}
            </button>
            <button
              class=" countryPop countryPopConfirm"
              (click)="onCancelModal(true)"
              data-dismiss="modal"
            >
            {{singletonServ.translateData.menu.confirm}}
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
