<h4 style="background-color: chocolate; text-align: center;">Angular upgrade</h4>
<header [ngClass]="{'hide-module' : previewId, 'show-this-module':showMegaMenu }">
  <div class="row d-flex align-items-center header-bottom">
    <div class="col-md-3 text-center mb-icon desktop-only">
      <div class="col-md-2 display">
        <a class="header-icons" (click)="setFocus()" data-bs-toggle="modal" data-bs-target="#searchModal">
          <i class="icon-search-md mx-auto"><span class="desktop-only header-icons"
              *ngIf="singletonServ.translateData">{{singletonServ.translateData.menu.search}}</span></i></a>
      </div>
      <div class="col-md-2 desktop-only">
        <a tabindex="0" class="header-icons" (click)="OnIconLinkClick($event,'/store/company/stores')" routerLink="/store/company/stores">
          <i class="icon-pin-md mx-auto"><span class="desktop-only header-icons"
              *ngIf="singletonServ.translateData">{{singletonServ.translateData.menu.stores}}</span></i></a>
      </div>
    </div>
    <div class="col-md-6 desktop-only mx-auto mb-logo-desktop">
      <a (click)="goToHome($event)" href="" tabindex="0" class="d-block">
        <img class="img-fluid d-block"
          src="https://media.moltonbrown.co.uk/i/moltonbrown/MB-logo-desktop?fmt=auto&w=1080" srcset="https://media.moltonbrown.co.uk/i/moltonbrown/MB-logo-desktop?fmt=auto&w=550 1x,
          https://media.moltonbrown.co.uk/i/moltonbrown/MB-logo-desktop?fmt=auto&w=1080 2x"
          alt="Molton Brown"></a>
    </div>
    <div class="col-md-3 text-center mb-icon-end desktop-only mb-header-right">
      <div class="col-md-4 desktop-only user-icon">
        <div *ngIf="singletonServ.loggedIn; else loggedOut">
          <a class="header-icons" (click)="onAccountClick($event)" tabindex="0">
            <i class="icon-user-md mx-auto"><span class="desktop-only header-icons"
                *ngIf="singletonServ.translateData">{{singletonServ.translateData.menu.myAccount}}</span></i>
          </a>
          <div class="dropdown-content" id="userDropdown">
            <button class="btn-black user-btn" (click)="onProfile($event)"
              *ngIf="singletonServ.translateData">{{singletonServ.translateData.menu.myAccount}}</button>
            <button class="btn-grey-outline user-btn" (click)="onAccountSignOut($event)"
              *ngIf="singletonServ.translateData">{{singletonServ.translateData.menu.logOut}}</button>

          </div>
        </div>
        <ng-template #loggedOut>
          <a class="header-icons" (click)="OnIconLinkClick($event,'/store/myacc/mbLogin')" routerLink="/store/myacc/mbLogin">
            <i class="icon-user-md mx-auto"><span class="desktop-only header-icons login-desktop"
                *ngIf="singletonServ.translateData">{{singletonServ.translateData.menu.login}}</span></i></a>
        </ng-template>
      </div>
      <div class="col-md-2 display">
        <a class="header-icons" data-bs-toggle="modal" data-bs-target="#viewBagModal" (click)="isLoggedInUser()" >
          <i class="icon-bag-md mx-auto">
            <img *ngIf="cartLoading === 'true'" alt="loading-cart" class="loading-cart img-fluid" src="/assets/Redesign/loading-cart.gif">
            <span *ngIf="cartLoading === 'false' && cartTotalCount !== '0' " class="badge-cart" [innerHTML]='cartTotalCount > 99 ? "99+" : (cartTotalCount) '></span>
            <span class="desktop-only header-icons"
              *ngIf="singletonServ.translateData">{{singletonServ.translateData.menu.viewbag}}</span></i></a>
      </div>
    </div>
    <div class="col-sm-2 mob-only mb-icon menu-icon">
      <div class="catalog-backdrop" (swipe)="swipMenu($event)" (click)="toggleMenu($event)" style="position: absolute;"
        [ngClass]="{
         'responsive-overlay':sidemenuStatus
        }">
      </div>
      <nav aria-label="Open the menu" role="navigation" class="nav-menu-mobile" (click)="onSidemenutap()">
        <i class="icon-menu-md mx-auto icon-size"></i>
      </nav>
    </div>
    <div class="col-sm-1 display mob-only mb-icon">
      <a class="header-icons d-flex" data-bs-toggle="modal" data-bs-target="#searchModal"  (click)="onsearchClicked()">
        <i class="icon-search-md mx-auto icon-size"><span class="desktop-only header-icons"
            *ngIf="singletonServ.translateData">{{singletonServ.translateData.menu.search}}</span></i></a>
    </div>
    <div class="col-sm-6 mob-only mob-logo" id="molton_brown_mob_logo">
      <a (click)="goToHome($event)" href="" tabindex="0" class="d-block">
        <img class="img-fluid d-block"
          src="https://media.moltonbrown.co.uk/i/moltonbrown/MB-logo-desktop?fmt=auto&w=874" srcset="https://media.moltonbrown.co.uk/i/moltonbrown/MB-logo-desktop?fmt=auto&w=874 1x,
                https://media.moltonbrown.co.uk/i/moltonbrown/MB-logo-desktop?fmt=auto&w=1536 2x"
          alt="Molton Brown">
      </a>
    </div>
    <div class="col-sm-1 mob-only mb-icon-end">
      <a class="header-icons d-flex" aria-label="Stores" routerLink="/store/company/stores">
        <i class="icon-pin-md mx-auto icon-size"></i></a>
    </div>
    <div class="col-sm-2 display mob-only mb-icon-end cart-icon">
      <a class="header-icons" routerLink="/store/mbcart/mbBasket">
        <i class="icon-bag-md mx-auto icon-size">
          <img *ngIf="cartLoading === 'true'" alt="loading-cart" class="loading-cart img-fluid" src="/assets/Redesign/loading-cart.gif">
          <span *ngIf="cartLoading === 'false' && cartTotalCount !== '0' " class="badge-cart" [innerHTML]='cartTotalCount > 99 ? "99+" : (cartTotalCount) '></span>
          <span class="desktop-only header-icons"
            *ngIf="singletonServ.translateData">{{singletonServ.translateData.menu.viewbag}}</span></i></a>
    </div>
  </div>
  <!--End of Row-->
</header>

<!-- start of menu-block -->
<div class="menu-bar">
  <app-menu [sidemenuStatus]="sidemenuStatus" [catalogMenu]="mbCategories"></app-menu>
</div>


<div class="modal" tabindex="-1" class="modal" id="searchModal" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">

        <button type="button" class="close" (click)="clearSearch()" data-bs-dismiss="modal" #closeBtn aria-label="Close">
          <i class="icon-close-lg"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="header-bottom-search-box">
          <form *ngIf="menuLocalData" [formGroup]="searchForm" (keydown)="keyDownFunction($event)"
            (ngSubmit)="onSubmitForm($event)">
            <span hidden><label for="product_channel" aria-label="Type to search">{{menuLocalData.menu.mobileSearch}}</label></span>
            <input #searchInput class="text header-bottom-search-input " type="text" id="product_channel"
              autocomplete="off" placeholder="{{menuLocalData.menu.mobileSearch}}" formControlName="search"
              (input)="onSearcKeyUp($event)" [ngClass]="{
                            'searchbox-open':searchCtgry,
                            'enable-searchIcon':enableSearchicon
                      }">


            <div class="auto-completeBlock" *ngIf="searchResults.length>0" (mouseleave)="onLeaveSearchBox()"
              (mouseenter)="showSearchBox()"
              [ngClass]="{'searchBlock-block':searchBlock,'searchBlock-none':!searchBlock}">
              <ul>
                <li *ngFor="let searchItem of searchResults;">
                  <a style="clear: both;" routerLink="{{getSearchRouterPath(searchItem)}}" routerLinkActive="active"
                    (click)="onSearchProduct($event,searchItem)">
                    <picture>
                      <source
                        srcset="{{searchItem.amplienceImageName}}.webp?$thImg$ 1x, {{searchItem.amplienceImageName}}.webp?$thImgRetina$ 2x"
                        type="image/webp" media="(min-width: 769px)" />
                      <source
                        srcset="{{searchItem.amplienceImageName}}.webp?$thImg$ 1x,{{searchItem.amplienceImageName}}.webp?$thImgRetina$ 2x"
                        type="image/webp" media="(max-width: 768px)">
                      <source
                        srcset="{{searchItem.amplienceImageName}}?$thImg$ 1x,{{searchItem.amplienceImageName}}?$thImgRetina$ 2x"
                        type="image/jpeg" media="(max-width: 768px)">
                      <img src="{{searchItem.amplienceImageName}}?"
                        srcset="{{searchItem.amplienceImageName}}?$thImg$ 1x, {{searchItem.amplienceImageName}}?$thImgRetina$ 2x ">
                    </picture>
                    <span *ngIf="searchItem.categoryDisplayName" [innerHtml]="searchItem.categoryDisplayName"></span>
                    <span *ngIf="searchItem.productDisplayName" [innerHtml]="searchItem.productDisplayName"></span>
                  </a>
                </li>
              </ul>
              <button class="btn-black search_more" (click)="onSearchResults()">
                {{singletonServ.translateData.menu.viewMoreButton}}
              </button>
            </div>
            <div class="noResultBlock"  *ngIf="searchBlockNoResult" >
              <span>{{singletonServ.translateData.menu.noResultFound}} </span>

              <span>‘{{searchfreetext}}’</span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
