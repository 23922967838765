import {
  Component,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Inject,
  PLATFORM_ID
} from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { CSCustomerService } from "./cs-header.service";
import { RegistrationForm } from "../../forms/registration.form";
import { UntypedFormBuilder, UntypedFormGroup,Validators,UntypedFormControl } from "@angular/forms";
import { SingletonService } from "../../services/singleton.service";
import { patternValidator } from '../../forms/pattern-validator';
import { Subscription, Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
declare var window: any;
declare var $:any;
@Component({
  selector: "app-cs-header",
  templateUrl: "./cs-header.component.html",
  styleUrls: ["./cs-header.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class CsHeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('csAgentRef') csAgentRef:ElementRef;
  @ViewChild('customerRef') customerRef:ElementRef;
  pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  asmCustomer: boolean;
  subscription: Subscription;
  private unsubscribe$=new Subject<void>();
  authForm: UntypedFormGroup;
  ASMCustomerform: UntypedFormGroup;
  csAgent: boolean;
  searchBlock: boolean;
  searchResults: any;
  toggleAsmHead: boolean;
  searchOrderResults: any;
  searchOrderBlock: boolean;
  customerDetail: any;
  startSession: boolean;
  timex: any;
  seconds: any;
  retrieveOrder: boolean;
  hours: any;
  csAgentName: string;
  unknownUser: boolean;
  errMsgObj: any;
  csAgentLogged:boolean;
  asm:any;
  csErrMsg:any;
  checkwithEmailValidation:boolean;
  constructor(
    public location: Location,
    public router: Router,
    public route: ActivatedRoute,
    public singletonServ: SingletonService,
    public fb: UntypedFormBuilder,
    public customerForm: RegistrationForm,
    public csService: CSCustomerService,
    @Inject(PLATFORM_ID) public platformId: Object
  ) {
    this.csErrMsg={
      status: false,
      description: 'Please Fill The Details'
    };
    this.errMsgObj = {
      status: false,
      description: 'Please Fill The Details'
    };
    this.authForm = this.fb.group(customerForm.ASMform());
    this.ASMCustomerform = this.fb.group(customerForm.ASMCustomerform());
  }

  async ngOnInit() {
    const baseSite=this.singletonServ.catalogVersion;
    if(baseSite){   
      this.toggleAsmHead = true;
      await this.constructASMView();
    }
    
  }
  ngAfterViewInit(){
    const that=this;
   this.singletonServ.getMessage().pipe(takeUntil(this.unsubscribe$)).subscribe(message => {
      if (message.invalidAgentToken) {
       that.onAgentSignOut(false);
      }
    });
  }
  onCloseBar() {
    this.toggleAsmHead = !this.toggleAsmHead;
  }
  keyDownFunction(event){
    if(event.keyCode==13){
      event.target.blur();
      this. onSubmitCSForm();
      return false;
    }
}
  onSubmitCSForm() {
    const that=this;
    let emailIsValid:boolean;
    const baseSite=this.singletonServ.catalogVersion;
    if(baseSite){
        if (this.authForm.valid) {
          that.    csErrMsg=undefined;
          const _obj = this.authForm.value;
          let pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
          emailIsValid = pattern.test(_obj.username);
        if(emailIsValid) {
          if(that.csAgentRef){
            that.authForm.controls['username'].setErrors({'incorrect': true});
            that.csAgentRef.nativeElement.focus();
            that.checkwithEmailValidation=true;
          }
        }else{
          that.checkwithEmailValidation=false;
          this.csService.createUser(_obj).subscribe(
            response => {
              this.csAgentName=this.authForm.value['username'];
              this.csAgent = false;
              this.csAgentLogged=true;
              const _obj = {
                csAgentName:this.authForm.value['username'],
                csAgent: false,
                agentToken: response["access_token"],
                uid:this.authForm.value['username']
              };
              if(this.getBrowserSessionData(baseSite.csAssistant)){
                const _assistant=JSON.parse(this.getBrowserSessionData(baseSite.csAssistant));
                _assistant.csAgentName=this.authForm.value['username'];
                this.setBrowserSessionData(baseSite.csAssistant,JSON.stringify(_assistant))
              }         
              this.setBrowserSessionData(baseSite.csCustomer, JSON.stringify(_obj));
              if(this.customerRef){
                this.customerRef.nativeElement.focus();
              }
              if(this.getBrowserSessionData(baseSite.guest)){
                this.removeBrowserSessionData(baseSite.guest);
              }else if(this.getBrowserSessionData(baseSite.reg)){
                this.removeBrowserSessionData(baseSite.reg);
              }
              this.singletonServ.catalogVersion.csAgent=true; 
              this.singletonServ.catalogVersion['agentToken']=response["access_token"];   
              this.singletonServ.loggedIn=false;
              this.router.navigate(["store", "index"], {
                queryParams: { ASM: true },
                queryParamsHandling: "merge"
              });
            },
            (err:any) => {
              if(err.error.error=="invalid_grant"){
              that.    csErrMsg={
                status: true,
                description: 'Your username or password may be incorrect. Please try again. '
              };
            }
            });
        }
    }else{
      that.validateAllFormFields(that.authForm);
    }
  }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {        
    Object.keys(formGroup.controls).forEach(field => {  
      const control = formGroup.get(field);             
      if (control instanceof UntypedFormControl) {             
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {      
        this.validateAllFormFields(control);            
      }
    });
    }
  onAgentSignOut(status){
    const baseSite = this.singletonServ.catalogVersion;
    this.singletonServ.loggedIn=false;
    this.csAgentName=undefined;
    this.removeBrowserSessionData(baseSite.reg);
    this.removeBrowserSessionData(baseSite.csCustomer);
    this.startSession=false;
    this.csAgent=true;
    this.csAgentLogged=false;
    this.ASMCustomerform.reset();
    this.authForm.reset();
    if(this.csAgentRef){
      this.csAgentRef.nativeElement.focus();
    }
    if (this.getBrowserSessionData(baseSite.csCustomer)) {
      this.removeBrowserSessionData(baseSite.csCustomer);
    }
    if (isPlatformBrowser(this.platformId)) {
      this.removeBrowserSessionData("order");
      const _sessionNames=Object.keys(localStorage);
      if(_sessionNames.length){
          _sessionNames.map((obj)=>{
            if(obj !='prefered_lng'){
              this.removeBrowserSessionData(obj);

            }
          });
      }    
   }
    this.singletonServ.sendMessage({ retrieveAsASM: true });   
    if(status) {
     this.router.navigate(["store", "index"], {
      queryParams: { ASM: true },
      queryParamsHandling: "merge"
     });
   }else{
     this.router.navigate(['store','global','sessionExpired'], {
      queryParams: { ASM: true },
      queryParamsHandling: "merge"
     });
   }
  }
  onEndCustomerSession(event){
    const baseSite = this.singletonServ.catalogVersion;
    this.singletonServ.loggedIn=false;
    this.removeBrowserSessionData(baseSite.reg);
    if (this.getBrowserSessionData(baseSite.csCustomer)) {
      const user = JSON.parse(this.getBrowserSessionData(baseSite.csCustomer));
      delete user.email;
      delete user.code;
      this.setBrowserSessionData(baseSite.csCustomer, JSON.stringify(user));
    }
    this.startSession=false;
    this.customerDetail=undefined;
    this.searchResults=undefined;    
    this.ASMCustomerform.reset();
    if(this.customerRef){
      this.customerRef.nativeElement.focus();
    }
    this.singletonServ.sendMessage({ retrieveAsASM: true,endSession:true });
   
    this.router.navigate(["store", "index"], {
      queryParams: { ASM: true },
      queryParamsHandling: "merge"
    });
  }

onSearchUser(event) {
     const baseSite=this.singletonServ.catalogVersion;
     if(baseSite){
      const email = this.ASMCustomerform.controls['customer'].value;
      email.trim();
      this.errMsgObj['status'] = false;
       if (this.getBrowserSessionData(baseSite.csCustomer)) {
        const user = JSON.parse(this.getBrowserSessionData(baseSite.csCustomer));
        const _searchKeyWord =email.trim();
          if(_searchKeyWord.length >2){        
            this.csService
              .retrieveUsersDetails(baseSite, user.agentToken, email)
              .subscribe(
                response => {
                  if (response) {
                    this.searchResults = response['entries'];
                    this.searchBlock = true;
                  }
                },
                err => {
                  if(err.error){
                    if(err.error.error=='invalid_token'){
                      this.onAgentSignOut(false);
                     }
                  }
                }
              );
        }else{
          this.searchResults=undefined;
        }
    }
   }
  }
 




  onSelectCart(event, cart) {
    this.ASMCustomerform['controls']['customerOrderId'].patchValue(cart.code);
    this.searchOrderBlock = false;
    this.retrieveOrder = false;
  }






onBlurSearchUser(event){
  const _val: any = this.ASMCustomerform.value;
  if(_val['customer']){
    const customer =_val['customer'].trim();
    this.ASMCustomerform['controls']['customer'].patchValue(customer);
    if(customer.length == 0 || !_val['customer'] ){
        this.searchResults=undefined;
        this.ASMCustomerform['controls']['customer'].setValidators(null);
        this.ASMCustomerform['controls']['customer'].updateValueAndValidity();
        this.ASMCustomerform['controls']['customerOrderId'].setValidators([
                          Validators.required,
                          patternValidator(/^[0-9]{8,8}$/)
                        ]);
        this.ASMCustomerform['controls']['customerOrderId'].updateValueAndValidity();
    }

 }
}
onBlurOrderSearch(event){
  const _val: any = this.ASMCustomerform.value;
  if(_val['customerOrderId']){
    const customerOrderId =_val['customerOrderId'].trim();
    if(_val['customer']){
      const customer =_val['customer'].trim();
      this.ASMCustomerform['controls']['customer'].patchValue(customer);
    }
    this.ASMCustomerform['controls']['customerOrderId'].patchValue(customerOrderId);
 }
}
onSelectUser(event, user) {
  const  _displayUid=user.displayUid.split('|');
  const _email =  (_displayUid.length ==1)?user.displayUid:_displayUid[1];
  this.ASMCustomerform['controls']['customerID'].setValue(user.customerId);
  this.ASMCustomerform['controls']['customer'].setValue(_email);
  if(!this.ASMCustomerform['controls']['customerOrderId'].valid){
    this.ASMCustomerform['controls']['customerOrderId'].setValidators(null);
    this.ASMCustomerform['controls']['customerOrderId'].updateValueAndValidity();
  }
  this.customerDetail=user;
  this.searchBlock = false;
}

searchMBUser(){
  const baseSite=this.singletonServ.catalogVersion;
  if(baseSite){
  const _val: any = this.ASMCustomerform.value;
  const _customer=_val.customer;  
  if (this.getBrowserSessionData(baseSite.csCustomer)) {
    const user = JSON.parse(this.getBrowserSessionData(baseSite.csCustomer));
    const _searchKeyWord =_customer.trim();
  this.csService
  .retrieveUsersDetails(baseSite, user.agentToken, _searchKeyWord)
  .subscribe(
    (response:any) => {
      if (response) {
        const _entries:any = response['entries'];
        _entries.filter((obj)=>{
          if(_searchKeyWord === user.displayUid || user.firstName === _searchKeyWord || user.lastName === _searchKeyWord){
            return obj;
          }
        });
        if(_entries.length !=0){
          this.customerDetail=_entries[0];
          this.checkoutAsCSCustomer();
        }else{
          this.errMsgObj = {
            status:true,
            description: 'Please fill the valid details .'
          };
        }
      }
    },
    err => {
      if(err.error){
        if(err.error.error=='invalid_token'){
          this.onAgentSignOut(false);
         }
      }
    }
  );
  }
}
}
onSearchOrderKeyDown(event){
  this.errMsgObj['status'] = false;
  if(event.keycode==13){
    this.onSubmitCSCustomerForm(event);
  }
}
onSubmitCSCustomerForm(event) {
  event.preventDefault();
  const that=this;
  const baseSite=this.singletonServ.catalogVersion;
  const _val: any = this.ASMCustomerform.value;
  const _searchKeyWord=_val.customer;
  if(_searchKeyWord ){
   _searchKeyWord.trim();
  }
  const _customerOrderId=_val.customerOrderId; 
  if(_customerOrderId ){
  _customerOrderId.trim();
}
  if(_searchKeyWord && _customerOrderId){
    if(_searchKeyWord !='' && _customerOrderId !="" ){
        const emailIsValid = this.pattern.test(_searchKeyWord);
        if(emailIsValid) {
          this.searchEndUser(_searchKeyWord,_customerOrderId);  
        }else{
          this.errMsgObj = {
            status:true,
            description: 'Please provide valid customer information'
          }
        }
      } else if (_searchKeyWord !='' ){
          const emailIsValid = this.pattern.test(_searchKeyWord);
          if(emailIsValid) {
            this.searchEndUser(_searchKeyWord,null);    
          }else{
            this.errMsgObj = {
                status:true,
                description: 'Please provide valid customer information'
            };
          }
    } else if (_customerOrderId !=""){
      this.onSearchOrder();
    } 
  } else {
   if(_searchKeyWord){
   if (_searchKeyWord !='' ){    
    const emailIsValid = this.pattern.test(_searchKeyWord);
    if(emailIsValid) {
      this.searchEndUser(_searchKeyWord,null);    
    }else{
      this.errMsgObj = {
          status:true,
          description: 'Please provide valid customer information'
       };
    }
  } else{
    this.errMsgObj = {
      status:true,
      description: 'Please provide valid customer information'
   };
  }
} else if(_customerOrderId){
  if (_customerOrderId !='' ){
     this.onSearchOrder();
  }else{
    this.errMsgObj = {
      status:true,
      description: 'Please provide valid customer information'
   };
  }
} else{
        this.errMsgObj = {
          status:true,
          description: 'Please provide valid customer or order information to proceed further'
    };
  }
}
}
searchEndUser(_searchKeyWord,order){
  const that=this;
  const baseSite=this.singletonServ.catalogVersion;
  if (this.getBrowserSessionData(baseSite.csCustomer)) {
    const _agent = JSON.parse(this.getBrowserSessionData(baseSite.csCustomer));    
        this.csService.retrieveUsersDetails(baseSite, _agent.agentToken, _searchKeyWord).subscribe((response:any) => {
              if (response) {
                if(response.entries){
                  if( response.entries.length !=0){
                    const _findUSer=response.entries.find((user)=>{
                      const _uid=user.uid.split('|');
                      const _email=_uid[_uid.length-1];
                      if(_searchKeyWord === _email || user.firstName === _searchKeyWord || user.lastName === _searchKeyWord){
                        return user;
                      }
                    });
                    if(_findUSer){
                      this.errMsgObj = {
                        status:false,
                        description: ''
                     };
                      this.customerDetail=_findUSer;
                      this.startSession=true;
                      if(order){
                        this.onSearchOrder();
                      }else{                        
                      this.checkoutAsCSCustomer();
                      }
                    }else{
                      this.errMsgObj = {
                        status:true,
                        description: 'Please provide valid customer information'
                     };
                    }

                  }else{
                    that.errMsgObj = {
                      status:true,
                      description: 'Please provide valid customer information'
                   };
                  }
                }
              }
            },(err:any) => {
              if(err.error){
               if(err.error.error=='invalid_token'){
                 this.onAgentSignOut(false);
                }
             }
            }
          );
}
}
onSearchOrder() {
  const baseSite=this.singletonServ.catalogVersion;
  if(baseSite){
      const order = this.ASMCustomerform.controls['customerOrderId'].value;
      if(order){
      order.trim();
      if ( order !== '' ) {
        if (this.getBrowserSessionData(baseSite.csCustomer)) {
          const _user = JSON.parse(this.getBrowserSessionData(baseSite.csCustomer));
          this.csService.retrieveOrderDetails(baseSite, _user.agentToken, order).subscribe(response => {
            this.errMsgObj = {
              status:false,
              description: ''
            };
                if (response) {
                  const _val: any = this.ASMCustomerform.value;
                  const customer =_val['customer'];
                  if(response['entries']){
                    const _dtl =response['entries'][0];
                    if(customer){
                  if(customer !=''){
                      customer.trim();
                      if (Object.values(_dtl).indexOf(customer) > -1) {
                          this.singletonServ.catalogVersion.csAgent=true; 
                          this.singletonServ.catalogVersion.agentToken=_user.agentToken;
                           this. orderQueryResults(_dtl);
                      }else{
                        this.errMsgObj = {
                          status:true,
                          description: "Given  Order Id doesn't belong to the customer"
                        };
                      }
                  }else{
                    this.singletonServ.catalogVersion.csAgent=true; 
                    this.singletonServ.catalogVersion.agentToken=_user.agentToken;
                    this. orderQueryResults(_dtl);
                    this.ASMCustomerform['controls']['customer'].patchValue(_dtl.displayUid);
                  }
                  }else{
                    this.singletonServ.catalogVersion.csAgent=true; 
                    this.singletonServ.catalogVersion.agentToken=_user.agentToken;
                    this. orderQueryResults(_dtl);
                    this.ASMCustomerform['controls']['customer'].patchValue(_dtl.displayUid);
                  }
                }
              }
              },
              err => {
                if(err.error){
                  if(err.error.error=='invalid_token'){
                    this.onAgentSignOut(false);
                   }else{
                    this.errMsgObj = {
                      status:true,
                      description: 'Please provide valid order id'
                    };
                   }
                }
          
              }
            );
      }
    } 
  }
}
}
checkoutAsCSCustomer(){ 
  const user = this.customerDetail;
  const baseSite = this.singletonServ.catalogVersion;
  if(baseSite){  
    const  _displayUid=user.displayUid.split('|');
    const _email =  (_displayUid.length ==1)?user.displayUid:_displayUid[1];
    this.singletonServ.catalogVersion.csAgent=true; 
    this.enduserRelevantCart(_email,null);
  }
}
orderQueryResults(_dtl){
  const baseSite=this.singletonServ.catalogVersion;
  if(baseSite) {
  this.customerDetail = _dtl;
  const order = this.ASMCustomerform.controls['customerOrderId'].value;
  const  _displayUid=  _dtl.uid.split('|');
  const _email =  (_displayUid.length ==1)?_dtl.uid:_displayUid[1];
  this.startSession = true;
  this.enduserRelevantCart(_email,order);

}
}
constructASMView(){
  const baseSite = this.singletonServ.catalogVersion;
  if(baseSite){
  const queryStatus = window.location.search.replace('?', '');
  if (queryStatus.indexOf('ASM') != -1 ) {
    if (isPlatformBrowser(this.platformId)) {
      
          if (this.getBrowserSessionData(baseSite.csCustomer)) {
              const _csCustomer = JSON.parse(this.getBrowserSessionData(baseSite.csCustomer));
              this.csAgent = _csCustomer.csAgent;
              this.csAgentLogged=true;
              this.singletonServ.catalogVersion.csAgent=true; 
              this.singletonServ.catalogVersion.agentToken=_csCustomer.agentToken;   
              this.csAgentName=_csCustomer.csAgentName;
              if (_csCustomer.email) {
                this.startSession = true;
                this.ASMCustomerform.controls['customer'].setValue(_csCustomer.email);
                this.ASMCustomerform.controls['customerOrderId'].setValue(_csCustomer.code);
              }
            } else {
              this.csAgent = true;
            }
          this.asmCustomer = true;
          if(this.getBrowserSessionData(baseSite.csAssistant)){
             const csAssistant = JSON.parse(this.getBrowserSessionData(baseSite.csAssistant));
             if(csAssistant.csAgentName){
               this.csAgentName=csAssistant.csAgentName;
             }
            this.setBrowserSessionData(baseSite.csAssistant,JSON.stringify(csAssistant));
          }else{
            this.setBrowserSessionData(baseSite.csAssistant,JSON.stringify({csAgent:true}));
          }
      }   
  } else if(this.getBrowserSessionData(baseSite.csAssistant)){   
          if(isPlatformBrowser(this.platformId)){
              if (this.getBrowserSessionData(baseSite.csCustomer)) {
                const _csCustomer = JSON.parse(this.getBrowserSessionData(baseSite.csCustomer));
                this.csAgent = _csCustomer.csAgent;
                this.csAgentLogged=true;
                this.singletonServ.catalogVersion.csAgent=true; 
                this.singletonServ.catalogVersion.agentToken=_csCustomer.agentToken;  
                this.csAgentName=_csCustomer.csAgentName; 
                if (_csCustomer.email) {
                  this.startSession = true;
                  this.ASMCustomerform.controls['customer'].setValue(_csCustomer.email);
                  this.ASMCustomerform.controls['customerOrderId'].setValue(_csCustomer.code);
                }
              } else {
                this.csAgent = true;
              }
          }
          this.asmCustomer = true;
  }else {
    this.asmCustomer = false;
  }
}
}

enduserRelevantCart(_email,order){
  const baseSite = this.singletonServ.catalogVersion;
  const _user = { email: "" };
  _user["email"] =  _email;
  const _obj = JSON.parse(this.getBrowserSessionData(baseSite.csCustomer));
  _obj['email']  =  _email;
  this.singletonServ.catalogVersion.agentToken=_obj.agentToken; 
  this.csService.generateToken(baseSite).subscribe(token => {
      _user['token']=   token["access_token"],
  this.csService.getCurrentUserRelevantCart(baseSite,token["access_token"],_email).subscribe((resp:any) => {
        if (resp) {
            const cartObj=this.singletonServ.setupEntryStream(resp);
            this.singletonServ.cartObj=cartObj;
            _user["code"] = resp.code;
            this.setBrowserSessionData(baseSite.reg, JSON.stringify(_user));
            this.setBrowserSessionData(baseSite.csCustomer, JSON.stringify(_obj));
            this.startSession=true;
            this.singletonServ.sendMessage({ retrieveAsASM: true,cartData:cartObj });
            this.userRelevantView(order);
        }
      },
      error => {
        this.startSession=true; 
        this.setBrowserSessionData(baseSite.reg, JSON.stringify(_user));
        this.setBrowserSessionData(baseSite.csCustomer, JSON.stringify(_obj));
        this.singletonServ.sendMessage({ retrieveAsASM: true });
        this.userRelevantView(order);
      });

    });
}


userRelevantView(order){
  const baseSite = this.singletonServ.catalogVersion;
  if(order){
   const _user = JSON.parse(this.getBrowserSessionData(baseSite.csCustomer));
  _user['code'] = order;
  this.setBrowserSessionData(baseSite.csCustomer, JSON.stringify(_user));

    this.router.navigate(["store", "myaccount", "profile", "orderDetails"], {
      queryParams: { orderId: order,ASM:true},
      queryParamsHandling: "merge"
    });

  }else{
    this.router.navigate(["store", "myaccount", "profile"], {
      queryParams: { ASM: true },
      queryParamsHandling: "merge"
    });
  }
}
getBrowserSessionData(key){
  if (isPlatformBrowser(this.platformId)) {
    return this.singletonServ.getStoreData(key);
  }
}
setBrowserSessionData(key,data){
  if (isPlatformBrowser(this.platformId)) {
     this.singletonServ.setStoreData(key,data);
  }
}
removeBrowserSessionData(key){
  if (isPlatformBrowser(this.platformId)) {
    this.singletonServ.removeItem(key);
  }
}
}
