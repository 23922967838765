import { Injectable,Inject,PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser,Location } from '@angular/common';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SingletonService } from "./singleton.service";
import { environment }     from '../../environments/environment';
import * as _ from 'lodash';
declare var window:any;
@Injectable()
export class RouteRedirectService  {
  countryData:Array<any>=environment.countryJsonData;
  constructor(
    public singletonServ:SingletonService, 
    private router: Router, 
    public location: Location,
    @Inject(PLATFORM_ID) public platformId: Object
    ) {}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const activate:boolean = this.redirect(route.data);
     return activate;  
  }
  redirect(routes:any): boolean {
    const baseSite = this.singletonServ.catalogVersion;
    if (isPlatformBrowser(this.platformId)) {
	const _sitehostname = window.location.hostname;
    const _siteindex = _.findIndex(this.countryData,(o)=>{ 
            return (o.hostname == _sitehostname||o.serverName == _sitehostname);
      });
      if(_siteindex !=-1){
        const _country= this.countryData[_siteindex];
        const  _routeIndex=routes.siteID.findIndex((obj)=>{
          return obj.isocode ==_country.isoCode;
        }); 
        if( _routeIndex !=-1){
           return true;
        } else  if (this.getBrowserSessionData("prefered_lng")){
          const _data = JSON.parse(this.getBrowserSessionData("prefered_lng"));
          const  _findSiteId = routes.siteID.findIndex((obj)=>{
             return obj.isocode ==_data.isoCode;
           }); 
           if( _findSiteId !=-1 ){
              return true;
           } 
        } else if(this.singletonServ.catalogVersion){

          const  _baseSiteIndex=routes.siteID.findIndex((obj)=>{
             return obj.isocode ==baseSite.isoCode;
           }); 
		if(_baseSiteIndex !=-1){
              return true;
           }
    
        }
      } else if(this.singletonServ.catalogVersion){
        const  _baseSiteIndex=routes.siteID.findIndex((obj)=>{
           return obj.isocode ==baseSite.isoCode;
         }); 
	if(_baseSiteIndex !=-1){
            return true;
         }
  
      } else  if (this.getBrowserSessionData("prefered_lng")){
        const _data = JSON.parse(this.getBrowserSessionData("prefered_lng"));
        console.log('check browser secondary  data', _data);
        const  _findSiteId = routes.siteID.findIndex((obj)=>{
           return obj.isocode ==_data.isoCode;
         }); 
         if( _findSiteId !=-1 ){
            return true;
         } 
      }  else { 
        const _hostname = this.location['_locationStrategy']._platformLocation._location.hostname;
        const _index = _.findIndex(this.countryData,(o)=>{ 
              return (o.hostname == _hostname||o.serverName == _hostname);
        });
	if(_index !=-1){
          const _country= this.countryData[_index];
          const  _routeIndex=routes.siteID.findIndex((obj)=>{
            return obj.isocode ==_country.isoCode;
          }); 
          if( _routeIndex !=-1){
             return true;
          } 
        }
      }
    }
    this.router.navigate(["store","404"]);
    return false;
  }
  getBrowserSessionData(key){
    if (isPlatformBrowser(this.platformId)) {
      return this.singletonServ.getStoreData(key);
    }
  }
  setBrowserSessionData(key,data){
    if (isPlatformBrowser(this.platformId)) {
       this.singletonServ.setStoreData(key,data);
    }
  }
  removeBrowserSessionData(key){
    if (isPlatformBrowser(this.platformId)) {
      this.singletonServ.removeItem(key);
    }
  }
}