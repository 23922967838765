import {
  Inject,
  Component,
  OnInit,
  AfterViewInit,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  HostListener,
  OnDestroy,
  Injectable, NgZone,PLATFORM_ID, RendererFactory2
} from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { HeaderComponentService } from "./header.service";
import { SingletonService } from "../../services/singleton.service";
import { BasketPageComponentService } from "../../checkoutpage/basketpage/basketpage.service";
import { Location } from "@angular/common";
import { Router,ActivatedRoute} from "@angular/router";
import * as _ from "lodash";
import { environment }     from '../../../environments/environment';
import {CartComponent} from './cart/cart.component';
import { productviewComponentService } from "../productview/productview.service";
import { Subscription, Subject } from "rxjs";
import { filter, map, take, toArray, takeUntil } from 'rxjs/operators';
import { GtmMethodService } from '../../services/gtmmethods.service';
import { AmplienceService } from 'src/app/services/amplience.service';
import { DeliveryKey } from '../../amplience.constant';
import { WebExtendService } from "src/app/services/webExtend.service";

declare var $: any;
declare var AmpCa: any;
declare var window:any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, AfterViewInit,OnDestroy {
  @ViewChild("scrollContainer") scrollCartContainer: ElementRef;
  @ViewChild("storeCartCount") cartCountElement:ElementRef;
  @ViewChild("cartRefElement",{ static: true }) cartCo:CartComponent;
  @ViewChild('submenuContainer') menuCartCo:ElementRef;
  @ViewChild('promotionalText') promotionalText:ElementRef;
  countryText: any;
  toggle: boolean;
  subscription: Subscription;
  cartSubscription:Subscription;
  createCartSubscription:Subscription;
  entryCartSubscription:Subscription;
  generateCartSubscription:Subscription;
  deliveryText: string;
  loggedIn: boolean;
  countries = environment.countryJsonData;
  currentCountryCode:string;
  sidemenuStatus:boolean;
  renderCart:boolean=true;
  message:any;
  updateBasket:boolean;
  hideCart:boolean;
  localData:any;
  greyBannerAmpData: any;
  private unsubscribe$=new Subject<void>();
  enableJP:boolean;
  param1:any;
  frSiteCode:string;
  isEmarsysEnabled: boolean;
  constructor(
    @Inject(DOCUMENT) public dom,
    @Inject(PLATFORM_ID) public platformId: Object,
    public headerServ: HeaderComponentService,
    public singletonServ: SingletonService,
    public location: Location,
    public router: Router,
    public route: ActivatedRoute,
    public basketServ: BasketPageComponentService,
    public quickServ: productviewComponentService,
    public gtmServe:GtmMethodService,
    public ampService: AmplienceService,  
    public webExtend:WebExtendService
    
    
  ) {
    this.sidemenuStatus = false;
    this.deliveryText = "Free UK Standard Delivery when you spend £49.*";
    this.loggedIn = false;
    this.route.queryParams.subscribe(params => {
      this.param1 = params['api'];    
  });
  }

  onSignOut() {  
    const that =this;
    this.loggedIn=false;
    this.singletonServ.loggedIn=false;
    if (isPlatformBrowser(this.platformId)) {
        this.removeBrowserSessionData("order");
        const _sessionNames=Object.keys(localStorage);
        if(_sessionNames.length){
            _sessionNames.map((obj)=>{
              if(obj !='prefered_lng'){
                that.removeBrowserSessionData(obj);
              }
            });
        }    
     }
     this.cartCo.signoutCredentials(); 
     const emailEmpty = "empty";
     if(this.isEmarsysEnabled) {
      this.webExtend.webExtendUnSetemail(emailEmpty);
     }
  }

  ngOnInit() {
    const baseSite =this.singletonServ.catalogVersion;    
    this.getAmplienceCnt(baseSite);    
    this.singletonServ.getCatalogData().subscribe(resp => {
      this.isEmarsysEnabled = (resp["enableEmarsysFlow"]);
    },
      error => {}
    );    
    if(baseSite){
      this.frSiteCode=(baseSite.lngCode=='en')?'uk':baseSite.lngCode;
      const _isoCode=this.singletonServ.catalogVersion.isoCode;
      this.getTopStaticCntnt(baseSite.lngCode);
      this.countries.map((obj)=>{
        if(_isoCode==obj.isoCode){
          obj['current']=true;
          this.currentCountryCode=obj.countryName;
        }else{
          obj['current']=false;
        }
      });
      if (this.getBrowserSessionData(baseSite.reg)) {
        this.loggedIn = true;
        this.singletonServ.loggedIn = true;
      }  
      if(this.location){
        if(this.location['_locationStrategy']?._platformLocation._location){
          const _pathName=this.location['_locationStrategy']._platformLocation._location.pathname;
          const queryParam = this.location['_locationStrategy']._platformLocation._location.search;
          const _path =_pathName.slice(1).split("/");
          const page= _path[_path.length-1];
          if(page != "mbBasket" || page.search('mbBasket') == -1 ){
              if( (page != 'mbOrderConfirmResponse' || page.search('mbOrderConfirmResponse') == -1) && (!queryParam.includes('redirectResult')) ){
                this.cartCo.retrieveCartDetails(); 
              }
        }
      }
     }
    }

  }

  async getAmplienceCnt(baseSite){    
        // Retrieve Amplience Grey Banner Data  
      await this.ampService.getContentByKey(DeliveryKey.banner[baseSite.isoCode]).subscribe(resp=>{
        this.greyBannerAmpData = resp['content']; 
    });
  }
  getTopStaticCntnt(lang: string){
    this.headerServ.getPolicyContent(lang).subscribe((response:any)=>{
      this.singletonServ.ampContentId=response;
      this.getTopHeadCntnt(response.headerPromotion);
    });
  }
    /*rendering Amplience Content Using Ampca variable IIFE Function located in assets/js*/
    getTopHeadCntnt(cntnt) {
      const that=this;
      let api;
      if(this.param1){
        api="https://"+this.param1;
      }else{
        api="https://c1.amplience.net";
      }
      //AmpCa.utils = new AmpCa.Utils();
      const baseSite=this.singletonServ.catalogVersion;
      /* AmpCa.utils.getHtmlServiceData({
        auth: {
          baseUrl: api,
          id: cntnt.content,
          store: "moltonbrown",
          templateName: "slot-contentWrapper",
          locale:baseSite.locale
        },
        callback: function(data) {
            that.promotionalText.nativeElement.innerHTML= data;
       }
      }); */
    }

  ngAfterViewInit() {
    const that=this;
    this.subscription = this.singletonServ.getMessage().pipe(takeUntil(this.unsubscribe$)).subscribe(message => {
          if (message.access_token) {
              this.loggedIn = true;
              this.singletonServ.loggedIn = true;
            }else if (message.updateCart) {
            this.message=message;
            this.updateBasket=true;
            this.updateMiniCart(message);
            }else if( message.showCartPopUp) {
              this.cartCo.cartMessage=message;
              this.message=message;
              this.updateBasket=true;
              this.cartCo.getCartDetail(message.unstreamCartData);
           } else if(message.sampleAdded){
              this.updateMiniCart(message);
           }else if(message.retrieveAsASM){
            if(message.cartData){
              this.cartCo.cart=message.cartData;
              }else{
               this.cartCo.retrieveCartDetails(); 
              }  
           } else if(message.updateBasketCount){
             this.cartCo.cart=message.cart;
           }else if(message.refreshCart){
            this.cartCo.getCartDetail(message.unstreamCartData);
           } else if(message.clearCart){
             this.cartCo.clearCartCount();             
             that.router.navigate(['store','global','sessionExpired']);
           }
           this.stickyHeaderOnScrollUp();  
    });
  }

  stickyHeaderOnScrollUp() {
    // For Sticky header on scroll top
    const body = document.body;
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    let lastScroll = 0;
    // setTimeout(() => {
    //   var headerHt = $('#header-submenuwrap').outerHeight();
    //   $('.storefront').css({'padding-top': headerHt});
    // },1000);
    window.addEventListener("scroll", () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 0) {
        body.classList.remove(scrollUp);
        body.classList.remove(scrollDown);
        var headerHt = $('#header-submenuwrap').outerHeight();
        // $('.storefront').css({'padding-top': headerHt});
        return;
      }
      if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
        // down
        body.classList.remove(scrollUp);
        body.classList.add(scrollDown);
        $('#header-submenuwrap').css({'top': 0});
        $('.storefront').css({'padding-top': 0});
      } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
        // up
        body.classList.remove(scrollDown);
        body.classList.add(scrollUp);
        $('#header-submenuwrap').css({'top': 0});
        $('.storefront').css({'padding-top': 0});
      }
      lastScroll = currentScroll;
    });
  }

 updateMiniCart(message){
  if(message.cartData){
    this.cartCo.cart=message.cartData;
    if(this.cartCo.cart && this.cartCo.cart.entries)this.cartCo.setProductQuantity(this.cartCo.cart.entries)
    }else{
     this.cartCo.retrieveCartDetails(); 
    }  
 }
 onNewsletterClick(event) {
  event.preventDefault();
  event.stopPropagation();
  if((event.ctrlKey && event.which === 1) || event.metaKey){
    window.open('/store/newsletter-sign-up');
    }else{
        this.router.navigate(["store", "newsletter-sign-up"]);
    }
}


  onProfileClick() {
    const baseSite = this.singletonServ.catalogVersion;
    if (this.getBrowserSessionData(baseSite.reg)) {
      this.router.navigate(["store", "myaccount"]);
    } else {
      this.singletonServ.loggedIn=false;
      this.loggedIn=false;
      this.router.navigate(["store", "myacc","mbLogin"]);
    }
  }
  onCountryChange(data) {
    if(data.isoCode != 'JP'){
      this.countryText = data;
      this.enableJP=false;
    } else {
      this.countryText = data;
      this.enableJP=true;
    }
  }

  onCancelModal(bol) {  
   if(!this.enableJP){
       const baseSite = this.singletonServ.catalogVersion;
        let user;
        if(this.getBrowserSessionData(baseSite.reg)){
          user= JSON.parse(this.getBrowserSessionData(baseSite.reg));
        }
        if (bol) {

          this.setBrowserSessionData("prefered_lng",JSON.stringify(this.countryText));    
          const _isoCode=this.countryText.isoCode;
          this.countries.map((obj)=>{
            if(_isoCode==obj.isoCode){
              obj['current']=true;
            }else{
              obj['current']=false;
            }
          });  
          const _obj = {
            baseSiteChange: true,
          };
          if(this.getBrowserSessionData(baseSite.reg)){
            if(!this.getBrowserSessionData(this.countryText.reg)){       
              const currentUser={email:''};
              currentUser.email=user.email;
              this.setBrowserSessionData(
                this.countryText.reg,
                JSON.stringify(currentUser)
              );
              if(user.token){
                this.headerServ.getUserData(baseSite,user.token,user.email).subscribe((response)=>{
                  this.singletonServ.loggedIn=true;
                  const userDtls= JSON.parse(this.getBrowserSessionData(this.countryText.reg));
                  userDtls['isExpressCheckout']=(response['isExpressCheckout'])?true:false;
                  this.setBrowserSessionData(
                    this.countryText.reg,
                    JSON.stringify(currentUser)
                  );
                },err=>{

                })
              }
              this.singletonServ.catalogVersion =  JSON.parse(this.getBrowserSessionData("prefered_lng"));
              this.singletonServ.sendMessage(_obj);      
              this.goToStore();
            }else{
              this.singletonServ.catalogVersion =  JSON.parse(this.getBrowserSessionData("prefered_lng"));
              this.singletonServ.sendMessage(_obj);      
              this.goToStore();
        }
        }else{
          this.singletonServ.catalogVersion =  JSON.parse(this.getBrowserSessionData("prefered_lng"));
          this.singletonServ.sendMessage(_obj);      
          this.goToStore();
        }
    
        }
  } else {
    window.location.href=this.countryText.query;
  }
}
  onleaveRichCart(event){
    this.cartCo.showCartBlock=false;
    this.cartCo.onleaveCart(event);
  }
  onHoverProfileIcon(event) {
    const baseSite = this.singletonServ.catalogVersion;
    if(baseSite){
    if (this.getBrowserSessionData(baseSite.reg)) {
      this.singletonServ.loggedIn=true;
      this.loggedIn = true;
    } else {
      this.singletonServ.loggedIn=false;
      this.loggedIn = false;
    }
    this.cartCo.onleaveCart(event);
   }
  }
  onFindStoreClick(event){
    event.preventDefault();
    event.stopPropagation();
    if((event.ctrlKey && event.which === 1) || event.metaKey){
      window.open('/store/company/stores');
    } 
    else{
        this.router.navigate(['store','company','stores']);
     }
  }
  toggleMenu(event) {
    event.preventDefault();
    this.onSidemenutap();
 }
 swipMenu(event) {
  this.onSidemenutap();
 }
 onResetSidemenuTap(){
  this.   singletonServ.cartObj=undefined;
  this.singletonServ.scrollToElWithinC('.sidenav',"#sidenavInput");
  const baseSite = this.singletonServ.catalogVersion;
  if(this.getBrowserSessionData(baseSite.reg)){
    this.singletonServ.loggedIn=true;
  }else{
    this.singletonServ.loggedIn=false;
  }
  $('#mainBar').toggleClass("open-sidebar");
  $('#sidebar').toggleClass('change-sidebar');
  this.sidemenuStatus = !this.sidemenuStatus;
  this.toggle=this.sidemenuStatus;
  const toggle = {
    moltonSideumenu: {
      state: this.sidemenuStatus
    }
  };    
  this.singletonServ.sendMessage(toggle);
  if(this.sidemenuStatus){
    this.dom.querySelector('body').classList.add("overlay-scroll-hidden");
  }else{
    this.dom.querySelector('body').classList.remove("overlay-scroll-hidden");
  }
  this.singletonServ.cartObj=undefined;
  if( this.cartCo){
    this.cartCo.signoutCredentials(); 
  } 
  this.router.navigate(["store", "myacc","mbLogin"]);
 }
  onSidemenutap() {
    this.singletonServ.scrollToElWithinC('.sidenav',"#sidenavInput");
    const baseSite = this.singletonServ.catalogVersion;
    if(baseSite){
    if(this.getBrowserSessionData(baseSite.reg)){
      this.singletonServ.loggedIn=true;
    }else{
      this.singletonServ.loggedIn=false;
    }
  
    $('#mainBar').toggleClass("open-sidebar");
    $('#sidebar').toggleClass('change-sidebar');
    this.sidemenuStatus = !this.sidemenuStatus;
    this.toggle=this.sidemenuStatus;
    const toggle = {
      moltonSideumenu: {
        state: this.sidemenuStatus
      }
    };    
    this.singletonServ.sendMessage(toggle);
    if(this.sidemenuStatus){
      this.dom.querySelector('body').classList.add("overlay-scroll-hidden");
    }else{
      this.dom.querySelector('body').classList.remove("overlay-scroll-hidden");
    }
   }
  }
  removejscssfile(filename, filetype){
    var targetelement=(filetype=="js")? "script" : (filetype=="css")? "link" : "none" ;
    var targetattr=(filetype=="js")? "src" : (filetype=="css")? "href" : "none";
    var allsuspects=this.dom.getElementsByTagName(targetelement);
    for (var i=allsuspects.length; i>=0; i--){ 
    if (allsuspects[i] && allsuspects[i].getAttribute(targetattr)!=null && allsuspects[i].getAttribute(targetattr).indexOf(filename)!=-1)
        allsuspects[i].parentNode.removeChild(allsuspects[i]) ;
    }
  }
  goToStore(){
      const baseSite = this.singletonServ.catalogVersion;
      if(baseSite){
      this.singletonServ.alarmTime=undefined;
      this.singletonServ.sessionStarts=false;
      this.removejscssfile(baseSite.bv,'js');
      if(this.getBrowserSessionData(baseSite.csCustomer)){
          if(baseSite.isoCode =="GB"){
            this.singletonServ.alarmTime=undefined;
            this.singletonServ.sessionStarts=false;
            const ukurl=baseSite.query+'?ASM=true';
            window.location.href=ukurl;
          }else{
            this.singletonServ.alarmTime=undefined;
            this.singletonServ.sessionStarts=false;
            const _biturl=baseSite.query+'&ASM=true';
            window.location.href=_biturl; 
          }
      }else{
        this.singletonServ.alarmTime=undefined;
        this.singletonServ.sessionStarts=false;
        window.location.href=baseSite.query;
      }
    }
  }
 

/* End Add to basket */
discardSubscription(event){
  event.preventDefault();
  if(this.subscription){
  this.subscription.unsubscribe();
}
}
ngOnDestroy(){
    if( this.subscription){
      this.subscription.unsubscribe();
    }
    if(this.createCartSubscription){
      this.createCartSubscription.unsubscribe();
    }
    if(this.entryCartSubscription){
      this.entryCartSubscription.unsubscribe();
    }
    if(this.generateCartSubscription){
      this.generateCartSubscription.unsubscribe();
    }
}
getBrowserSessionData(key){
  if (isPlatformBrowser(this.platformId)) {
    return this.singletonServ.getStoreData(key);
  }
}
setBrowserSessionData(key,data){
  if (isPlatformBrowser(this.platformId)) {
     this.singletonServ.setStoreData(key,data);
  }
}
removeBrowserSessionData(key){
  if (isPlatformBrowser(this.platformId)) {
    this.singletonServ.removeItem(key);
  }
}
onFreeDeliveryClick(event) {
  event.preventDefault();
  event.stopPropagation();
  let bannerUrl = this.greyBannerAmpData.bannerinfo.callToActionUrl ? this.greyBannerAmpData.bannerinfo.callToActionUrl : "/store/delivery";
  if((event.ctrlKey && event.which === 1) || event.metaKey){
    window.open(bannerUrl);
    }else{
        this.router.navigateByUrl(bannerUrl);
    }
}
addSkipLinkHandler(event) {
  event.preventDefault();
  event.stopPropagation();
  const target = this.dom.querySelector('#main-content');
  target.scrollIntoView({ behavior: 'smooth', block: 'start' });
  target.setAttribute('tabindex', '-1'); // Make the target focusable
  target.focus({ preventScroll: true });
}

}
