import { Component, OnInit, AfterViewInit  } from '@angular/core';
import {SingletonService} from '../../services/singleton.service';
import { DeviceDetectorService } from "ngx-device-detector";
import { Location } from "@angular/common";
import { SEOService } from "../../services/seo.service";
import { GtmMethodService } from '../../services/gtmmethods.service';

@Component({
  selector: 'app-princes-trust',
  templateUrl: './princes-trust.component.html',
  styleUrls: ['./princes-trust.component.scss']
})
export class PrincesTrustComponent implements OnInit, AfterViewInit {
  path: string;
  countryCode: string;
  constructor (
    public deviceService: DeviceDetectorService,
    public location: Location,
    public singletonServ:SingletonService,
    public gtmServe:GtmMethodService,
    private seoService: SEOService,
    
    
  ){
  }
  ngOnInit() {
    const baseSite = this.singletonServ.catalogVersion;
    this.countryCode = baseSite.isoCode;
    this.path=this.location.path();
    this.seoService.createCanonicalURL(this.path);
  }
  ngAfterViewInit(){
    const _baseSite = this.singletonServ;    
    const pageType = 'Prince Trust Partnership';
    this.gtmServe.gtmPageCategorisation(_baseSite,pageType);
  }

}
