import { Injectable } from "@angular/core";
import { Observable, Subject , of,bindCallback} from 'rxjs';
declare const google: any;
@Injectable()
export class PlacePredictionService {
  public autocompleteService;
  observable

  getPlacePredictions(term: string): Observable<any[]> {
    return Observable.create(observer => {

    this.autocompleteService.getPlacePredictions({ input: term }, data => {
      let previousData: Array<any[]>;

      if (data) {
        previousData = data;
        observer.next(data);
        observer.complete();
      }


      if (!data) {
        observer.next(previousData);
        observer.complete();

      } else {
        observer.error(status);
      }

    });

    });

    }
  }