import {Injectable} from '@angular/core';
import {UntypedFormControl, Validators } from '@angular/forms';
import { patternValidator } from './pattern-validator';
import * as _ from 'lodash';
import { countries } from "../app.constant";
import { compareValidator } from './custom-validator.directive';
import { SingletonService } from "../services/singleton.service";
@Injectable({
  providedIn: 'root'
})
export class RegistrationForm {
  numberPattern:'^(0|[1-9][0-9]*)$';
  countryList:Array<any>=countries;
  activeCountry:Array<any>;
  counts:boolean;
  constructor( 
   public singletonServ: SingletonService
       ){

  }
  storesForm(){
    return{
       storeName:new UntypedFormControl('',{validators:Validators.required,updateOn: 'blur'})
    }
   
  }
  
 getForm() {
  return {
      uid: new UntypedFormControl(''), 
      // confirmemail: new FormControl('',{validators:[Validators.required,
      //   compareValidator('uid')],updateOn: 'blur'}),
      password: new UntypedFormControl('',{validators:[Validators.required, patternValidator(/^.{6,20}$/)],updateOn: 'blur'}),
      // confirmPassword: new FormControl('',{validators:[ Validators.required,compareValidator('password')],updateOn: 'blur'}),
      titleCode: new UntypedFormControl(null,{validators:Validators.required,updateOn: 'blur'}),
      firstName:new UntypedFormControl('',{
                                     validators:[
                                        Validators.required,
                                        patternValidator(/^([a-zA-Z0-9?]{1,}\s?([a-zA-Z0-9!@&()+-.,space/?:;' ]{1,68})?)$/)
                                      ],
                                      updateOn: 'blur'
                                    }),
      lastName: new UntypedFormControl('',{
                          validators:[
                            Validators.required,
                            patternValidator(/^([a-zA-Z0-9?]{1,}\s?([a-zA-Z0-9!@&()+-.,space/?:;' ]{1,68})?)$/)
                          ],updateOn: 'blur'}),       
      // country:new FormControl(null,{validators:Validators.required,updateOn: 'blur'}),
      // postalCode:new FormControl ('',{
      //   validators:[
      //     Validators.required,
      //     patternValidator(/^(([a-zA-Z0-9!@#$&()+-.,space/?:;' ]{1,68})?)$/)
      //   ]
      // }),
      // address:[''],
      // line1:new FormControl('',{validators:Validators.required,updateOn: 'blur'}),      
      // line2:[''],
      // town:new FormControl('',{validators:Validators.required,updateOn: 'blur'}),  
      // phone:new FormControl ('',{
      //                            validators:[
      //                              Validators.required,  
      //                              patternValidator(/^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/)
      //                             ],
      //                             updateOn: 'blur'
      //                           }),
      phone:[''],
      // district:[''],
      terms:new UntypedFormControl('',{validators:Validators.requiredTrue, updateOn: 'blur'}),
      // agegroup:[''],
      // gender:[''],
      marketingOptIn:[''],
      marketingOptSMSIn:[''],
      // searchText:new FormControl('',{validators:Validators.requiredTrue}),
      loyaltyOptIn:['']
  };  
 }
  addressForm() {
    return {
        titleCode:new UntypedFormControl('',{
                                      validators:[
                                        Validators.required
                                        ],
                                        updateOn: 'blur'
                                    }),
        firstName:new UntypedFormControl ('',{
                                     validators:[
                                        Validators.required, 
                                        patternValidator(/^([a-zA-Z0-9?]{1,}\s?([a-zA-Z0-9!@&()+-.,space/?:;' ]{1,68})?)$/)
                                        ],
                                        updateOn: 'blur'
                                   }),
        lastName:new UntypedFormControl ('',{
                                      validators:[
                                        Validators.required,  
                                        patternValidator(/^([a-zA-Z0-9?]{1,}\s?([a-zA-Z0-9!@&()+-.,space/?:;' ]{1,68})?)$/)
                                      ],
                                      updateOn: 'blur'
                                    }), 
        country:new UntypedFormControl(null,{validators:Validators.required,updateOn: 'blur'}), 
        line1:new UntypedFormControl('',{validators:Validators.required,updateOn: 'blur'}), 
        line2:[''],
        city:[''],
        state:[''],
        address:[''],
        town:new UntypedFormControl('',{validators:Validators.required,updateOn: 'blur'}), 
        district:new UntypedFormControl(''), 
        searchText:[''],
        findAddrline1: new UntypedFormControl('',{validators:[Validators.required],updateOn: 'blur'}),
        findAddrline2: [''],
        findAddrCity:new UntypedFormControl('',{validators:[Validators.required],updateOn: 'blur'}),
        findAddrCounty:[''],
        findAddrPostCode:new UntypedFormControl('',{validators:[Validators.required,patternValidator(/^(([a-zA-Z0-9!@#$&()+-.,space/?:;' ]{1,68})?)$/)],updateOn: 'blur'}),
        postalCode: new UntypedFormControl('',{
          validators:[
            Validators.required,
            patternValidator(/^(([a-zA-Z0-9!@#$&()+-.,space/?:;' ]{1,68})?)$/)
          ]
        }),
        phone: new UntypedFormControl ('',{
          validators:[
             Validators.required,
             patternValidator(/^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/)                                      
           ],
           updateOn: 'blur'
         }),
    };
  }
  updatePassword(){
    return {
      oldPassword:new UntypedFormControl('',{validators:Validators.required,updateOn: 'blur'}),
      password:new UntypedFormControl ('',{validators:[Validators.required,  patternValidator(/^.{6,20}$/)],updateOn: 'blur'}),
      confirmPassword:new UntypedFormControl('',{
        validators:[ 
          Validators.required,
          compareValidator('password')],
          updateOn: 'blur'})
     
    }
  }
  profileForm(){
    return{
      titleCode:new UntypedFormControl(null,{validators:Validators.required,updateOn: 'blur'}),
      firstName:new UntypedFormControl('', {
                                     validators:[
                                       Validators.required,
                                       patternValidator(/^([a-zA-Z0-9?]{1,}\s?([a-zA-Z0-9!@&()+-.,space/?:;' ]{1,68})?)$/)
                                      ],
                                      updateOn: 'blur'}),
      lastName:new UntypedFormControl('', {
                                    validators:[
                                      Validators.required,
                                      patternValidator(/^([a-zA-Z0-9?]{1,}\s?([a-zA-Z0-9!@&()+-.,space/?:;' ]{1,68})?)$/)
                                    ],
                                    updateOn: 'blur'}),
      uid: new UntypedFormControl({value: '', disabled: true})       
      // country:new FormControl(null,{validators:Validators.required,updateOn: 'blur'}),
      // postalCode:new FormControl('',{
      //   validators:[
      //     Validators.required,
      //     patternValidator(/^(([a-zA-Z0-9!@#$&()+-.,space/?:;' ]{1,68})?)$/)
      //   ]
      //   }),
      // address:[''],
      // line1:new FormControl('',{validators:Validators.required,updateOn: 'blur'}),
      // line2:[''],
      // town:new FormControl('',{validators:Validators.required,updateOn: 'blur'}),
      // district:[''],
      // phone:new FormControl('', {
      //                            validators:[
      //                              Validators.required,
      //                              patternValidator(/^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/)
      //                             ],
      //                             updateOn: 'blur'}),
      // agegroup:[''],
      // gender:[''],
      // dlMode:[''],
      // searchText:new FormControl('',{validators:Validators.requiredTrue})
    }
  }

  ymarketingForm(){
    const baseSite=this.singletonServ.catalogVersion;
        let validate =  {
      emailAddress: new UntypedFormControl('', {validators:[Validators.required,
        patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)],
        updateOn: 'blur'}),
     /*   phone:new FormControl('', {
          validators:[
            Validators.required,
            patternValidator(/^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/)
           ],
           updateOn: 'blur'}), */
       firstName:[''],
       lastName:[''],
       day:[''],
       month:[''],
       year:[''],
      gender:['NM'],
      newsletterOptIn:['',Validators.requiredTrue],
    //  newsletterOptSMSIn:['',Validators.requiredTrue],
      newsletterOptInTerms:['',Validators.requiredTrue]
   
    }
    if(baseSite.isoCode=="GB")
    {
     /* validate['phone']= new FormControl('', {
        validators:[
          Validators.required,
          patternValidator(/^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/)
         ],
         updateOn: 'blur'}); */
         validate['phone'] = [''];
         validate ['newsletterOptSMSIn'] =  ['']; 
    }
    return validate;
  }

  ymarketingUnsubscribeForm(){
    return {
      emailAddress: new UntypedFormControl({value:'',disabled:false}, {validators: [Validators.required,
        patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ],
        updateOn: 'blur'}),
		phoneNumber:new UntypedFormControl ('',{
                                 validators:[
                                   Validators.required,  
                                   patternValidator(/^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/)
                                  ],
                                  updateOn: 'blur'
                                }),
          firstName: new UntypedFormControl(''),
        lastName: new UntypedFormControl(''), 
        dob: new UntypedFormControl(''),
       day: new UntypedFormControl(''),
       month: new UntypedFormControl(null),
       year: new UntypedFormControl(null),
       postalCode:new UntypedFormControl(''),
       gender:new UntypedFormControl(''),
      newsletterOptIn:[''],
      line1:[],
      line2:[''],
      town:[''],
      district:[''],
      country:[''],
      unsubscribe:new UntypedFormControl(''),
	  smsUnsubscribe:new UntypedFormControl('')
   
    }
  }
  ASMform(){
    return{
      username: new UntypedFormControl('', {validators: Validators.required, updateOn: 'blur'}),
      password: new UntypedFormControl('', {validators: Validators.required, updateOn: 'blur'})
    }
  }
  ASMCustomerform(){
    return{
      customer: new UntypedFormControl(''),
      customerID: new UntypedFormControl(''),
      customerOrderId: new UntypedFormControl('', {
                                   validators:[
                                   Validators.required,
                                   patternValidator(/^[0-9]{8,9}$/)
                                  ]
                                })
    }
  }
  StorePortalAuthForm(){
    return {
      storeName:new UntypedFormControl('', {validators: Validators.required, updateOn: 'blur'}),
      password:new UntypedFormControl('', {validators: Validators.required, updateOn: 'blur'}),
    }
  }
  createWishListForm(){
    return{      
      wishlistName:new UntypedFormControl('New Wish Lists', {validators:Validators.required,updateOn: 'blur'})      
      
    }
  }
  preferenceCenterForm(){
    return {
      firstName: new UntypedFormControl('', {
        validators: [
          Validators.required,
          Validators.maxLength(60),
          patternValidator(/^([a-zA-Z0-9?]{1,}\s?([a-zA-Z0-9!@&()+-.,space/?:;' ]{1,68})?)$/)
        ],
        updateOn: 'blur'
      }),
      lastName: new UntypedFormControl('', {
        validators: [
          Validators.required,
          Validators.maxLength(60),
          patternValidator(/^([a-zA-Z0-9?]{1,}\s?([a-zA-Z0-9!@&()+-.,space/?:;' ]{1,68})?)$/)
        ], updateOn: 'blur'
      }),
      phone: new UntypedFormControl('', {
        validators: [
          Validators.required,
          patternValidator(/^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/)
        ],
        updateOn: 'blur'
      }),
      gender: new UntypedFormControl(''),
      dob: ['',
      [
        // validates date format dd/mm/yyyy with regular expression
        Validators.pattern(
          /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/[0-9]{4}$/
        ),
      ],],
      
      country:new UntypedFormControl(null,{validators:Validators.required,updateOn: 'blur'}), 
      addressLine1:new UntypedFormControl('',{ validators: [Validators.required, Validators.maxLength(255)],updateOn: 'blur'}), 
      addressLine2:new UntypedFormControl(null,{
        validators: [
        Validators.maxLength(255)
      ], updateOn: 'blur'}), 
      city:new UntypedFormControl(null,{
        validators: [
        Validators.required,
        Validators.maxLength(60)
      ], updateOn: 'blur'}), 
      state:new UntypedFormControl(null,{
        validators: [
        Validators.maxLength(60)
      ], updateOn: 'blur'}), 
      postalCode: new UntypedFormControl('',{
        validators:[
          Validators.required,
          Validators.maxLength(60),
          patternValidator(/^(([a-zA-Z0-9!@#$&()+-.,space/?:;' ]{1,68})?)$/)
        ]
      }),
    }
  }
  preferenceCenterChannelForm(){
    return {
      uid: new UntypedFormControl({value:'',disabled:true}, {validators: [Validators.required,
      patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) ],
      updateOn: 'blur'}),
      optUnsubscribeMarketingOptions:new UntypedFormControl(''),
      emailOptin:new UntypedFormControl(''),
      phoneOptin:new UntypedFormControl(''),
      smsphone: new UntypedFormControl({value:'',disabled:true}),
      postOptin:new UntypedFormControl(''),
      otherUnsubcribeReason:new UntypedFormControl(null,{
        validators: [
        Validators.maxLength(255)
      ], updateOn: 'blur'}), 
      unsubscribeoption1: new UntypedFormControl(''),
      unsubscribeoption2: new UntypedFormControl(''),
      unsubscribeoption3: new UntypedFormControl(''),
      unsubscribeoption4: new UntypedFormControl(''),
      unsubscribeoption5: new UntypedFormControl(''),
      unsubscribeoption6: new UntypedFormControl('') 
    }
  }
  preferenceCenterInterestForm(){
    return {
      ourInterestOption1: new UntypedFormControl(''),
      ourInterestOption2: new UntypedFormControl(''),
      ourInterestOption3: new UntypedFormControl(''),
      ourInterestOption4: new UntypedFormControl(''),
      Check_MothersDay: new UntypedFormControl(''),
      Check_FathersDay: new UntypedFormControl(''),
    }
  }
  preferenceCenterUnsubscribeForm(){
    return {
      optUnsubscribeMarketingOptions:new UntypedFormControl(''),
      uid:new UntypedFormControl({value:'',disabled:true}),
      phoneOptin:new UntypedFormControl(''),
      phone: new UntypedFormControl({value:'',disabled:true}),
      emailOptin:new UntypedFormControl(''),
      postOptin:new UntypedFormControl(''),
      otherUnsubcribeReason: new UntypedFormControl(''),
      unsubscribeoption1: new UntypedFormControl(''),
      unsubscribeoption2: new UntypedFormControl(''),
      unsubscribeoption3: new UntypedFormControl(''),
      unsubscribeoption4: new UntypedFormControl(''),
      unsubscribeoption5: new UntypedFormControl(''),
      unsubscribeoption6: new UntypedFormControl(''),
      ourInterestOption1: new UntypedFormControl(''),
      ourInterestOption2: new UntypedFormControl(''),
      ourInterestOption3: new UntypedFormControl(''),
      ourInterestOption4: new UntypedFormControl(''),
      Check_MothersDay: new UntypedFormControl(''),
      Check_FathersDay: new UntypedFormControl(''),
    }
  }
}
