import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from "@angular/forms";
import { SanitizeHtmlPipePipe} from "./sanitize-html-pipe.pipe";
import { StripHtmlPipe } from "./removeHtmltags.pipe";
@NgModule({
  imports: [
    CommonModule,
    FormsModule
 ],
  declarations: [SanitizeHtmlPipePipe,StripHtmlPipe],
  exports: [
    SanitizeHtmlPipePipe,
    StripHtmlPipe,
    CommonModule,
    FormsModule
  ]
})
export class SanitizeHtmlPipeModule { }
