<div 
role="dialog"
id="cookie"
*ngIf="singletonServ&&singletonServ.translateData"
aria-live="polite" 
aria-label="cookieconsent" 
aria-describedby="cookieconsent:desc" 
class="cc-window cc-banner cc-type-info cc-theme-block cc-bottom" >
  <span id="cookieconsent:desc" class="cc-message">
      {{(singletonServ.translateData&&singletonServ.translateData.cookie)?singletonServ.translateData.cookie.detailTxt:''}} 
      <a aria-label="learn more about cookies" role="button" tabindex="0" class="cc-link" href="/store/privacy-policy" target="_blank">
          {{(singletonServ.translateData&&singletonServ.translateData.cookie)?singletonServ.translateData.cookie.ManageCookie:''}} &gt;</a>
      </span>
  <div class="cc-compliance"><a aria-label="dismiss cookie message" role="button" (click)="acceptCookie()" tabindex="0" class="cc-btn cc-dismiss">
     {{(singletonServ.translateData&&singletonServ.translateData.cookie)?singletonServ.translateData.cookie.acceptCookie:''}}
  </a>
  </div>
</div>