import { Component } from '@angular/core';

@Component({
  selector: 'app-collection-categorylp',
  templateUrl: './collection-categorylp.component.html',
  styleUrls: ['./collection-categorylp.component.scss']
})
export class CollectionCategorylpComponent {

}
