import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExclusiveGameGuard  {
  constructor(
    private router: Router
  ) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const _hostname = window.location.hostname;
    if (_hostname && ( _hostname.includes('moltonbrown.co.uk') || _hostname.includes('moltonbrown.de') || _hostname.includes('moltonbrown.com')) ){
      return true
    } else {
      this.router.navigate(["store", "404"]);
      return false;
    }
  }
}
