
<app-mb-bread-crumb [breadcrumb]="breadcrumb"></app-mb-bread-crumb>
<section class="d-flex justify-content-center">
    <div class="org-content" >
      <div class="row m-0 hero-banner-block" *ngIf="heroBannerAboutUS">
        <div class="col-md-6 col-sm-12 p-0 add-banner-text">
          <picture class="">
            <source media="(max-width: 480px)"
            srcset="//media.moltonbrown.co.uk/i/{{heroBannerAboutUS?.imageOne?.endpoint}}/{{heroBannerAboutUS?.imageOne?.name}}?fmt=auto&w=874&$banner-image-poi$ 1x, //media.moltonbrown.co.uk/i/{{heroBannerAboutUS?.imageOne?.endpoint}}/{{heroBannerAboutUS?.imageOne?.name}}?fmt=auto&w=1748&$banner-image-poi$ 2x">
            <source media="(max-width: 874px)"
            srcset="//media.moltonbrown.co.uk/i/{{heroBannerAboutUS?.imageOne?.endpoint}}/{{heroBannerAboutUS?.imageOne?.name}}?fmt=auto&w=874$poi$&sm=aspect&aspect=2:1 1x, //media.moltonbrown.co.uk/i/{{heroBannerAboutUS?.imageOne?.endpoint}}/{{heroBannerAboutUS?.imageOne?.name}}?fmt=auto&w=1748&$poi$&sm=aspect&aspect=2:1 2x">
            <source media="(min-width: 875px)"
            srcset="//media.moltonbrown.co.uk/i/{{heroBannerAboutUS?.imageOne?.endpoint}}/{{heroBannerAboutUS?.imageOne?.name}}?fmt=auto&w=594 1x, //media.moltonbrown.co.uk/i/{{heroBannerAboutUS?.imageOne?.endpoint}}/{{heroBannerAboutUS?.imageOne?.name}}?fmt=auto&w=1188 2x">
            <img class="img-style" priority style="cursor: pointer;" width="864" height="700"
            ngSrc="//media.moltonbrown.co.uk/i/{{heroBannerAboutUS?.imageOne?.endpoint}}/{{heroBannerAboutUS?.imageOne?.name}}?fmt=auto&w=594"
            srcset="//media.moltonbrown.co.uk/i/{{heroBannerAboutUS?.imageOne?.endpoint}}/{{heroBannerAboutUS?.imageOne?.name}}?fmt=auto&w=594 1x, //media.moltonbrown.co.uk/i/{{heroBannerAboutUS?.imageOne?.endpoint}}/{{heroBannerAboutUS?.imageOne?.name}}?fmt=auto&w=1188 2x"
            title="{{heroBannerAboutUS?.imageOne?.name}}" alt="{{heroBannerAboutUS?.altimagetext}}" />
            </picture>
            <div class="hero-title-block">
            <div *ngIf="heroBannerAboutUS?.title" class="hero-title"><h1 class="hero-text">{{heroBannerAboutUS?.title}}</h1></div>
            <div *ngIf="heroBannerAboutUS?.subtitle" class="hero-subtitle"><span class="hero-text subtitle-text">{{heroBannerAboutUS?.subtitle}}</span></div>
            <div class="hero-text hero-desc" [innerHtml]="heroBannerAboutUS?.description?.text"></div>
            </div>

        </div>
        <div class="col-md-6 col-sm-12 p-0 ">
          <picture class="">
            <source media="(max-width: 874px)"
            srcset="//media.moltonbrown.co.uk/i/{{heroBannerAboutUS?.imageTwo?.endpoint}}/{{heroBannerAboutUS?.imageTwo?.name}}?fmt=auto&w=874&$banner-image-poi$ 1x, //media.moltonbrown.co.uk/i/{{heroBannerAboutUS?.imageTwo?.endpoint}}/{{heroBannerAboutUS?.imageTwo?.name}}?fmt=auto&w=1748&$banner-image-poi$ 2x">
            <source media="(min-width: 875px)"
            srcset="//media.moltonbrown.co.uk/i/{{heroBannerAboutUS?.imageTwo?.endpoint}}/{{heroBannerAboutUS?.imageTwo?.name}}?fmt=auto&w=594 1x, //media.moltonbrown.co.uk/i/{{heroBannerAboutUS?.imageTwo?.endpoint}}/{{heroBannerAboutUS?.imageTwo?.name}}?fmt=auto&w=1188 2x">
            <img class="img-style" priority style="cursor: pointer;" width="864" height="700"
            ngSrc="//media.moltonbrown.co.uk/i/{{heroBannerAboutUS?.imageTwo?.endpoint}}/{{heroBannerAboutUS?.imageTwo?.name}}?fmt=auto&w=594"
            srcset="//media.moltonbrown.co.uk/i/{{heroBannerAboutUS?.imageTwo?.endpoint}}/{{heroBannerAboutUS?.imageTwo?.name}}?fmt=auto&w=594 1x, //media.moltonbrown.co.uk/i/{{heroBannerAboutUS?.imageTwo?.endpoint}}/{{heroBannerAboutUS?.imageTwo?.name}}?fmt=auto&w=1188 2x"
            title="{{heroBannerAboutUS?.imageTwo?.name}}" alt="{{heroBannerAboutUS?.altimagetext}}" />
            </picture>
        </div>
    </div>
       <!--Single Msg Banner Ex: Why Molton Brown-->
       <section>
          <ng-container *ngFor="let banner of singleMBannerAboutUS;let i = index; let isFirst = first">
            <div class="row amp-banner mob-content" [ngClass]="{'first-item': isFirst,'left-media' : banner?.media?.mediaAlignment === 'Left Media'}">
              <div class="col-sm-12 col-md-6 d-flex align-items-center desktop-justify mob-only-margin amp-dc-card-wrap" >
                <div [ngClass]="{'amp-msg-container' : banner?.media?.mediaAlignment !== 'Left Media' , 'right-msg-container' : banner?.media?.mediaAlignment === 'Left Media' }" >
                <h2>{{banner?.title}}</h2>
                <div [innerHtml]="banner?.description?.text"></div>
                <div class="button-row">
                  <a href="{{banner?.buttonLink1}}" class="first-button" *ngIf="banner?.buttonText1">
                    <button class="mt-24" [ngClass]="{'black': banner?.buttonPicker1 === 'Black',
                    'btn-black': banner?.buttonPicker1 === 'None',
                    'btn-grey-outline': banner?.buttonPicker1 === 'Grey Outline',
                    'btn-black-outline': banner?.buttonPicker1 === 'Black Outline',
                    'btn-white-outline': banner?.buttonPicker1 === 'White Outline',
                    'btn-white': banner?.buttonPicker1 === 'White'
                  }">{{banner?.buttonText1}}</button>
                  </a>
                  <a href="{{banner?.buttonLink2}}" *ngIf="banner?.buttonText2">
                    <button class="mt-24" [ngClass]="{'btn-black': banner?.buttonPicker1 === 'Black',
                    'btn-grey-outline': banner?.buttonPicker2 === 'None',
                    'btn-grey-outline': banner?.buttonPicker2 === 'Grey Outline',
                    'btn-black-outline': banner?.buttonPicker2 === 'Black Outline',
                    'btn-white-outline': banner?.buttonPicker2 === 'White Outline',
                    'btn-white': banner?.buttonPicker2 === 'White'
                  }">{{banner?.buttonText2}}</button>
                  </a>
                </div>
                </div>
              </div>
              <div class="col-sm-12 col-md-6 media-padding amp-dc-card-wrap" [ngClass]="{'media-first' : banner?.media?.mediaAlignment === 'Left Media', 'media-second' : banner?.media?.mediaAlignment === 'Right Media'}">
                <a href="{{banner?.buttonLink1}}">
                  <ng-container *ngIf="banner?.media?.image && !banner?.media?.video">
                  <picture [routerLink]="banner?.buttonLink1" >
                    <source media="(max-width: 874px)"
                    srcset="//media.moltonbrown.co.uk/i/{{banner?.media?.image?.endpoint}}/{{banner?.media?.image?.name}}?fmt=auto&w=874&$banner-image-poi$ 1x, //media.moltonbrown.co.uk/i/{{banner?.media?.image?.endpoint}}/{{banner?.media?.image?.name}}?fmt=auto&w=1536&$banner-image-poi$ 2x">
                    <source media="(min-width: 875px)"
                    srcset="//media.moltonbrown.co.uk/i/{{banner?.media?.image?.endpoint}}/{{banner?.media?.image?.name}}?fmt=auto&w=594 1x, //media.moltonbrown.co.uk/i/{{banner?.media?.image?.endpoint}}/{{banner?.media?.image?.name}}?fmt=auto&w=1188 2x">
                    <img class="img-fluid w-100" width="738" height="738"  style="cursor: pointer;"
                    ngSrc="//media.moltonbrown.co.uk/i/{{banner?.media?.image?.endpoint}}/{{banner?.media?.image?.name}}?fmt=auto&w=594"
                    srcset="//media.moltonbrown.co.uk/i/{{banner?.media?.image?.endpoint}}/{{banner?.media?.image?.name}}?fmt=auto&w=594 1x, //media.moltonbrown.co.uk/i/{{banner?.media?.image?.endpoint}}/{{banner?.media?.image?.name}}?fmt=auto&w=1188 2x"
                    title="{{banner?.media?.image?.name}}" alt="{{banner?.media?.image?.altimagetext}}" (click)="banner?.media?.gtmProperties && gtmPromoClicks(banner.media.gtmProperties)" />
                    </picture>
                  </ng-container>
                  <ng-container *ngIf="banner?.media?.video && !banner?.media?.image">
                    <video class="amp-dc-video" poster="//media.moltonbrown.co.uk/v/{{banner?.media?.video?.endpoint}}/{{banner?.media?.video?.name}}?protocol=https"
                    autoplay="" loop="" muted="" playsinline="" oncanplay="this.play()" onloadedmetadata="this.muted = true"  src="//media.moltonbrown.co.uk/v/{{banner?.media?.video?.endpoint}}/{{banner?.media?.video?.name}}/mp4_720p">
                      <source src="//media.moltonbrown.co.uk/v/{{banner?.media?.video?.endpoint}}/{{banner?.media?.video?.name}}/hevc720p"
                        data-res="Medium" data-bitrate="1000" label="Medium" type="video/mp4; codecs=hevc">          
                      <source src="//media.moltonbrown.co.uk/v/{{banner?.media?.video?.endpoint}}/{{banner?.media?.video?.name}}/vp9720p"
                        data-res="Medium" data-bitrate="1000" label="Medium" type="video/webm; codecs=vp9">         
                      <source src="//media.moltonbrown.co.uk/v/{{banner?.media?.video?.endpoint}}/{{banner?.media?.video?.name}}/mp4_480p"
                    data-res="Medium" data-bitrate="689" label="Medium" type="video/mp4; codecs==h264">
                    </video>
                  </ng-container>
                </a>
              </div>
            </div>
          </ng-container>

          <!-- Circular banner -->
          <ng-container *ngIf="circleImageOverlayBannerAboutUS">
            <div *ngIf="circleImageOverlayBannerAboutUS?.Caption || circleImageOverlayBannerAboutUS?.image || circleImageOverlayBannerAboutUS?.video">
            <div class="circle-overlay-banner" [ngClass]="{'left-pos' : circleImageOverlayBannerAboutUS?.bannerBoxPosition === 'Left', 'right-pos' : circleImageOverlayBannerAboutUS?.bannerBoxPosition === 'Right',
            'center-pos' : circleImageOverlayBannerAboutUS?.bannerBoxPosition === 'Middle' }">

                <div class="row d-flex circle-overlay-banner-row"
                    [ngClass]="{'left-image' : circleImageOverlayBannerAboutUS?.bannerBoxPosition === 'Left', 'right-image' : circleImageOverlayBannerAboutUS?.bannerBoxPosition === 'Right',
                    'center-image' : circleImageOverlayBannerAboutUS?.bannerBoxPosition === 'Middle' }">

                    <div class="col-sm-12 circle-overlay-banner-text amp-dc-card-wrap">

                        <h3 class="">{{circleImageOverlayBannerAboutUS?.Caption}}</h3>

                        <div class="sub-title" [innerHtml]="circleImageOverlayBannerAboutUS?.description?.text"></div>
                        <div class="button-holder" *ngIf="circleImageOverlayBannerAboutUS?.buttonLink">
                                <a href="{{circleImageOverlayBannerAboutUS?.buttonLink}}">
                                    <button class="btn-black circle-btn" aria-label="circleImageOverlayBannerAboutUS?.buttonText"
                                        (click)="circleImageOverlayBannerAboutUS?.gtmProperties && gtmPromoClicks(circleImageOverlayBanner.gtmProperties)">{{circleImageOverlayBannerAboutUS?.buttonText}}</button>
                                </a>
                        </div>
                    </div>

                    <div class="col-sm-12 circle-overlay-banner-image amp-dc-card-wrap">
                      <a href="{{circleImageOverlayBannerAboutUS?.buttonLink}}">
                        <ng-container *ngIf="circleImageOverlayBannerAboutUS?.image">

                            <picture>

                                <source media="(min-width: 875px)"
                                    srcset="//media.moltonbrown.co.uk/i/{{circleImageOverlayBannerAboutUS?.image?.endpoint}}/{{circleImageOverlayBannerAboutUS?.image?.name}}?fmt=auto&w=393&$banner-image-poi$ 1x,  //media.moltonbrown.co.uk/i/{{circleImageOverlayBannerAboutUS?.image?.endpoint}}/{{circleImageOverlayBannerAboutUS?.image?.name}}?fmt=auto&w=786&$banner-image-poi$ 2x">

                                <source media="(max-width: 874px)"
                                    srcset="//media.moltonbrown.co.uk/i/{{circleImageOverlayBannerAboutUS?.image?.endpoint}}/{{circleImageOverlayBannerAboutUS?.image?.name}}?fmt=auto&w=268 1x,  //media.moltonbrown.co.uk/i/{{circleImageOverlayBannerAboutUS?.image?.endpoint}}/{{circleImageOverlayBannerAboutUS?.image?.name}}?fmt=auto&w=536 2x">

                                <img class="img-fluid w-100 circle-layout" width="386" height="386"
                                    ngSrc="//media.moltonbrown.co.uk/i/{{circleImageOverlayBannerAboutUS?.image?.endpoint}}/{{circleImageOverlayBannerAboutUS?.image?.name}}?fmt=auto&w=375"
                                    srcset="//media.moltonbrown.co.uk/i/{{circleImageOverlayBannerAboutUS?.image?.endpoint}}/{{circleImageOverlayBannerAboutUS?.image?.name}}?fmt=auto&w=375 1x,  //media.moltonbrown.co.uk/i/{{circleImageOverlayBannerAboutUS?.image?.endpoint}}/{{circleImageOverlayBannerAboutUS?.image?.name}}?fmt=auto&w=750 2x"
                                    title="{{circleImageOverlayBannerAboutUS?.image?.name}}"
                                    alt="{{circleImageOverlayBannerAboutUS?.image?.altimagetext}}">

                            </picture>

                        </ng-container>

                        <ng-container *ngIf="circleImageOverlayBannerAboutUS?.video">

                            <video class="amp-dc-video circle-layout"
                                poster="//media.moltonbrown.co.uk/v/{{circleImageOverlayBannerAboutUS?.video?.endpoint}}/{{circleImageOverlayBannerAboutUS?.video?.name}}?protocol=https"
                                autoplay="" loop="" muted="" playsinline="" oncanplay="this.play()" onloadedmetadata="this.muted = true"
                                src="//media.moltonbrown.co.uk/v/{{circleImageOverlayBannerAboutUS?.video?.endpoint}}/{{circleImageOverlayBannerAboutUS?.video?.name}}/mp4_720p">

                                <source
                                    src="//media.moltonbrown.co.uk/v/{{circleImageOverlayBannerAboutUS?.video?.endpoint}}/{{circleImageOverlayBannerAboutUS?.video?.name}}/hevc720p"
                                    data-res="Medium" data-bitrate="1000" label="Medium" type="video/mp4; codecs=hevc">
                                <source
                                    src="//media.moltonbrown.co.uk/v/{{circleImageOverlayBannerAboutUS?.video?.endpoint}}/{{circleImageOverlayBannerAboutUS?.video?.name}}/vp9720p"
                                    data-res="Medium" data-bitrate="1000" label="Medium" type="video/webm; codecs=vp9">
                                <source
                                    src="//media.moltonbrown.co.uk/v/{{circleImageOverlayBannerAboutUS?.video?.endpoint}}/{{circleImageOverlayBannerAboutUS?.video?.name}}/mp4_480p"
                                    data-res="Medium" data-bitrate="689" label="Medium" type="video/mp4; codecs=h264">
          
                            </video>

                        </ng-container>
                      </a>
                    </div>

                </div>

            </div>
          </div>
          </ng-container>

          <!-- additional message banner -->
<ng-container *ngIf="additionalMsgBannerAboutUS">
  <div class="single-msg-bottom" *ngIf="additionalMsgBannerAboutUS?.title || additionalMsgBannerAboutUS?.image || additionalMsgBannerAboutUS?.video">
    <div [ngClass]="{'add-banner' : additionalMsgBannerAboutUS?.backgroundChooser === 'White', 'add-banner-marble' : additionalMsgBannerAboutUS?.backgroundChooser === 'Marble' }">
        <div class="row d-flex add-banner-row"
            [ngClass]="{'left-image' : additionalMsgBannerAboutUS?.bannerBoxPosition === 'Left', 'right-image' : additionalMsgBannerAboutUS?.bannerBoxPosition === 'Right' }">
            <div class="col-sm-12 col-md-6 add-banner-text amp-dc-card-wrap">
                <h2 [ngClass]="{'add-ban-title' : additionalMsgBannerAboutUS?.backgroundChooser === 'White', 'add-ban-marble-title' : additionalMsgBannerAboutUS?.backgroundChooser === 'Marble' }">{{additionalMsgBannerAboutUS?.title}}</h2>
                <div [ngClass]="{'add-ban-desc' : additionalMsgBannerAboutUS?.backgroundChooser === 'White', 'add-ban-marble-desc' : additionalMsgBannerAboutUS?.backgroundChooser === 'Marble' }"
                [innerHtml]="additionalMsgBannerAboutUS?.description?.text"></div>
                <div class="add-ban-btn">
                    <ng-container *ngIf="additionalMsgBannerAboutUS?.buttonText1">
                        <a class="mob-add-btn" href="{{additionalMsgBannerAboutUS?.buttonLink1}}">
                                <button  class="btn-black mr-20" [ngClass]="{'btn-black': additionalMsgBannerAboutUS?.buttonPicker1 === 'Black Outline', 'btn-white': additionalMsgBannerAboutUS?.buttonPicker1 === 'White' }"
                                (click)="additionalMsgBannerAboutUS?.gtmProperties && gtmPromoClicks(additionalMsgBanner.gtmProperties)">{{additionalMsgBannerAboutUS?.buttonText1}}</button>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="additionalMsgBannerAboutUS?.buttonText2">
                        <a class="mob-add-btn" href="{{additionalMsgBannerAboutUS?.buttonLink2}}">
                            <button class="btn-grey-outline" [ngClass]="{'btn-grey-outline': additionalMsgBannerAboutUS?.buttonPicker2 === 'Gery Outline', 'btn-white-outline': additionalMsgBannerAboutUS?.buttonPicker2 === 'White Outline' }"
                               (click)="additionalMsgBannerAboutUS?.gtmProperties && gtmPromoClicks(additionalMsgBanner.gtmProperties)">{{additionalMsgBannerAboutUS?.buttonText2}}</button>
                        </a>
                    </ng-container>
                </div>
            </div>
            <div class="col-md-6 add-ban-image amp-dc-card-wrap">
              <a href="">
                <ng-container *ngIf="additionalMsgBannerAboutUS?.media?.image">
                  <picture>
                      <source media="(min-width: 875px)"
                          srcset="//media.moltonbrown.co.uk/i/{{additionalMsgBannerAboutUS?.media?.image?.endpoint}}/{{additionalMsgBannerAboutUS?.media?.image?.name}}?fmt=auto&w=393 1x,  //media.moltonbrown.co.uk/i/{{additionalMsgBannerAboutUS?.media?.image?.endpoint}}/{{additionalMsgBannerAboutUS?.media?.image?.name}}?fmt=auto&w=786 2x">
                      <source media="(max-width: 874px)"
                          srcset="//media.moltonbrown.co.uk/i/{{additionalMsgBannerAboutUS?.media?.image?.endpoint}}/{{additionalMsgBannerAboutUS?.media?.image?.name}}?fmt=auto&w=268&$banner-image-poi$ 1x,  //media.moltonbrown.co.uk/i/{{additionalMsgBannerAboutUS?.media?.image?.endpoint}}/{{additionalMsgBannerAboutUS?.media?.image?.name}}?fmt=auto&w=536&$banner-image-poi$ 2x">
                      <img class="img-fluid w-100 additional-image" width="393" height="393"
                          ngSrc="//media.moltonbrown.co.uk/i/{{additionalMsgBannerAboutUS?.media?.image?.endpoint}}/{{additionalMsgBannerAboutUS?.media?.image?.name}}?fmt=auto&w=375"
                          srcset="//media.moltonbrown.co.uk/i/{{additionalMsgBannerAboutUS?.media?.image?.endpoint}}/{{additionalMsgBannerAboutUS?.media?.image?.name}}?fmt=auto&w=375 1x,  //media.moltonbrown.co.uk/i/{{msgBanner?.column1?.media?.image?.endpoint}}/{{msgBanner?.column1?.media?.image?.name}}?fmt=auto&w=750 2x"
                          title="{{additionalMsgBannerAboutUS?.media?.image?.name}}"
                          alt="{{additionalMsgBannerAboutUS?.media?.image?.altimagetext}}">
                  </picture>
              </ng-container>
              <ng-container *ngIf="additionalMsgBannerAboutUS?.video">
                <video class="amp-dc-video amplience-video"
                    poster="//media.moltonbrown.co.uk/v/{{additionalMsgBannerAboutUS?.media?.video?.endpoint}}/{{additionalMsgBannerAboutUS?.media?.video?.name}}?protocol=https"
                    autoplay="" loop="" muted="" playsinline="" oncanplay="this.play()" onloadedmetadata="this.muted = true"
                    src="//media.moltonbrown.co.uk/v/{{additionalMsgBannerAboutUS?.media?.video?.endpoint}}/{{additionalMsgBannerAboutUS?.media?.video?.name}}/mp4_720p">
                    <source
                        src="//media.moltonbrown.co.uk/v/{{additionalMsgBannerAboutUS?.media?.video?.endpoint}}/{{additionalMsgBannerAboutUS?.media?.video?.name}}/hevc720p"
                        data-res="Medium" data-bitrate="1000" label="Medium" type="video/mp4; codecs=hevc">
                    <source
                        src="//media.moltonbrown.co.uk/v/{{additionalMsgBannerAboutUS?.media?.video?.endpoint}}/{{additionalMsgBannerAboutUS?.media?.video?.name}}/vp9720p"
                        data-res="Medium" data-bitrate="1000" label="Medium" type="video/webm; codecs=vp9">
                    <source
                        src="//media.moltonbrown.co.uk/v/{{additionalMsgBannerAboutUS?.media?.video?.endpoint}}/{{additionalMsgBannerAboutUS?.media?.video?.name}}/mp4_480p"
                        data-res="Medium" data-bitrate="689" label="Medium" type="video/mp4 codecs=h264">
                </video>
            </ng-container>
              </a>
            </div>
        </div>
    </div>
    </div>
  </ng-container>
      </section>
    </div>
  </section>
