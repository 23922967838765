import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChange,
  Input,
  Output,
  EventEmitter,
  HostListener,
  OnDestroy,
  Inject,
  PLATFORM_ID
} from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import { HeaderComponentService } from "../header.service";
import { SingletonService } from "../../../services/singleton.service";
import { BasketPageComponentService } from "../../../checkoutpage/basketpage/basketpage.service";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import * as moment from 'moment';
import * as _ from "lodash";
import { TranslateServiceSubService } from "../../../pipe/translate-service-sub.service";
import { GtmMethodService } from '../../../services/gtmmethods.service';
import { DeviceDetectorService } from "ngx-device-detector";
import { profileComponentService } from "src/app/component/profile-form/profile.service";
import { WebExtendService } from "src/app/services/webExtend.service";

declare var $: any;
declare var window: any;
@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"]  
})
export class CartComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  isAnnexCloudEnabled: boolean;
  isLoyaltyEnrolled: boolean;
  loyaltyPoints: string;
  @HostListener("document:click")
  onDiscardCart() {
    this.showCartBlock = false;
    this.isPointer = false;
  }
  @HostListener("window:resize", [])
  onResize(event) {
    this.cart = this.singletonServ.cartObj;
  }
  @HostListener("click", ["$event"])
  onClickCart(e) {
    this.isPointer = false;
  }
  @HostListener("tap", ["$event"])
  onTap(e) {
    this.isPointer = true;
  }
  @HostListener("scroll", ["$event"])
  scrollHandler(event) {
    this.scrolled = event.target.scrollTop > 0;
  }
  @Output() discardSubscription: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("scrollContainer") scrollCartContainer: ElementRef;
  @ViewChild("storeCartCount") cartCountElement: ElementRef;
  @Input() renderCart: boolean;
  @Input() message: any;
  @Input() cartLocalData: any;
  scrolled: boolean;
  cartMessage: any;
  subscription: Subscription;
  cart: any;
  totalAmount: number;
  cartStatus: boolean;
  showCartBlock: boolean;
  picknmix6: string;
  picknmix3: string;
  toggleCartBox: boolean;
  productCode: string;
  refreshBasket: boolean;
  animate: boolean;
  basketEmptyContent: string;
  isocode: any;
  freeMsg: string;
  isPointer: boolean;
  deviceInfo: any;
  mobileDevice: boolean;
  desktopDevice: boolean;
  productQty = [];
  giftQuantityArray=[];
  giftBuildQuantity: any;
  giftBoxDetails: any = {};
  giftBoxDtls: any;  
  isEmarsysEnabled: boolean;
  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    public headerServ: HeaderComponentService,
    public singletonServ: SingletonService,
    public location: Location,
    public gtmServ: GtmMethodService,
    public router: Router,
    public basketServ: BasketPageComponentService,
    private translate: TranslateServiceSubService,
    public deviceService: DeviceDetectorService,
    public profileServ: profileComponentService,
    public webExtend:WebExtendService

  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.cart = this.singletonServ.cartObj;
  }
  onCarttouchStart(event) {
    if (event.pointerType && event.pointerType == "touch") {
      this.isPointer = true;
      if (this.toggleCartBox) {
        this.router.navigate(["store", "mbcart", "mbBasket"]);
        this.toggleCartBox = false;
      } else {
        this.toggleCartBox = true;
      }
    }
  }
  getBundleProductQuntity(entry,bottle){
    if(entry.pickAgain){
     return  bottle.pickerQuanity
   } else{
     return bottle.quantity/entry.quantity; ;
  }
 }
 getGiftProducts(entry,bottle){
  this.giftBuildQuantity = bottle.quantity/entry.quantity;
    this.giftQuantityArray.length = this.giftBuildQuantity;
    return this.giftQuantityArray;
 }
  getBundleQuantity(entry){
    let p=0;
    let i;
    for( i=0;i<entry.product.length;i++){
      if(!entry.product[i]['product']['isSample']){
       let qty=entry.product[i].quantity;
         p = p + qty ;
      }
    }
    if(entry.bundleTemplateId){
      if (entry.bundleTemplateId.indexOf("6") != -1) {
         p=p/6
      } else {
        p=p/3
      }
    }
    return p;
  }
  onViewBasket(event) {
    event.preventDefault();
    event.stopPropagation();
    if ((event.ctrlKey && event.which === 1) || event.metaKey) {
      window.open("/store/mbcart/mbBasket");
    } else {
      this.router.navigate(["store", "mbcart", "mbBasket"]);
    }
    this.showCartBlock = false;
    this.toggleCartBox = false;
  }
  onViewProduct(event, entry) {
    const _url = "/store" + entry.product.url.replace("/p/", "/");
    this.router.navigate([_url]);
    this.showCartBlock = false;
    this.toggleCartBox = false;
  }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    const that = this;
    if (changes["message"]) {
      if (changes["message"]["currentValue"] != undefined) {
        that.cartMessage = changes["message"]["currentValue"];
      }
    }
    if(this.getBrowserSessionData("GBBoxDetails")) {
      this.giftBoxDtls = JSON.parse(this.getBrowserSessionData("GBBoxDetails"));
    }else{
      const baseSite = this.singletonServ.catalogVersion;
      if(baseSite) this.getAndSetTokenForGiftBoxDetails(baseSite);
    }
  }
  ngOnInit() {
      // AnnexCloud points update call, when product added to basket
      this.singletonServ.getMessage().subscribe(message => {
        if(message && message.productAddedToBasket ) { 
          const _userDetail = this.singletonServ.userDetail;          
          // Annexcloud Enable/disable flag starts
          this.singletonServ.getCatalogData().subscribe(
            (resp) => {
              if(resp && resp["enableAnnexCloud"]) this.isAnnexCloudEnabled = resp["enableAnnexCloud"];
              if(resp && resp["enableEmarsysFlow"]) this.isEmarsysEnabled = resp["enableEmarsysFlow"];
              // get loyalty program enrollment status
              if (_userDetail == undefined && this.getBrowserSessionData(baseSite.reg) && this.isAnnexCloudEnabled) {
              const user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
              this.profileServ.getUserData(baseSite, user.token, user.email).subscribe((res) => {
                if (res && res['annexcloudOptIn']) {
                  this.isLoyaltyEnrolled = res['annexcloudOptIn'];
                  if(this.isLoyaltyEnrolled)
                  {
                  this.basketServ.getBasketRewardPoint(baseSite, user.token, user.email, user.code).subscribe(
                    (resp: any) => {
                      if(resp.cartPoints) this.loyaltyPoints = resp.cartPoints;
                    })
                }
              }
              })
            } else if ( _userDetail && _userDetail['annexcloudOptIn']){
              this.isLoyaltyEnrolled = _userDetail['annexcloudOptIn']
            //  this.basketServ.getBasketRewardPoint(baseSite, _userDetail.token, _userDetail.email, _userDetail.code).subscribe(
            //    (resp: any) => {
            //      if(resp.cartPoints) this.loyaltyPoints = resp.cartPoints;
            //    })
              }
        
           }); 
        }
  
      });

    const baseSite = this.singletonServ.catalogVersion;
    if (baseSite) {
      this.freeMsg = "FREE";
      if (baseSite.lngCode == "de") {
        this.freeMsg = "GRATIS";
        this.basketEmptyContent = "Der Warenkorb ist leer";
      } else if (baseSite.lngCode == "eu") {
        this.basketEmptyContent = "Basket is empty";
      } else if (baseSite.lngCode == "us") {
        this.basketEmptyContent = "Cart is empty";
      } else if (baseSite.lngCode == "en") {
        this.basketEmptyContent = "Basket is empty";
      }
      const lngCode = baseSite.lngCode;
      this.isocode = lngCode;
      if(this.getBrowserSessionData("GBBoxDetails")) {
        this.giftBoxDtls = JSON.parse(this.getBrowserSessionData("GBBoxDetails"));
      }else{
        this.getAndSetTokenForGiftBoxDetails(baseSite);
      }
    }
    this.getDeviceInfo();
  }
  getDeviceInfo() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (isMobile) {
      this.mobileDevice = true;
    } else {
      this.mobileDevice = false;
    }
    if (isDesktopDevice) {
      this.desktopDevice = true;
    } else {
      this.desktopDevice = false;
    }
  }
  getRouterPath(data) {
    if (!data.isBundle) {
      if (data.product.url.indexOf("/c/") != -1) {
        if (data.category) {
          let _constructUrl = data.product.category.url.slice(1).split("/");
          _constructUrl.splice(-2, 2);
          const _produrl = _constructUrl.join("/");
          const _url = "/store/" + _produrl + "/" + data.name + "/" + data.code;
          return _url;
        }
      } else {
        const _url = "/store" + data.product.url.replace("/p/", "/");
        return _url;
      }
    }
    return "";
  }

  ngAfterViewInit() {

    const _userDetail = this.singletonServ.userDetail;
    const baseSite = this.singletonServ.catalogVersion;

    // Annexcloud Enable/disable flag starts
   this.singletonServ.getCatalogData().subscribe(
     (resp) => {
       if(resp && resp["enableAnnexCloud"]) this.isAnnexCloudEnabled = resp["enableAnnexCloud"];
       // get loyalty program enrollment status
       if (_userDetail == undefined && this.getBrowserSessionData(baseSite.reg) && this.isAnnexCloudEnabled) {
        const user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
        this.profileServ.getUserData(baseSite, user.token, user.email).subscribe((res) => {
          if (res && res['annexcloudOptIn']) {
           this.isLoyaltyEnrolled = res['annexcloudOptIn'];
           if(this.isLoyaltyEnrolled)
           {
           this.basketServ.getBasketRewardPoint(baseSite, user.token, user.email, user.code).subscribe(
             (resp: any) => {
               if(resp.cartPoints) this.loyaltyPoints = resp.cartPoints;
             })
         }
        }
        })
      } else if ( _userDetail && _userDetail['annexcloudOptIn']){
        this.isLoyaltyEnrolled = _userDetail['annexcloudOptIn']
      //  this.basketServ.getBasketRewardPoint(baseSite, _userDetail.token, _userDetail.email, _userDetail.code).subscribe(
      //    (resp: any) => {
      //      if(resp.cartPoints) this.loyaltyPoints = resp.cartPoints;
      //    })
        }

      });
      
    this.singletonServ.getMessage().subscribe((message) => {
      if (message.refreshCartDetail) {
        this.retrieveCartDetails();
      } else if (message.signOutCart){
        this.signoutCredentials()
      } if (message.isloggedInUser){
        this.signinCredentials()
      } if (message.refreshSubsVBag){
        this.setSubscriptionTag(message.refreshSubsVBag)
      }
    });  
    
  }

  /* get user history cart to show previous cart */
  fetchRelavantBasket(token, email) {
    const baseSite = this.singletonServ.catalogVersion;
    if (baseSite) {
      this.headerServ
        .getCurrentUserRelevantCart(baseSite, token, email)
        .subscribe(
          (resp: any) => {
            if (resp) {
              this.getCartDetail(resp);
            }
          },
          (error) => {}
        );
    }
  }

  retrieveCartDetails() {
    const baseSite = this.singletonServ.catalogVersion;
    if (baseSite) {
      if (this.getBrowserSessionData(baseSite.reg)) {
        const user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
        if (user.token) {
          if (user.code) {
            this.fetchBasket(user.token, user.email, user.code);
          } else {
            this.cart = undefined;
            this.singletonServ.cartObj = undefined;
          }
        } else {
          this.headerServ.generateCartToken(baseSite).subscribe(
            (resp) => {
              user.token = resp["access_token"];
              this.setBrowserSessionData(baseSite.reg, JSON.stringify(user));
              if (user.code) {
                this.fetchBasket(user.token, user.email, user.code);
              } else {
                this.cart = undefined;
                this.singletonServ.cartObj = undefined;
              }
            },
            (err) => {}
          );
        }
      } else {
        if (this.getBrowserSessionData(baseSite.guest)) {
          const user = JSON.parse(this.getBrowserSessionData(baseSite.guest));
          if (user.token) {
            this.fetchBasket(user.token, "anonymous", user.guid);
          }
        } else {
          this.cart = undefined;
          this.singletonServ.cartObj = undefined;
        }
      }
    }
  }
  fetchBasket(token, email, code) {
    const _reg = email != "anonymous" ? true : false;
    const baseSite = this.singletonServ.catalogVersion;
    this.subscription = this.headerServ
      .retrieveCartDetails(baseSite, token, email, code)
      .subscribe(
        (response) => {
          this.getCartDetail(response);
        },
        (err) => {
          if (err.error) {
            if (err.error["errors"]) {
              if (err.error["errors"][0]) {
                if (err.error["errors"][0]["type"] == "InvalidTokenError") {
                  if (!baseSite.csAgent) {
                    this.headerServ
                      .generateCartToken(baseSite)
                      .subscribe((resp: any) => {
                        if (_reg) {
                          const user = JSON.parse(
                            this.getBrowserSessionData(baseSite.reg)
                          );
                          user.token = resp["access_token"];
                          this.setBrowserSessionData(
                            baseSite.reg,
                            JSON.stringify(user)
                          );
                          this.fetchBasket(resp["access_token"], email, code);
                        } else {
                          const user = JSON.parse(
                            this.getBrowserSessionData(baseSite.guest)
                          );
                          user.token = resp["access_token"];
                          this.setBrowserSessionData(
                            baseSite.guest,
                            JSON.stringify(user)
                          );
                          this.fetchBasket(resp["access_token"], email, code);
                        }
                      });
                  } else {
                    this.singletonServ.sendMessage({ invalidAgentToken: true });
                  }
                }
              }
            }
          }
        }
      );
  }
  IfZero(num) {
    return num <= 9 ? "0" + num : num;
  }

  /*Remove Bundle */
  onSpliceBundleItem(event, entry, k, i) {
    const bundleNo = entry["products"][0]["bundleNo"];
    const baseSite = this.singletonServ.catalogVersion;
    if (this.getBrowserSessionData(baseSite.reg)) {
      const _user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
      this.removeBundle(_user.token, _user.email, _user.code, bundleNo);
    } else {
      if (this.getBrowserSessionData(baseSite.guest)) {
        const _user = JSON.parse(this.getBrowserSessionData(baseSite.guest));
        this.removeBundle(_user.token, "anonymous", _user.guid, bundleNo);
      }
    }
  }
  removeBundle(token, email, code, bundleNo) {
    const baseSite = this.singletonServ.catalogVersion;
    this.headerServ
      .removeBundle(baseSite, token, email, code, bundleNo)
      .subscribe(
        (response) => {
          this.showCartBlock = false;
          this.fetchBasket(token, email, code);
        },
        (err) => {
          this.fetchBasket(token, email, code);
        }
      );
  }

  /* Remove Entry from cart */
  onSpliceItem(event, data, k) {
    event.stopPropagation();
    const baseSite = this.singletonServ.catalogVersion;
    if (this.getBrowserSessionData(baseSite.reg)) {
      let user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
      if (data.isBundle) {
        this.removeBundle(user.token, user.email, user.code, data.bundleNo);
      } else {
        this.removeCartEntry(
          user.token,
          user.email,
          user.code,
          data.entryNumber
        );
      }
    } else {
      if (this.getBrowserSessionData(baseSite.guest)) {
        const user = JSON.parse(this.getBrowserSessionData(baseSite.guest));
        if (data.isBundle) {
          this.removeBundle(user.token, "anonymous", user.guid, data.bundleNo);
        } else {
          this.removeCartEntry(
            user.token,
            "anonymous",
            user.guid,
            data.entryNumber
          );
        }
      }
    }
    this.setGtmOnRemoveProduct(data);
  }
  pageUrl1(dataurl) {
    if (dataurl.product.length) {
      const pageUrl = dataurl.product[0]["product"].url;
      if (pageUrl.indexOf("/c/") != -1) {
        const _replaceUrl = pageUrl.replace("/c/", "/");
        if (_replaceUrl.indexOf("/p/") != -1) {
          const _replaceProductUrl = pageUrl.replace("/p/", "/");
          return _replaceProductUrl.split("/");
        } else {
          return _replaceUrl.split("/");
        }
      } else if (pageUrl.indexOf("/p/") != -1) {
        const _replaceProductUrl = pageUrl.replace("/p/", "/");
        return _replaceProductUrl.split("/");
      }
    } else {
      const pageUrl = dataurl.product.url;
      return pageUrl.split("/");
    }
  }

  setGtmOnRemoveProduct(data) {
    const baseSite = this.singletonServ.catalogVersion;
    const pageUrlCat = this.pageUrl1(data);
    const pageUrlMainCat = pageUrlCat[1].split("-").join(" & ").toUpperCase();
    let _subCatg;
    if (
      pageUrlCat[2] == "bath-body-gift-sets" ||
      pageUrlCat[2] == "body-lotion-cream" ||
      pageUrlCat[2] == "bath-shower-gel"
    ) {
      if (pageUrlCat[2] == "bath-body-gift-sets") {
        let urlsplit: any = pageUrlCat[2].split("-");
        let subcat =
          urlsplit[0] +
          " & " +
          urlsplit[1] +
          " " +
          urlsplit[2] +
          " " +
          urlsplit[3];
        _subCatg = subcat.toUpperCase();
      } else if (pageUrlCat[2] == "body-lotion-cream") {
        let urlsplit: any = pageUrlCat[2].split("-");
        let subcat = urlsplit[0] + " " + urlsplit[1] + " & " + urlsplit[2];
        _subCatg = subcat.toUpperCase();
      } else if (pageUrlCat[2] == "bath-shower-gel") {
        let urlsplit: any = pageUrlCat[2].split("-");
        let subcat = urlsplit[0] + " & " + urlsplit[1] + " " + urlsplit[2];
        _subCatg = subcat.toUpperCase();
      }
    } else {
      let subcat = pageUrlCat[2].split("-").join(" & ").toUpperCase();
      _subCatg = subcat.toUpperCase();
    }
    const pageUrlSubCat = _subCatg;

    let cartDetails: any = {};
    if (data.product.length) {
      cartDetails = {
        products: [],
        categoryList: pageUrlMainCat + " - " + _subCatg,
        currencyCode: data.product[0].product.price.currencyIso,
      };
      cartDetails.currencyCode = data.product[0].product.price.currencyIso;

      for (let i = 0; i < data.product.length; i++) {
        let saleprice;
        if (data.product[i].originalPrice) {
          saleprice = data.product[i].price.value;
        } else {
          saleprice = "";
        }

        cartDetails.products.push({
          productName: data.product[i].product.productDisplayName,
          productID: data.product[i].product.code,
          productPrice: "",
          productBrand: "Molton Brown",
          productCategory: _subCatg,
          productVariant: data.product[i].productVariant
            ? data.product[i].productVariant
            : "",
          productQuantity: data.product[i].quantity,
          size: data.product[i].product.size
            ? data.product[i].product.size
            : "",
          reviewRating: data.product[i].productAverageRating
            ? data.product[i].productAverageRating
            : "",
          reviewsCount: data.product[i].reviewCount
            ? data.product[i].reviewCount
            : "",
          saleStatus: data.product[i].originalPrice ? "True" : "False",
          stockLevel:
            data.product[i].stock.stockLevelStatus == "inStock"
              ? "True"
              : "False",
          productStockLevelNumber: data.product[i].stock.stockLevel
            ? data.product[i].stock.stockLevel
            : "",
          deleveryType: data.product.productEdition
            ? data.product.productEdition
            : "",
          salePrice: saleprice,
        });
        if (data.product[i].product.originalPrice) {
          const originalPrice = data.product[i].product.originalPrice;
          const _originalPrice = originalPrice.match(/[\d\.]+/);
          if (_originalPrice) {
            cartDetails.products[i]["productPrice"] = _originalPrice[0];
          } else {
            cartDetails.products[i]["productPrice"] = originalPrice;
          }
        } else if (data.product[i].product.price) {
          cartDetails.products[i]["productPrice"] =
            data.product[i].product.price.value;
        } else {
          cartDetails.products[i]["productPrice"] = data.bundlePrice;
          cartDetails["bundlePrice"] = data.bundlePrice;
        }
      }
    } else {
      let saleprice;
      if (data.product.originalPrice) {
        saleprice = data.product.price.value;
      } else {
        saleprice = "";
      }

      cartDetails = {
        currencyCode: data.product.price
          ? data.product.price.currencyIso
          : baseSite.currencyCode,
        categoryList: pageUrlMainCat + " - " + pageUrlSubCat.toUpperCase(),
        products: [
          {
            productName: data.product.productDisplayName,
            productID: data.product.code,
            productPrice: "",
            productBrand: "Molton Brown",
            productCategory: pageUrlSubCat.toUpperCase(),
            productVariant: data.product.productVariant
              ? data.product.productVariant
              : "",
            productQuantity: data.quantity,
            size: data.product.size ? data.product.size : "",
            reviewRating: data.product.productAverageRating
              ? data.product.productAverageRating
              : "0",
            reviewsCount: data.product.reviewCount
              ? data.product.reviewCount
              : "0",
            saleStatus: data.product.originalPrice ? "True" : "False",
            stockLevel:
              data.product.stock.stockLevelStatus == "inStock"
                ? "True"
                : "False",
            productStockLevelNumber: data.product.stock.stockLevel
              ? data.product.stock.stockLevel
              : "",
            deleveryType: data.product.productEdition
              ? data.product.productEdition
              : "",
            salePrice: saleprice,
          },
        ],
      };
      if (data.product.originalPrice) {
        const originalPrice = data.product.originalPrice;
        const _originalPrice = originalPrice.match(/[\d\.]+/);
        if (_originalPrice) {
          cartDetails.products[0]["productPrice"] = _originalPrice[0];
        } else {
          cartDetails.products[0]["productPrice"] = originalPrice;
        }
      } else if (data.product.price) {
        cartDetails.products[0]["productPrice"] = data.product.price.value;
      } else {
        cartDetails.products[0]["productPrice"] = data.bundlePrice;
      }
    }
    this.gtmServ.gtmRemoveRichCart(cartDetails);
  }
  removeCartEntry(token, email, code, entry) {
    const baseSite = this.singletonServ.catalogVersion;
    this.basketServ.removeEntry(baseSite, token, email, code, entry).subscribe(
      (resp) => {
        this.showCartBlock = false;
        this.fetchBasket(token, email, code);
      },
      (err) => {
        this.fetchBasket(token, email, code);
      }
    );
  }

  onShowProduct(event, searchItem) {
    event.stopPropagation();
    this.toggleCartBox = false;
    const url = "/store" + searchItem.product.url.replace("/p/", "/");
    if (event.ctrlKey && event.which === 1) {
      window.open(url);
    } else {
      this.router.navigate([url]);
    }
  }

  onHoverCartIcon(event) {
    event.stopPropagation();
    this.toggleCartBox = true;
  }
  onleaveCart(event) {
    event.stopPropagation();
    this.toggleCartBox = false;
    this.showCartBlock = false;
  }

  /*cart data construction */

  nestedCopy(array) {
    return JSON.parse(JSON.stringify(array));
  }

  getCartDetail(data) {
    const that = this;
    const baseSite = this.singletonServ.catalogVersion;
    this.singletonServ.sendMessage({ updateBasketEntry: true, cartObj: data });
    const cart = this.singletonServ.setupEntryStream(data);
    const _copyCart = this.nestedCopy(data);
    this.animate = true;
    this.cart = cart;
    // for Emarsys
    this.setProductQuantity(this.cart.entries);
    if(this.isEmarsysEnabled) {
      this.webExtend.cartItems(this.cart.entries);
      this.webExtend.webExtendAddToCart();
    }
    if(this.cart.totalUnitCount != null && this.cart.totalUnitCount != undefined) {
      this.singletonServ.sendMessage({"cartTotalItems": cart.entries.length.toString(), "cartLoading":"false"})
    }
   //subscription section
    this.setSubscriptionTag(cart);

  if (this.getBrowserSessionData(baseSite.reg)) {
    const _user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
    if(_user.code && this.isAnnexCloudEnabled && this.isLoyaltyEnrolled){ // reg user and loyalty member
      this.basketServ.getBasketRewardPoint(baseSite,_user.token, _user.email, _user.code).subscribe(
        (resp: any) => {
          if(resp.cartPoints) this.loyaltyPoints = resp.cartPoints;
        })
    }
    else{ // reg user non loyalty member
      if(this.isAnnexCloudEnabled){ 
        if(this.cart && this.cart.potentialRewardPoints)
        this.loyaltyPoints =this.cart.potentialRewardPoints;
      }
    }
  }
    else{
    if(this.isAnnexCloudEnabled){ //guest user non loyalty member
      if(this.cart && this.cart.potentialRewardPoints)
      this.loyaltyPoints =this.cart.potentialRewardPoints;
    }
  }
    //this.setProductQuantity(this.cart.entries);
    this.singletonServ.cartObj = cart;
    if (this.cartMessage) {
      if (this.cartMessage.showCartPopUp) {
        if (this.cartMessage.code) {
          const _target = "#richCart" + this.cartMessage.code;
          this.showCartBlock = true;
          that.waitForEl(_target, 5);
        }
      }
    } else {
      this.singletonServ.sendMessage({
        retreiveSamples: true,
        cartObj: _copyCart,
      });
    }
    if (this.refreshBasket) {
      this.singletonServ.sendMessage({
        retreiveSamples: true,
        cartObj: _copyCart,
      });
      this.refreshBasket = false;
    }
    this.discardSubscription.emit();

    if (!this.singletonServ.sessionStarts) {
      if (this.getBrowserSessionData(baseSite.reg)) {
        const _regUser: any = JSON.parse(
          this.getBrowserSessionData(baseSite.reg)
        );
        const _timeout = new Date();
        _timeout.setMinutes(
          _timeout.getMinutes() + this.singletonServ.sessionTime
        );
        this.singletonServ.alarmTime = moment.utc(_timeout).valueOf();
        this.singletonServ.sessionStarts = true;
      } else if (this.getBrowserSessionData(baseSite.guest)) {
        const _guestUser: any = JSON.parse(
          this.getBrowserSessionData(baseSite.guest)
        );
        const _timeout = new Date();
        _timeout.setMinutes(
          _timeout.getMinutes() + this.singletonServ.sessionTime
        );
        this.singletonServ.alarmTime = moment.utc(_timeout).valueOf();
        this.singletonServ.sessionStarts = true;
      }
    }
  }
  setSubscriptionTag(cart){
    if(cart.entries.length > 0){
      setTimeout(()=>{
      cart.entries.forEach(item=>{
          let dynamicCode = "#product-codecart-"+item.product.code;
          if(document.querySelector(dynamicCode)) document.querySelector(dynamicCode).setAttribute('product', item.product.code);
      })
    }, 100);
    }
  }
  waitForEl(selector, count) {
    const that = this;
    if ($(selector).length) {
      this.showCartPopUp();
    } else {
      setTimeout(function () {
        if (!count) {
          count = 0;
        }
        count++;
        if (count < 10) {
          that.waitForEl(selector, count);
        } else {
          return;
        }
      }, 100);
    }
  }
  showCartPopUp() {
    const _target = "#richCart" + this.cartMessage.code;
    const elemnt = $(_target);
    if (elemnt) {
      if (elemnt.offset()) {
        this.singletonServ.scrollToElWithinC(".cart-list-container", elemnt);
      }
    }
    setTimeout(() => {
      this.showCartBlock = false;
    }, 3000);
  }
  getPixMixImage(entry) {
    if (entry.bundleTemplateId) {
      if (entry.bundleTemplateId.indexOf("6") != -1) {
        return "https://media.moltonbrown.co.uk/i/moltonbrown/PickMixTravel6_uk_pick-mix-travel-6_image_01?$smallImg$&amp;fmt=webp";
      } else if (entry.bundleTemplateId.indexOf("3") != -1) {
        return "https://media.moltonbrown.co.uk/i/moltonbrown/PickMixTravel3_uk_pick-mix-travel-3_image_01?$smallImg$&amp;fmt=webp";
      } else {
        return "https://media.moltonbrown.co.uk/i/moltonbrown/FFDummySKU_uk_Fragrance-Finder-Samples_image_01?$thImg$&fmt=webp";
      }
    }
    return "https://media.moltonbrown.co.uk/i/moltonbrown/PickMixTravel3_uk_pick-mix-travel-3_image_01?$smallImg$&amp;fmt=webp";
  }
  getPixMixImageJpg(entry) {
    if (entry.bundleTemplateId) {
      if (entry.bundleTemplateId.indexOf("6") != -1) {
        return "https://media.moltonbrown.co.uk/i/moltonbrown/PickMixTravel6_uk_pick-mix-travel-6_image_01?$smallImg$&amp;fmt=jpg";
      } else if (entry.bundleTemplateId.indexOf("3") != -1) {
        return "https://media.moltonbrown.co.uk/i/moltonbrown/PickMixTravel3_uk_pick-mix-travel-3_image_01?$smallImg$&amp;fmt=jpg";
      } else {
        return "https://media.moltonbrown.co.uk/i/moltonbrown/FFDummySKU_uk_Fragrance-Finder-Samples_image_01?$thImg$&fmt=jpg";
      }
    }
    return "https://media.moltonbrown.co.uk/i/moltonbrown/PickMixTravel3_uk_pick-mix-travel-3_image_01?$smallImg$&amp;fmt=jpg";
  }
  getBundleContent(text) {
    const baseSite = this.singletonServ.catalogVersion;
    if (baseSite) {
      if (baseSite.isoCode == "DE") {
        this.picknmix6 = "Pick & Mix für die Reise x 6";
        this.picknmix3 = "Pick & Mix für die Reise x 3";
      } else {
        this.picknmix6 = "Pick & Mix Travel x 6";
        this.picknmix3 = "Pick & Mix Travel x 3";
      }
    }
    if (text.bundleTemplateId.indexOf("6") != -1) {
      return this.picknmix6;
    } else if (text.bundleTemplateId.indexOf("3") != -1) {
      return this.picknmix3;
    } else if (text.pickAgain) {
      return text.bundleTitleName;
    } else {
      return "Fragrance Finder Samples";
    }
    return this.picknmix3;
  }

  getTotalPrice(entry) {
    if (!entry.giveAway) {
      if (entry.product.price) {
        if (entry.product.price.value != 0) {
          if (entry.product.originalPrice) {
            if (entry.pickAgain && entry.promotionMessage) {
              return entry.discountedProductPrice;
            } else {
              return entry.product.price.formattedValue;
            }
          } else if (entry.promotionMessage) {
            return entry.discountedProductPrice;
          }
          return entry.product.price.formattedValue;
        } else {
          if (entry.promotionMessage) {
            return entry.discountedProductPrice;
          }
          return this.freeMsg;
        }
      }
    } else {
      if (entry.promotionMessage) {
        return entry.discountedProductPrice;
      }
      return this.freeMsg;
    }
  }
  getBundlePrice(data) {
    const _bundlePrice = data.product.filter((obj) => {
     if(obj && obj.product) return obj.product.isSample;
    });
    if (_bundlePrice.length != 0) {
      return _bundlePrice[0]["basePrice"]["formattedValue"];
    } else if (data.pickAgain) {
      return this.freeMsg;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  getCartCount() {
    let sum = 0;
    this.totalAmount = 0;
    if (this.cart) {
      if (this.cart.totalItems != 0) {
        for (let i = 0; i < this.cart["entries"].length; i++) {
          if (this.cart["entries"][i]["pickAgain"]) {
            // if (this.cart["entries"][i]["product"]["isSample"]) {
            sum += this.cart["entries"][i]["quantity"];
            //  }
          } else {
            if (!this.cart["entries"][i]["product"]["isSample"]) {
              sum += this.cart["entries"][i]["quantity"];
            }
          }
        }
      }
    }
    return sum;
  }
  getHidden(entry) {
    if (entry.pickAgain) {
      if (entry.product.isSample) {
        return false;
      }
    } else {
      if (entry.product.isSample) {
        return true;
      }
    }
    return false;
  }
  enableEntryBlock(cart: any) {
    if (cart.entries.length != 0) {
      const _pickAgain = _.filter(cart.entries, (entry) => {
        if (!entry["product"].length) {
          if (!entry.pickAgain) {
            if (entry["product"].isSample) {
              return entry;
            }
          }
        }
      });
      if (_pickAgain.length != 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  getBrowserSessionData(key) {
    if (isPlatformBrowser(this.platformId)) {
      return this.singletonServ.getStoreData(key);
    }
  }
  setBrowserSessionData(key, data) {
    if (isPlatformBrowser(this.platformId)) {
      this.singletonServ.setStoreData(key, data);
    }
  }
  signoutCredentials() {
    this.cart = undefined;
    this.singletonServ.cartObj = undefined;
    //this.cartCountElement.nativeElement.value = "0";
    this.router.navigate(["store", "myacc", "mbLogin"]);
    //
    this.isLoyaltyEnrolled = false; //reset flag for loyalty enrolled reg user
  }
  clearCartCount() {
    this.cart = undefined;
    this.singletonServ.cartObj = undefined;
    this.cartCountElement.nativeElement.value = "0";
  }
  setProductQuantity(products) {
    if (products.length) {
      products.forEach((entry) => {
        if (entry.product.stock && entry.product.stock.stockLevel) {
          let stockLevel = entry.product.stock.stockLevel;
          if (stockLevel != 0 && stockLevel < 10)
            this.productQty[entry.product.code] = Array.from(
              { length: stockLevel },
              (_, i) => i + 1
            );
          else if (stockLevel != 0)
            this.productQty[entry.product.code] = Array.from(
              { length: 10 },
              (_, i) => i + 1
            );
        } else if(entry.bundleTitleName){ // Pick and Mix quantity display
          if(entry.quantity<1) {
            entry.quantity = 1;
          }
          this.productQty[entry.bundleNo] = Array.from(
            { length: 10 },
            (_, i) => i + 1
          );
        }
      });
    }
  }

  // change quantity calls from basket page
  onChangeQuant(event,data) {
    this.refreshBasket=true;
    const baseSite = this.singletonServ.catalogVersion;
    const _count = event.target.value;
    const quantity=_count;
    if (this.getBrowserSessionData(baseSite.reg)) {
      const user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
      if (!user.code) {
      } else {
        if(data.isBundle){
          this.updatebundleItem(baseSite,user.token, user.email, user.code, data,quantity);
        }else{
          this.updateBasket(baseSite,user.token, user.email, user.code, data, _count);
        }
      }
    } else if (this.getBrowserSessionData(baseSite.guest)) {
      const user = JSON.parse(this.getBrowserSessionData(baseSite.guest));
      if(data.isBundle){
        this.updatebundleItem(baseSite,user.token, "anonymous", user.guid, data,quantity);
      }else{
        this.updateBasket(baseSite,user.token, "anonymous", user.guid, data, _count);
      }
    }
  }

  updatebundleItem(baseSite,token, email, code, item,quantity){
    let bundleNo=item.bundleNo;
    let _codes=[];
    if(item.bundleTemplateId && item.bundleTemplateId.includes('GiftBuilder')){
      item.product.forEach(eachItem=>{
        if(eachItem.eachItemsInGiftBuilder){
          for(let i=0;i<eachItem.eachItemsInGiftBuilder.length;i++){
            _codes.push(eachItem.product.code);
          }
        } else if (eachItem.product.isSample && eachItem.product.code) { _codes.push(eachItem.product.code)}  // push dummy sku product code 
      });
    }else{
      let prevBundlQty = 1;
      if(item.product && item.product.length > 1){
        prevBundlQty = item.product[item.product.length - 1].quantity;
      }
      item.product.map(product => {
        let qty=product.quantity/prevBundlQty;
          for(let k=0;k<qty;k++){
            _codes.push(product.product.code); 
          }   
      });
    }
    const body={
      "productcodes":_codes
     }
    this.updateBundleItem(baseSite,token,email,code,body, bundleNo,quantity);
  }
  updateBundleItem(baseSite,token,email,code,body, bundleNo,quantity){
  this.basketServ.updateBundleItem(baseSite,token,email,code,body, bundleNo,quantity).subscribe((response)=>{
      
    //this.getCartDetail(response);
    this.retrieveCartDetails();
   
    },err=>{
     if(err.error){
      if(err.error["errors"]){
        if(err.error["errors"][0]){
          if(err.error["errors"][0]['type']== "InvalidTokenError") {
            if(!baseSite.csAgent){
            this.basketServ.generateCartToken(baseSite).subscribe(
              (resp:any) => {
                const _reg=(email!='anonymous')?true:false;
                if(_reg){
                  const user=JSON.parse(this.getBrowserSessionData(baseSite.reg));
                  user.token= resp["access_token"];
                  this.setBrowserSessionData(baseSite.reg, JSON.stringify(user));
                  this.updateBundleItem(baseSite,resp["access_token"],email,code,body, bundleNo,quantity); 
                }else{
                  const user=JSON.parse(this.getBrowserSessionData(baseSite.guest));
                  user.token= resp["access_token"];
                  this.setBrowserSessionData(baseSite.guest, JSON.stringify(user));
                  this.updateBundleItem(baseSite,resp["access_token"],email,code,body, bundleNo,quantity); 
                }
                   
              });
             }else{
               this.singletonServ.sendMessage({invalidAgentToken:true});
             }
          }else{
            this.refreshBasket=false;
          }
        }
        }
       }
    });
  }

  updateBasket(baseSite,token, email, code, item, count) {
    const entry = item["entryNumber"];
    const productObj = {
      product: { code: item["product"]["code"] },
      quantity: parseInt(count)
    };
    // subscription check
    if(this.getBrowserSessionData("OG_STATE")){
      const subscriptionInfo = JSON.parse(this.getBrowserSessionData("OG_STATE"));
      const _optin = subscriptionInfo.optedin;
      const _optedOut = subscriptionInfo.optedout;
      if(_optin.length > 0){
        _optin.forEach(element => {
          if(element.id == item["product"]["code"]){
            productObj["isSubscriptionEntry"] = true;
            productObj["subscriptionFrequencyNumber"] = element?.frequency?.split('_')[0] // selected frequency
            productObj["subscriptionFrequencyPeriod"] = element?.frequency?.split('_')[1] // range of freq.
            productObj["offer"] = '' // once promotion is avaible to be updated
          }
        })
      }
      if (_optedOut.length > 0){
        _optedOut.forEach(element => {
          if(element.id == item["product"]["code"]){
            productObj["isSubscriptionEntry"] = false;
          }
        })
      }
      console.log("og-state"+ JSON.stringify(subscriptionInfo));
    }
    this.updateCartEntry(baseSite,token, email, code, productObj, entry);
  }
 
 updateCartEntry(baseSite,token, email, code, productObj, entry){
  this.basketServ.updateEntry(baseSite,token, email, code, productObj, entry).subscribe((response:any) => {
      if(response.errorMsg){
        //this.outofstockentryMsg=response.errorMsg;
        this.refreshBasket=false;
      }else{
       //this.outofstockentryMsg=undefined;
       this.getCartDetail(response);
      }
    },(err:any) => { 
      if(err.error){
        if(err.error["errors"]){
          if(err.error["errors"][0]){
            if(err.error["errors"][0]['type']== "InvalidTokenError") {
              if(!baseSite.csAgent){
              this.basketServ.generateCartToken(baseSite).subscribe(
                (resp:any) => {
                  const _reg=(email!='anonymous')?true:false;
                  if(_reg){
                    const user=JSON.parse(this.getBrowserSessionData(baseSite.reg));
                    user.token= resp["access_token"];
                    this.setBrowserSessionData(baseSite.reg, JSON.stringify(user));
                    this.updateCartEntry(baseSite,resp["access_token"], email, code, productObj, entry); 
                  }else{
                    const user=JSON.parse(this.getBrowserSessionData(baseSite.guest));
                    user.token= resp["access_token"];
                    this.setBrowserSessionData(baseSite.guest, JSON.stringify(user));
                    this.updateCartEntry(baseSite,resp["access_token"], email, code, productObj, entry); 
                  }
                      
                });
               }else{
                 this.singletonServ.sendMessage({invalidAgentToken:true});
               }
            } 
            // else if(err.error["errors"][0]['type']== "CommerceCartModificationError") {
            //  this.outofstockentryMsg=err.error["errors"][0]['message'];
            //  this.refreshBasket=false;
            // } else if(err.error["errors"][0]['type']== "InsufficientStockError") {
            //  this.outofstockentryMsg=err.error["errors"][0]['message'];
            //  this.refreshBasket=false;
            // } 
            else {
              this.refreshBasket=false;
            }
          }
          }
         }
    }
  );
 }
 getAndSetTokenForGiftBoxDetails(baseSite){
  let user: any = {};
  if(this.singletonServ.getStoreData(baseSite.reg)){
    user = JSON.parse(this.singletonServ.getStoreData(baseSite.reg));
  }else if(this.singletonServ.getStoreData(baseSite.guest)){
    user = JSON.parse(this.singletonServ.getStoreData(baseSite.guest));
  }else{
    this.profileServ.generateToken(baseSite).subscribe((token)=>{
      const tokenId = token["access_token"];
      user['token']=tokenId;
      this.profileServ.generateCartId(baseSite,tokenId,'anonymous').subscribe(data => {
        user["guid"]=data["guid"];
        user['code']=data['code'];
        this.singletonServ.setStoreData(baseSite.guest,JSON.stringify(user));
      },error=>{});
      if(user.token)this.fetchGiftBoxDetails(baseSite,user.token);
  });
  }
  if(user.token && this.getBrowserSessionData("GBBoxDetails") == undefined) this.fetchGiftBoxDetails(baseSite,user.token);
}

fetchGiftBoxDetails(baseSite, token){
  this.profileServ.giftBoxDetails(baseSite, token).subscribe(resp=>{
    this.giftBoxDetails = resp;
    this.setBrowserSessionData("GBBoxDetails",   JSON.stringify(this.giftBoxDetails));
    this.giftBoxDtls = JSON.parse(this.getBrowserSessionData("GBBoxDetails"));
  },
  error=>{});
}

signinCredentials(){
  //check if reg user is loyaltyEnrolled
  const baseSite = this.singletonServ.catalogVersion;
  if(this.isAnnexCloudEnabled){ // reg user loyalty member or not
    setTimeout(()=>{ 
      const _user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
    this.profileServ.getUserData(baseSite, _user.token, _user.email).subscribe((res) => {
      if (res && res['annexcloudOptIn']) {
       this.isLoyaltyEnrolled = res['annexcloudOptIn'];
       if( this.isLoyaltyEnrolled){
       this.basketServ.getBasketRewardPoint(baseSite, _user.token, _user.email, _user.code).subscribe(
        (resp: any) => {
          if(resp.cartPoints) this.loyaltyPoints = resp.cartPoints;
        })
      }
  }
});
if(_user.code && this.isAnnexCloudEnabled && this.isLoyaltyEnrolled){ // reg user and loyalty member
  this.basketServ.getBasketRewardPoint(baseSite,_user.token, _user.email, _user.code).subscribe(
    (resp: any) => {
      if(resp.cartPoints) this.loyaltyPoints = resp.cartPoints;
    })
}
else{ // reg user non loyalty member
  if(this.isAnnexCloudEnabled){ 
    if(this.cart && this.cart.potentialRewardPoints)
    this.loyaltyPoints =this.cart.potentialRewardPoints;
  }
}

},3000);
  }
}
}