// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment :{
  production:boolean,
  environmentName:string,
  AUTHRISATION_PATH:string,
  CS_AUTHRISATION_PATH:string,
  CS_CUSTOMER:string,
  API_PATH:string,
  songBird:string,
  paypal:string,
  freshrelevance:string,
  live:boolean,
  countryJsonData:Array<any>,
  alternateURLs:Array<any>,
  adyenKeys : {
    environment: string, 
    clientKey: string,
  },
  subscriptionKeys : {
    environment: string, 
    enviornmentUrl: string,
    clientKey: string,
  }
} = {
  production: false,
  environmentName: 'Development',
  AUTHRISATION_PATH:'https://api.cxur-kaocorpor1-d5-public.model-t.cc.commerce.ondemand.com/authorizationserver/',
  CS_AUTHRISATION_PATH:'https://api.cxur-kaocorpor1-d5-public.model-t.cc.commerce.ondemand.com/authorizationserver/',
  CS_CUSTOMER:'https://api.cxur-kaocorpor1-d5-public.model-t.cc.commerce.ondemand.com/',
  API_PATH:'https://api.cxur-kaocorpor1-d5-public.model-t.cc.commerce.ondemand.com/kaowebservices/v2/:baseSite',
  songBird:'https://songbirdstag.cardinalcommerce.com/cardinalcruise/v1/songbird.js',
  paypal:'https://www.sandbox.paypal.com/cgi-bin/webscr?cmd=_express-checkout&token=',
  freshrelevance:'//d81mfvml8p5ml.cloudfront.net/yre05t09.js',
  live:false,
  countryJsonData:[
  {
    countryCode: "gb",
    currencyCode:'GBP',
    countryImg: "assets/imgs/icon_country_german.gif",
    countryName: "UK",
    name:"United Kingdom",
    german_trans:"Vereinigtes Königreich",
    catalogversionId: "moltonbrown-gb",
    locale: "en-GB",
    reg:"reg-gb",
    guest:"guest-gb",
    regOrder:"reg-gb-order",
    guestOrder:"guest-gb-order",
    isoCode:"GB",
    store:"store_gb",
    lngCode:'en',
    guestPickMix:'guest-pick-mix-gb',
    regPickMix:'reg-pick-mix-gb',
    bv_locale:'en_gb',
    bv:'https://apps.bazaarvoice.com/deployments/moltonbrown-en_gb/uat/staging/en_GB/bv.js',
    geoPoint:{
      latitude:51.509865,
      longitude:-0.118092
    },
    query:"https://www.devh.moltonbrown.co.uk/store/index",
    hostname:"www.devh.moltonbrown.co.uk",
    serverName:"devh.moltonbrown.co.uk",
    csAgent:false,
    agentToken:undefined,
    csCustomer:'gbCustomer',
    csAssistant:'gbAgent',
    personaId:'GBPERSONA',
    pciOptimizely:'https://cdn-pci.optimizely.com/js/20496642582.js',
    gtmsrc:'https://www.googletagmanager.com/ns.html?id=GTM-56ZL49',
    convsrc:'//cdn-3.convertexperiments.com/js/10023594-10024988.js',
    gtmOL:'gtm-gb-val',
    live:false,
    fr_script:'//d81mfvml8p5ml.cloudfront.net/yre05t09.js'
  },
  {
    countryCode: "US",
    currencyCode:'USD',
    countryImg: "assets/imgs/livedata/USflagOver.gif",
    countryName: "USA",
    name:"United States",
    german_trans:"Vereinigte Staaten von Amerika",
    catalogversionId: "moltonbrown-us",
    locale: "en-US",
    reg:"reg-us",
    guest:"guest-us",
    regOrder:"reg-us-order",
    guestOrder:"guest-us-order",
    isoCode:"US",
    store:"store_us",
    lngCode:'us',
    guestPickMix:'guest-pick-mix-us',
    regPickMix:'reg-pick-mix-us',
    latitude:'51.50853',
    longitude:'-0.12574',
    bv_locale:'en_US',
    bv:'https://apps.bazaarvoice.com/deployments/moltonbrown/uat/staging/en_US/bv.js',
    geoPoint:{
      latitude:51.50853,
      longitude:-0.12574
    },
    query:"https://www.devh.moltonbrown.com/store/index?country_store=us",
    hostname:"www.devh.moltonbrown.com",
    serverName:"devh.moltonbrown.com",
    csAgent:false,
    agentToken:undefined,
    csCustomer:'usCustomer',
    csAssistant:'usAgent',
    personaId:'USPERSONA',
    pciOptimizely:'https://cdn-pci.optimizely.com/js/20496642582.js',
    gtmsrc:'https://www.googletagmanager.com/ns.html?id=GTM-WT2QCD',
    convsrc:'//cdn-3.convertexperiments.com/js/10023594-10024989.js',
    gtmOL:'gtm-us-val',
    live:false,
    fr_script:'//d81mfvml8p5ml.cloudfront.net/yre05t09.js'
  },
  {
    countryCode: "eu",
    currencyCode:'EUR',
    countryImg: "assets/imgs/icon_country_europe.gif",
    countryName: "Europe",
    name:"Europe",
    german_trans:"Europa",
    catalogversionId: "moltonbrown-eu",
    locale: "en-CX",    
    reg:"reg-eu",
    guest:"guest-eu",
    regOrder:"reg-eu-order",
    guestOrder:"guest-eu-order",
    isoCode:"EU",
    store:"store_eu",
    lngCode:'eu',
    guestPickMix:'guest-pick-mix-eu',
    regPickMix:'reg-pick-mix-eu',
    latitude:'51.50853',
    longitude:'-0.12574',
    bv_locale:'en_EU',
    bv:'https://apps.bazaarvoice.com/deployments/moltonbrown-eu/uat/staging/en_EU/bv.js',
    geoPoint:{
      latitude:51.50853,
      longitude:-0.12574
    },
    query:"https://www.devh.moltonbrown.eu/store/index?country_store=eu",
    hostname:"www.devh.moltonbrown.eu",
    serverName:"devh.moltonbrown.eu",
    csAgent:false,
    agentToken:undefined,
    csCustomer:'euCustomer',
    csAssistant:'euAgent',
    personaId:'EUPERSONA',
    pciOptimizely:'https://cdn-pci.optimizely.com/js/20496642582.js',
    gtmsrc:'https://www.googletagmanager.com/ns.html?id=GTM-PRF4JQM',
    convsrc:'//cdn-3.convertexperiments.com/js/10023594-10024990.js',
    gtmOL:'gtm-eu-val',
    live:false,
    fr_script:'//d81mfvml8p5ml.cloudfront.net/yre05t09.js'
  },
  {
    countryCode: "de",
    currencyCode:'EUR',
    countryImg: "assets/imgs/icon_country_german.gif",
    countryName: "Germany",
    name:"Germany",
    german_trans:"Germany",
    catalogversionId: "moltonbrown-de",
    locale: "de-DE",    
    reg:"reg-de",
    guest:"guest-de",
    regOrder:"reg-de-order",
    guestOrder:"guest-de-order",
    isoCode:"DE",
    store:"store_de",
    lngCode:'de',
    guestPickMix:'guest-pick-mix-de',
    regPickMix:'reg-pick-mix-de',
    bv_locale:'de_DE',
    bv:'https://apps.bazaarvoice.com/deployments/moltonbrown-de_de/main_site/staging/de_DE/bv.js',
    geoPoint:{
      latitude:51.50853,
      longitude:-0.12574
    },
    query:"https://www.devh.moltonbrown.de/store/index?country_store=de",
    hostname:"www.devh.moltonbrown.de",
    serverName:"devh.moltonbrown.de",
    csAgent:false,
    agentToken:undefined,
    csCustomer:'deCustomer',
    csAssistant:'deAgent',
    personaId:'DEPERSONA',
    pciOptimizely:'https://cdn-pci.optimizely.com/js/20496642582.js',
    gtmsrc:'https://www.googletagmanager.com/ns.html?id=GTM-TBRSFX6',
    convsrc:'//cdn-3.convertexperiments.com/js/10023594-10024991.js',
    gtmOL:'gtm-De-val',
    live:false,
    fr_script:'//d81mfvml8p5ml.cloudfront.net/yre05t09.js'
  },
  {
    countryCode: "jp",
    countryImg: "assets/imgs/icon_country_japan.gif",
    countryName: "Japan",
    name:"Japan",
    german_trans:"Japan",
    catalogversionId: "moltonbrown-jp",
    locale: "jp-JP",    
    reg:"reg-jp",
    guest:"guest-jp",
    isoCode:"JP",
    store:"store_jp",
    lngCode:'jp',
    guestPickMix:'guest-pick-mix-jp',
    regPickMix:'reg-pick-mix-jp',
    bv_locale:'en_EU',
    bv:'https://apps.bazaarvoice.com/deployments/moltonbrown-eu/uat/staging/en_EU/bv.js',
    geoPoint:{
      latitude:51.50853,
      longitude:-0.12574
    },
    query:"http://www.moltonbrown.co.jp/?country=store_jp",
    live:false,
    fr_script:'//d81mfvml8p5ml.cloudfront.net/yre05t09.js'
  }
],
alternateURLs:[
  { country: "x-default", url: "https://www.devh.moltonbrown.co.uk" },
  { country: "en-gb", url: "https://www.devh.moltonbrown.co.uk" },
  { country: "en-us", url: "https://www.devh.moltonbrown.com" },
  { country: "en-ca", url: "https://www.devh.moltonbrown.com" },
  { country: "en-de", url: "https://www.devh.moltonbrown.de" },
  { country: "de-de", url: "https://www.devh.moltonbrown.de" },
  { country: "en-eu", url: "https://www.devh.moltonbrown.eu" },
  { country: "de-at", url: "https://www.devh.moltonbrown.de" },
  { country: "en-ie", url: "https://www.devh.moltonbrown.eu" },
  { country: "ga-ie", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-be", url: "https://www.devh.moltonbrown.eu" },
  { country: "be-be", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-nl", url: "https://www.devh.moltonbrown.eu" },
  { country: "nl-nl", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-lu", url: "https://www.devh.moltonbrown.eu" },
  { country: "lb-lu", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-ee", url: "https://www.devh.moltonbrown.eu" },
  { country: "et-ee", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-at", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-sk", url: "https://www.devh.moltonbrown.eu" },
  { country: "sk-sk", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-pt", url: "https://www.devh.moltonbrown.eu" },
  { country: "pt-pt", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-fi", url: "https://www.devh.moltonbrown.eu" },
  { country: "fi-fi", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-si", url: "https://www.devh.moltonbrown.eu" },
  { country: "sl-si", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-mt", url: "https://www.devh.moltonbrown.eu" },
  { country: "mt-mt", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-dk", url: "https://www.devh.moltonbrown.eu" },
  { country: "dk-dk", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-bg", url: "https://www.devh.moltonbrown.eu" },
  { country: "bg-bg", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-cz", url: "https://www.devh.moltonbrown.eu" },
  { country: "cs-cz", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-hr", url: "https://www.devh.moltonbrown.eu" },
  { country: "hr-hr", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-ad", url: "https://www.devh.moltonbrown.eu" },
  { country: "ca-ad", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-sm", url: "https://www.devh.moltonbrown.eu" },
  { country: "it-sm", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-ch", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-li", url: "https://www.devh.moltonbrown.eu" },
  { country: "it-li", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-lt", url: "https://www.devh.moltonbrown.eu" },
  { country: "lt-lt", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-is", url: "https://www.devh.moltonbrown.eu" },
  { country: "is-is", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-al", url: "https://www.devh.moltonbrown.eu" },
  { country: "sq-al", url: "https://www.devh.moltonbrown.eu" },
  { country: "no-no", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-no", url: "https://www.devh.moltonbrown.eu" },
  { country: "fr-mc", url: "https://www.devh.moltonbrown.eu" },
  { country: "en-mc", url: "https://www.devh.moltonbrown.eu" }
],
adyenKeys : {
  environment: 'test', // Change to 'live' for the live environment.
  clientKey: 'test_DAZZFSAUSNB5XMVOPRHGW3YVN4YAL4EF', // Public key used for client-side authentication: https://docs.adyen.com/development-resources/client-side-authentication,
},
subscriptionKeys : {
  environment: 'staging', // Change to 'live' for the live environment.
  enviornmentUrl: '.moltonbrown.co.uk',
  clientKey: 'L-!+f5*%5d5RRXobmX{QmSea$s_NI#VB', // Public Hash Key used for authentication,
}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
