import {
  InjectionToken,
  NgModule
} from '@angular/core';
import {
  Routes,
  RouterModule,
  UrlSegment,
  ActivatedRouteSnapshot
} from '@angular/router';
import {FragranceComponent} from "./fragrance/fragrance.component";
import {FfLandingpageComponent} from "./ff-landingpage/ff-landingpage.component";
import {PreloadService} from "./services/pre-loadstartegy.service";
import { CovidOfferPageComponent } from './store/covid-offer-page/covid-offer-page.component';
import { CandleCareTipsComponent } from './lifestyle/candle-care-tips/candle-care-tips.component';
import { PayWithKlarnaComponent } from './content-pages/pay-with-klarna/pay-with-klarna.component';
import {RouteRedirectService} from "./services/route-redirect.service";
import { BathBeautyOutletComponent } from './content-pages/bath-beauty-outlet/bath-beauty-outlet.component';
import { AboutUsComponent } from './content-pages/about-us/about-us.component';
import { ExclusiveGamesComponent } from './exclusive-games/exclusive-games.component';
import { ExclusiveGameGuard } from './guards/exclusive-game.guard';
import { GiftBuilderModule } from './gift-builder/gift-builder.module';
import {SharedWishlistComponent} from './shared-wishlist/shared-wishlist.component'
import { RootCategoryLpComponent } from './new-landingPages-2023/root-category/root-category-lp.component';
import { PrincesTrustComponent } from './new-landingPages-2023/princes-trust/princes-trust.component';
const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
const routes: Routes = [{
    path: '',
    redirectTo: 'store/index',
    pathMatch: 'full'
  },
  { path: 'store/hotel-amenities/hotel-products/luxury-collection-one', redirectTo: 'store/hotel-amenities/hotel-products/luxury-collection', pathMatch: 'full' },
  { path: 'store/hotel-amenities/hotel-products/turndown-service', redirectTo: 'store/hotel-amenities/hotel-products/the-accessories-and-turndown-collections', pathMatch: 'full' },
  { path: 'store', redirectTo: 'store/index', pathMatch: 'full', data: { catalogId: 'moltonbrown-gb' } },
  { path: 'store/index', loadChildren: () => import('./amp-homepage/amp-homepage.module').then(m => m.AmpHomePageModule),runGuardsAndResolvers: 'always',data: { preload: true }  },
  { path:'store/bireport', loadChildren:() => import('./bi-report/bi-report.module').then(m => m.BireportModule)},
  { 
    path: 'store/404',
    pathMatch: 'full',
    loadChildren: () => import('./not-found/not-found.module').then(m => m.Pagenotfoundmodule)
  },
  { path: 'covid-offer',component: CovidOfferPageComponent},
  { path:'store/shared/wish-lists/:wishlistID',component:SharedWishlistComponent},
  {
    path: 'store/storeportal',
    loadChildren: () => import('./store-portal/store-portal.module').then(m => m.StorePortalRouteModule),
    data: {
      header: false
    }
  },
  {
    path:'store/fragrance/fragrance-finder/displayFFProducts',
    loadChildren:() => import('./ff-display-products/ff-display-products.module').then(m => m.FragranceFinderDisplayProductsModule),
    pathMatch: 'full',
    data: { preload: true }
 },
  {
    path:'store/fragrance/fragrance-finder/:catId',
    loadChildren:() => import('./fragrance-finder-version2/fragrance-finder-version2.module').then(m => m.FragranceFinderVersion2Module),
    data: { preload: true }
  },

   {
    path: 'store',
    loadChildren: () => import('./store/store.module').then(m => m.StorePageModule),
    data: {
      header: true
    }
  },
  { path:'store/journal/lifestyle/candle-care-tips',component:CandleCareTipsComponent},
  { path:'store/journal/lifestyle/candle-care-tips/.',component:CandleCareTipsComponent},
  { path:'store/pay-with-klarna',component:PayWithKlarnaComponent,canActivate:[RouteRedirectService],data:{siteID:[{isocode:'DE',extension:'.de'}]}},
  { path:'store/pay-with-klarna/.',component:PayWithKlarnaComponent,canActivate:[RouteRedirectService],data:{siteID:[{isocode:'DE',extension:'.de'}]}},
  { path: 'store/bath-beauty-outlet', data: { siteID: [{ isocode: 'GB', extension: '.co.uk' }, { isocode: 'EU', extension: '.eu' }] }, redirectTo: '/store/index' },
  { path: 'store/bath-beauty-outlet/.', data: { siteID: [{ isocode: 'GB', extension: '.co.uk' }, { isocode: 'EU', extension: '.eu' }] }, redirectTo: '/store/index' },
  { path:'store/about-us',component:AboutUsComponent},
  { path:'store/about-us/.',component:AboutUsComponent},
  { path:'store/rewards',loadChildren:() => import('./content-pages/rewards-club/rewards-club.module').then(m => m.RewardsClubModule),canActivate:[RouteRedirectService],data:{siteID:[{isocode:'GB',extension:'.co.uk'}]}},
  { path:'store/rewards/.',loadChildren:() => import('./content-pages/rewards-club/rewards-club.module').then(m => m.RewardsClubModule),canActivate:[RouteRedirectService],data:{siteID:[{isocode:'GB',extension:'.co.uk'}]}},
  { path: 'store/:categoryname/pick-mix-travel/:productid', loadChildren: () => import('./pick-mix-travel/pick-mix-travel.module').then(m => m.PickMixTravelModule)},
  { path: 'store/:categoryname/pick-mix-travel/:productid/.', loadChildren: () => import('./pick-mix-travel/pick-mix-travel.module').then(m => m.PickMixTravelModule)},
  { path: 'store/gifts/gift-builder', loadChildren: () => import('./gift-builder/gift-builder.module').then(m => m.GiftBuilderModule)},
  { path: 'store/:productname/:categoryId', loadChildren: () => import('./categorylanding-page/categorylanding-page.module').then(m => m.CategorylandingPageModule),runGuardsAndResolvers: 'always', data: { preload: true } },
  { path: 'store/:productname/:categoryId/.', loadChildren: () => import('./categorylanding-page/categorylanding-page.module').then(m => m.CategorylandingPageModule),runGuardsAndResolvers: 'always' },
  { path: 'store/:categoryname/:productname/:productid', loadChildren: () => import('./categorylanding-page/categorylanding-page.module').then(m => m.CategorylandingPageModule),runGuardsAndResolvers: 'always'},
  { path: 'store/:categoryname/:productname/:productid/.', loadChildren: () => import('./categorylanding-page/categorylanding-page.module').then(m => m.CategorylandingPageModule),runGuardsAndResolvers: 'always' },
  { path: 'store/:categoryname/:productname/:itemname/:itemid',loadChildren: () => import('./categorylanding-page/categorylanding-page.module').then(m => m.CategorylandingPageModule),runGuardsAndResolvers: 'always'},
  { path: 'store/:categoryname/:productname/:itemname/:itemid/.',loadChildren: () => import('./categorylanding-page/categorylanding-page.module').then(m => m.CategorylandingPageModule),runGuardsAndResolvers: 'always'},
  { path: 'store/browse/productDetailSingleSku', loadChildren:() => import('./produtDetailView/product-detail.module').then(m => m.ProductDetailPageModule)},  
  { path: 'store/product/:itemid',  loadChildren: () => import('./categorylanding-page/categorylanding-page.module').then(m => m.CategorylandingPageModule),runGuardsAndResolvers: 'always'}, 
  { path:'store/fragrance',component:RootCategoryLpComponent},
  { path:'store/fragrance/.',component:RootCategoryLpComponent},
  { path:'store/fragrance-2',component:RootCategoryLpComponent},
  { path:'store/fragrance-2/.',component:RootCategoryLpComponent},
  { path:'store/christmas-gift-collection',component:RootCategoryLpComponent},
  { path:'store/christmas-gift-collection/.',component:RootCategoryLpComponent},
  { path:'store/christmas-gift-collection-2',component:RootCategoryLpComponent},
  { path:'store/christmas-gift-collection-2/.',component:RootCategoryLpComponent},
  { path:'store/princes-trust-charity-partnership',component:PrincesTrustComponent,canActivate:[RouteRedirectService],data:{siteID:[{isocode:'GB',extension:'.co.uk'}]}},
  { path:'store/princes-trust-charity-partnership/.',component:PrincesTrustComponent,canActivate:[RouteRedirectService],data:{siteID:[{isocode:'GB',extension:'.co.uk'}]}},
  {
    path:'store/storeportal',
    loadChildren:() => import('./store-portal/store-portal.module').then(m => m.StorePortalRouteModule),
    data: {
      header: false
    }
  },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout-cart/checkout-cart.module').then(m => m.CheckoutCartPageModule),
    data: {
      header: false
    },
    runGuardsAndResolvers: 'always'
  },
  { path: 'checkout/mbOrderConfirmResponse', loadChildren: () => import('./checkout-cart/order-confirmation/order-confirmation.module').then(m => m.OrderConfirmationModule)},
   
  {
    path: 'organisation',
    loadChildren: () => import('./organisation/organisation.module').then(m => m.OrgainisationRouteModule),
    data: {
      header: true
    }
  },
  { path: 'store/customer-service', loadChildren:() => import('./customer-service/customer-service.module').then(m => m.CustomerServiceModule)},
  { path: 'store/terms-and-policies', loadChildren:() => import('./terms-policies/terms-policies.module').then(m => m.TermsPoliciesModule)},
  { path:'store/exclusive-online-game',component:ExclusiveGamesComponent,canActivate:[ExclusiveGameGuard]},
  { path:'sitemap',loadChildren:() => import('./organisation/organisation.module').then(m => m.OrgainisationRouteModule)},
  
  {
    path: '**',
    loadChildren: () => import('./not-found/not-found.module').then(m => m.Pagenotfoundmodule),
  }
];

@NgModule({
  providers: [{
    provide: externalUrlProvider,
    useValue: (route: ActivatedRouteSnapshot) => {
      const externalUrl = route.paramMap.get('externalUrl');
      window.open(externalUrl, '_self');
    },
  }],
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadService,
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'top',
    onSameUrlNavigation: 'reload',
    initialNavigation: 'enabledBlocking'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

export function htmlFiles(url: UrlSegment[]) {
  return url.length === 1 && url[0].path.endsWith('gift-cards') ? ({
    consumed: url
  }) : null;
}
