<input type="hidden" id="moltonbrown">
<div class="store-front-container" >
  <div id="modal-background"></div>
  <div id="sidebar">
      <app-menu [sidemenuStatus]="toggleMainMenu" ></app-menu>
  </div>
  <div id="mainBar" class="main-content" id="MB-Header">
      <div class="storefront-content">
        <div class="storefront-cs-header" *ngIf="enableCSHeader">
        <app-cs-header></app-cs-header>  
      </div>
        <div  class="storefront-checkout-header" *ngIf="checkoutStatus">
          <app-header ></app-header> 
        </div>

        <div class="storefront-checkout-header" *ngIf="enableCookieBar">
          <app-cookiebar></app-cookiebar> 
        </div>

        <main  class="storefront" id="main-content">
            <router-outlet></router-outlet>
          </main>
          <div  class="storefront-checkout-footer" *ngIf="checkoutStatus">
        <app-footer ></app-footer>
        </div>
      </div>
  </div>
</div>

