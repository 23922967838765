import {
  Inject,
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Renderer2,
  Input,
  SimpleChange,
  PLATFORM_ID
} from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { Title } from "@angular/platform-browser";
import { HeaderComponentService } from "../header/header.service";
import { HeaderSubmenuComponent } from "../header-submenu/header-submenu.component";
import { SingletonService } from "../../services/singleton.service";
import { environment }     from '../../../environments/environment';
import { Location } from "@angular/common";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "../../translate.service";
import { BasketPageComponentService } from "../../checkoutpage/basketpage/basketpage.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { productviewComponentService } from "../productview/productview.service";
import { UntypedFormBuilder } from "@angular/forms";
import { GtmMethodService } from '../../services/gtmmethods.service';
import { AmplienceService } from 'src/app/services/amplience.service';
import { DeliveryKey } from '../../amplience.constant';
import * as _ from "lodash";
import { WebExtendService } from "src/app/services/webExtend.service";

declare var AmpCa: any;
declare var $: any;

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent extends HeaderSubmenuComponent
  implements OnInit, AfterViewInit {    
  @ViewChild("mySidenav") sideNav: ElementRef;
  @ViewChild("searchInput") searchMobField: ElementRef;
  @Input() sidemenuStatus: boolean;
  @Input() set catalogMenu(catalog){
    this.catalogmenu = catalog;
    if (this.catalogmenu)this.catalogmenu.filter((category, i)=>{
      if(category.order !=100 && category.categoryDisplayName){
        if(category.megaMenuSlotID) this.getAmplienceContent(category.megaMenuSlotID,i)
      }
    })
  }
  countryData:Array<any>=environment.countryJsonData;
  countryText: any;
  footerData: Array<any> ;
  toggle: boolean;
  mouseEnter: boolean;
  searchBlockClose: boolean;
  menuLog: any;
  baseSiteCountries: Array<any> = environment.countryJsonData;
  countries = environment.countryJsonData;
  currentCountryCode: string;
  signIn:boolean;
  siteData:any;
  feedList:Array<any>;
  rendercatalogasASM:boolean;
  menuLocalData:any;
  catalogmenu: Array<any>;
  footerAmplienceData: any ;
  footerCompare:any;
  showFooter:boolean;
  showMegaMenu:boolean;
  testDKey: any;
  previewId: any;
  VSEId:any;
  constructor(
    @Inject(DOCUMENT) public dom,
    @Inject(PLATFORM_ID) public platformId: Object,
    public renderer: Renderer2,
    public el: ElementRef,
    public headerServ: HeaderComponentService,
    public singletonServ: SingletonService,
    public location: Location,
    public router: Router,
    public route: ActivatedRoute,
    public basketServ: BasketPageComponentService,
    public deviceService: DeviceDetectorService,
    public quickServ: productviewComponentService,
    public fb: UntypedFormBuilder,
    public gtmServe:GtmMethodService,
    public ampService: AmplienceService,  
    public webExtend:WebExtendService

  ) {
    super(
      dom,
      platformId,
      renderer,
      el,
      headerServ,
      singletonServ,
      location,
      router,
      route,
      basketServ,
      deviceService,
      quickServ,
      fb,
      gtmServe,
      ampService,
      webExtend
    );
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.param1 = params['api'];   
      this.previewId = params['previewId'];
      this.testDKey = params['testDKey'];
      this.VSEId = params['VSE'];   
  });
    const baseSite=this.singletonServ.catalogVersion;   
    this.getAmplienceCnt(baseSite)
  }

  async getAmplienceCnt(baseSite){
     /// Retrieve Amplience Footer Data for mobile menu footer
     if(this.previewId && this.testDKey){
      if(this.testDKey == 'Footer'){
        this.showFooter = true;
        await this.ampService.getContentByID(this.previewId, this.VSEId).subscribe(resp=>{
          this.footerAmplienceData = resp['content'];
         });
      }     
     } else if(!this.previewId){
      await this.ampService.getContentByKey(DeliveryKey.footer[baseSite.isoCode]).subscribe(resp=>{
        this.footerAmplienceData = resp['content'];     
     
        });  
     }
       
  }

  constructHostDetail(){
    if (isPlatformBrowser(this.platformId)) {
      const _hostname = this.location['_locationStrategy']?._platformLocation._location.hostname;
      const _index = _.findIndex(this.countryData,(o)=>{ 
            return (o.hostname == _hostname||o.serverName == _hostname);
      });
      if(_index !=-1){
        this.singletonServ.catalogVersion = this.countryData[_index];
        this.siteData=true;
        this.setBrowserSessionData("prefered_lng", JSON.stringify(this.countryData[_index]));
        if (this.getBrowserSessionData(this.singletonServ.catalogVersion .csCustomer)) {
          const _customer = JSON.parse(this.getBrowserSessionData(this.singletonServ.catalogVersion .csCustomer));
          this.singletonServ.catalogVersion.csAgent=true; 
          this.singletonServ.catalogVersion.agentToken=_customer["agentToken"];   
        }
        this.retrieveStaticContentBySiteId(this.countryData[_index]);
      } else if (this.getBrowserSessionData("prefered_lng")){
        const _data = JSON.parse(this.getBrowserSessionData("prefered_lng"));
        this.singletonServ.catalogVersion = _data;
        if (this.getBrowserSessionData(this.singletonServ.catalogVersion .csCustomer)) {
          const _customer = JSON.parse(this.getBrowserSessionData(this.singletonServ.catalogVersion .csCustomer));
          this.singletonServ.catalogVersion.csAgent=true; 
          this.singletonServ.catalogVersion.agentToken=_customer["agentToken"];   
        }
        this.siteData=true;
        this.retrieveStaticContentBySiteId(_data);
      }else{
         this.route.queryParams.subscribe(params => {
       const myParam =  params['country_store'];
      if (myParam) {
        const _index = _.findIndex(this.countryData, function(o) {
          return o.lngCode == myParam;
        });
        if(_index !=-1 ){
          this.singletonServ.catalogVersion = this.countryData[_index];
          this.siteData=true;
          this.setBrowserSessionData("prefered_lng", JSON.stringify(this.countryData[_index]));
          if (this.getBrowserSessionData(this.singletonServ.catalogVersion .csCustomer)) {
            const _customer = JSON.parse(this.getBrowserSessionData(this.singletonServ.catalogVersion .csCustomer));
            this.singletonServ.catalogVersion.csAgent=true; 
            this.singletonServ.catalogVersion.agentToken=_customer["agentToken"];   
          }
          this.retrieveStaticContentBySiteId(this.countryData[_index]);
        }else if (this.getBrowserSessionData("prefered_lng")){
                const _data = JSON.parse(this.getBrowserSessionData("prefered_lng"));
                this.singletonServ.catalogVersion = _data;
                this.siteData=true;
                if (this.getBrowserSessionData(this.singletonServ.catalogVersion .csCustomer)) {
                  const _customer = JSON.parse(this.getBrowserSessionData(this.singletonServ.catalogVersion .csCustomer));
                  this.singletonServ.catalogVersion.csAgent=true; 
                  this.singletonServ.catalogVersion.agentToken=_customer["agentToken"];   
                }
                this.retrieveStaticContentBySiteId(_data);
             
        }else{
          this.setCatlogVersion();
        }
      } else{
        this.setCatlogVersion();
      }       
    });
      }
    }
  }
  setCatlogVersion(){
    const _index = _.findIndex(environment.countryJsonData, function(o) {
      return o.locale == "en-GB";
    });
    if(_index !=-1){
      this.setBrowserSessionData("prefered_lng", JSON.stringify(environment.countryJsonData[_index]));
      if (this.getBrowserSessionData(this.singletonServ.catalogVersion .csCustomer)) {
        const _customer = JSON.parse(this.getBrowserSessionData(this.singletonServ.catalogVersion .csCustomer));
        this.singletonServ.catalogVersion.csAgent=true; 
        this.singletonServ.catalogVersion.agentToken=_customer["agentToken"];   
      }
      this.siteData=true;
      this.singletonServ.catalogVersion = environment.countryJsonData[_index];
      this.retrieveStaticContentBySiteId(environment.countryJsonData[_index]);
    }

  }
  retrieveStaticContentBySiteId(baseSite){
    if (baseSite) {
      if (this.getBrowserSessionData(this.singletonServ.catalogVersion .csCustomer)) {
        const _customer = JSON.parse(this.getBrowserSessionData(this.singletonServ.catalogVersion .csCustomer));
        this.singletonServ.catalogVersion.csAgent=true; 
        this.singletonServ.catalogVersion.agentToken=_customer["agentToken"];   
      }
      const _isoCode = this.singletonServ.catalogVersion.isoCode; 
      this.countries.map(obj => {
        if (_isoCode == obj.isoCode) {
          obj["current"] = true;
          this.currentCountryCode = obj.countryName;
        } else {
          obj["current"] = false;
        }
     });
     if (this.getBrowserSessionData(baseSite.reg)) {
      this.signIn = true;
      this.singletonServ.loggedIn = true;
    }
  }

  }
  onmbCountryChange(data) {
     this.countryText = data;
  }
  onCancelModal(bol) {   
    const baseSite = this.singletonServ.catalogVersion;
    let user;
    if(this.getBrowserSessionData(baseSite.reg)){
       user= JSON.parse(this.getBrowserSessionData(baseSite.reg));
     }
    if (bol) {
      this.setBrowserSessionData(
        "prefered_lng",
        JSON.stringify(this.countryText)
      );    
      const _isoCode=this.countryText.isoCode;
      this.countries.map((obj)=>{
        if(_isoCode==obj.isoCode){
          obj['current']=true;
        }else{
          obj['current']=false;
        }
      });  
      const _obj = {
        baseSiteChange: true,
      };
      if(this.getBrowserSessionData(baseSite.reg)){
        if(!this.getBrowserSessionData(this.countryText.reg)){       
          let currentUser={email:''};
          currentUser.email=user.email;
          this.setBrowserSessionData(
            this.countryText.reg,
            JSON.stringify(currentUser)
          );
          if(user.token){
            this.headerServ.getUserData(baseSite,user.token,user.email).subscribe((response)=>{
              this.singletonServ.loggedIn=true;
              const userDtls= JSON.parse(this.getBrowserSessionData(this.countryText.reg));
              userDtls['isExpressCheckout']=(response['isExpressCheckout'])?true:false;
              this.setBrowserSessionData(
                this.countryText.reg,
                JSON.stringify(currentUser)
              );
            },err=>{

            })
          }
          this.singletonServ.catalogVersion =  JSON.parse(this.getBrowserSessionData("prefered_lng"));
          this.singletonServ.sendMessage(_obj);      
          this.goToStore();
        }else{
          this.singletonServ.catalogVersion =  JSON.parse(this.getBrowserSessionData("prefered_lng"));
          this.singletonServ.sendMessage(_obj);      
          this.goToStore();
     }
    }else{
      this.singletonServ.catalogVersion =  JSON.parse(this.getBrowserSessionData("prefered_lng"));
      this.singletonServ.sendMessage(_obj);      
      this.goToStore();
    }
 
    }
  }
onFindStoreClick(){
  this.router.navigate(['store','company','stores'])
  this.onSidemenutap();
}

ngAfterViewInit() {
    this.singletonServ.getMessage().subscribe(message => {
      if (message.access_token) {
        this.signIn = true;
        this.singletonServ.loggedIn = true;
      }
    });

    this.constructHostDetail();
}
checkuser(){
    const baseSite=this.singletonServ.catalogVersion;
    if (isPlatformBrowser(this.platformId)) {
       if(baseSite){
         if(this.getBrowserSessionData(baseSite.reg)){
            return true;
         }
      }
    }
    return false;
}
onhidesubmenu() {
    this.mouseEnter = false;
}
toggleMenu(event) {
    event.preventDefault();
     this.sideNav.nativeElement.scrollTo(0, 0);
     this.onSidemenutap();
}
swipMenu(event) {
   this.onSidemenutap();
  }
  onKeydown(event) {
    console.log(event);
    event.stopPropagation();
    if (event.key == "Enter" || event.keyCode==13) {
      if (event.srcElement.classList.contains('dropbtn-bg-none')) {
        event.srcElement.classList.remove('dropbtn-bg-none');
        event.srcElement.classList.add('dropbtn-bg');
        event.srcElement.children[1].classList.remove('submenu-ds-none');
        event.srcElement.children[1].classList.add('submenu-ds');
      }
    }
  }
  onKeyescape(event) {
    event.stopPropagation();
    if (event.key == "Escape" || event.keyCode==27) {
      $('.sub-menu').addClass('submenu-ds-none').removeClass('submenu-ds');
      $('.sub-dropdown').addClass('dropbtn-bg-none').removeClass('dropbtn-bg');
     }
  }
  onHoverCategory(event,item, i) {
    const that=this;
    const __dataList=[];
    let amplienceImages = [];
    if (item.bannerId&&item.order !=100) {
     
    const objId = item["subcategories"].findIndex(function(obj) {
      return !Array.isArray(obj);
    });
    if(objId ==-1){
       this.singletonServ.catalogmenu[i]["subcategories"].push({ content: [] });
       const _contentId = item["subcategories"].findIndex(function(obj) {
        return !Array.isArray(obj);
      });
       this.singletonServ.catalogmenu[i]["subcategories"][_contentId]["content"]=this.getStaticBanner(item,_contentId);
    }
  }
  
  if (item.name) {
    if(item.amplienceData){
        this.mouseEnter = true;
        this.catalogmenu.map((obj, id) => {
          if (id == i) {
            obj.bg = true;
          } else {
            obj.bg = false;
          }
        });
     }
    } else {
      this.catalogmenu.map((obj, id) => {
        if (id == i) {
          obj.bg = true;
        } else {
          obj.bg = false;
        }
      });
      this.mouseEnter = true;
    }
    this.singletonServ.sendMessage({hideCart:true});
    // Amplience Images GTM content
    if(item.amplienceData){
    if(item.amplienceData.column2.images) item.amplienceData.column2.images.forEach(img => {
      if(img.gtmProperties)amplienceImages.push(img.gtmProperties)
    });
    if(item.amplienceData.column3.images) item.amplienceData.column3.images.forEach(img => {
      if(img.gtmProperties)amplienceImages.push(img.gtmProperties)
    }); 
    if(item.amplienceData.column4.images) item.amplienceData.column4.images.forEach(img => {
      if(img.gtmProperties)amplienceImages.push(img.gtmProperties)
    });   
     amplienceImages.forEach(function (element) {
          const _data= {
            'id': element.id,            
            'name':element.name,
            'creative': (element.creative)?(element.creative):'',
            'position': element.position
        };
        __dataList.push(_data);
   
       });
       if(__dataList.length !=0){
          if(that.feedList){
            const _result= _.differenceWith(__dataList, that.feedList, _.isEqual);     
            if(_result.length !=0){
                 that.gtmServe.sendFeedOnHover(_result);
                 that.feedList= _.uniq(_.union(this.feedList,_result));
             }
          }else{
            this.feedList=__dataList;
            that.gtmServe.sendFeedOnHover(__dataList);
          }
      }
    }
  }

  onShowEditorialPage(event) {
    this.onSidemenutap();
    this.router.navigate(["store/journal/."]);
  
  }

  onClickSaleCategory(event,item){
      event.preventDefault();
      const url = "/store" + item.url.replace("/c/", "/");
      if(item.isCategoryToBeRedirected){
        this.searchCategoryInstance(event,item);
      }else{  
       // const res_url = url.toLowerCase();  
        this.router.navigate([url]);
      }
      this.onSidemenutap();
    }
  onTapRegister() {
    this.router.navigate(["store", "myacc", "mbRegister"]);
    this.renderMenu();
    this.onSidemenutap();
  }
  onTapLogin() {
    this.router.navigate(["store", "myacc", "mbLogin"]);
    this.renderMenu();
    this.onSidemenutap();
  }
  renderMenu() {
    this.mouseEnter = false;
  }

  getRouterPath(current){
    if(current.categoryDisplayName === "Treats"){
      const _url="/store/special-offers/"+current.id;
      return _url;
    } else if(current.categoryDisplayName === "Editorial") {
          return "/store/journal/.";
    } 
    else if(current.categoryDisplayName === "Sustainability"||current.categoryDisplayName === "Nachhaltigkeit") {
      return "/store/sustainability";
    } 
    else if(current.name== "special-offers"){
        const _url="/store/special-offers/"+current.id;
        return _url;
    }
    else if(current.categoryDisplayName== "Stories") {
      return "/store/stories";
    } else {
      const url = "/store" + current.url.replace("/c/", "/");
      return url;
    }
    return ""
  }

  onShowMbCategory(event, data) {
    event.stopPropagation(); 
    event.preventDefault();
  if(data.categoryDisplayName === "Treats"){
      if((event.ctrlKey && event.which === 1) || event.metaKey){
        const _url="/store/special-offers/"+data.id;
        //const res_url = _url.toLowerCase(); 
        window.open(_url); 
      } else {          
        const _uri="store/special-offers/"+data.id;    
        this.mouseEnter = false;
        this.catalogmenu.map((obj, id) => {
            obj.bg = false;
        });
        this.renderMenu();
       // const res_url = _uri.toLowerCase(); 
        this.router.navigate([_uri]);
     }
    } else  if(data.categoryDisplayName === "Editorial") {

            if((event.ctrlKey && event.which === 1) || event.metaKey){
              window.open("/store/journal/"); 
            } else {              
              this.mouseEnter = false;
              this.catalogmenu.map((obj, id) => {
                  obj.bg = false;
              });
              this.renderMenu();
              this.router.navigate(["store/journal/."]);
          }
    }
    else  if(data.categoryDisplayName === "Sustainability"||data.categoryDisplayName === "Nachhaltigkeit") {

          if((event.ctrlKey && event.which === 1) || event.metaKey){
            window.open("/store/sustainability"); 
          } else {              
            this.mouseEnter = false;
            this.catalogmenu.map((obj, id) => {
                obj.bg = false;
            });
            this.renderMenu();
            this.router.navigate(["/store/sustainability"]);
        }
    }  else  if(data.categoryDisplayName === "Stories") {

      if((event.ctrlKey && event.which === 1) || event.metaKey){
        window.open("/store/stories"); 
      } else {              
        this.mouseEnter = false;
        this.catalogmenu.map((obj, id) => {
            obj.bg = false;
        });
        this.renderMenu();
        this.router.navigate(["/store/stories"]);
    }
}
     else {

      this.mouseEnter = false;
      this.catalogmenu.map((obj, id) => {
          obj.bg = false;
      });
      const routeId = data.id;
          if(data.isCategoryToBeRedirected){
            this.searchCategoryInstance(event,data);
          } else {          
            if((event.ctrlKey && event.which === 1) || event.metaKey){
              const url = "/store" + '/'+data.name+'/'+routeId;
              //const res_url = url.toLowerCase();
              window.open(url); 
            } else {
                this.mouseEnter = false;
                this.catalogmenu.map((obj, id) => {
                    obj.bg = false;
                });
                this.renderMenu();
                //const redirect_routeId = routeId.toLowerCase();
                this.router.navigate(["/store", data.name, routeId]);
          }
        }
    } 
  }

  onClickSubCategory(event, current) {
    event.stopPropagation();
    event.preventDefault();
    if (current.isL3 || (!current.parent && current.description)) {      
       if(current.name== "special-offers"){
            if((event.ctrlKey && event.which === 1) || event.metaKey){
              const _url="/store/special-offers/"+current.id;
              //const res_url = _url.toLowerCase();
              window.open(_url); 
            } else {  
              const _uri="store/special-offers/"+current.id;        
              this.mouseEnter = false;
              this.catalogmenu.map((obj, id) => {
                  obj.bg = false;
              });
              this.renderMenu();
              //const res_url = _uri.toLowerCase();
              this.router.navigate([_uri]);
          }
      } else  {
        if(current.isCategoryToBeRedirected){
          this.searchCategoryInstance(event,current);
        }else{        
          const url = "/store" + current.url.replace("/c/", "/");
          if((event.ctrlKey && event.which === 1) || event.metaKey){
           // const res_url = url.toLowerCase();
             window.open(url); 
          } else {
            this.mouseEnter = false;
            this.catalogmenu.map((obj, id) => {
                obj.bg = false;
            });
            this.renderMenu();
           // const res_url = url.toLowerCase();
            this.router.navigate([url]);
            this.catalogmenu.map(obj => {
              obj.bg = false;
            });
          }          
      }
    }
    }
  }
  searchCategoryInstance(event,data){
    const that = this;
    const baseSite=this.singletonServ.catalogVersion;
    this.headerServ.verifyProduct(baseSite,data.id).subscribe((response:any)=>{
      if(response.keywordRedirectUrl){
          this.mouseEnter = false;
          if(this.catalogmenu){
            this.catalogmenu.map((obj, id) => {
              obj.bg = false;
            });
          }
          if((event.ctrlKey && event.which === 1) || event.metaKey){
            window.open(response.keywordRedirectUrl); 
          } else {
              this.mouseEnter = false;
              if(this.catalogmenu){
                this.catalogmenu.map((obj, id) => {
                  obj.bg = false;
                });
              }
              this.renderMenu();
              this.router.navigate([response.keywordRedirectUrl]);
          }
    }
    });
  }
  onmouseLeave() {
    this.catalogmenu.map(obj => {
      obj.bg = false;
    });
    this.mouseEnter=false;
  }
  mouseLeave() {
    this.mouseEnter = false;
  }
  onSearcKeyUp(event) {
    if(event.keyCode==13){
        event.target.blur();   
        const val=event.target.value.trim();
        const deocdeVal =encodeURIComponent(val);    
        if(deocdeVal.length!=0){
          this.removeBrowserSessionData("searchId");          
         this.router.navigate(["store", "browse", deocdeVal]); 
         this.openSearcgLandingPage(); 
       }
    }else{      
       this.onMenuSearchChange(event,event.target.value)
    }
  }
  openSearcgLandingPage(){
      this.searchResults.length=0;
      this.searchCtgry = false;
      this.searchBlock = false;
      this.searchBlockClose = false
      this.searchPrdctText = "";
      this.searchResultValue = "";
      this.searchQuery='';
      this.searchMobField.nativeElement.value="";
      this.onSidemenutap();
  }

  onMenuSearchChange(event,searchValue: string) {
    this.searchCtgry = true;
    const baseSite=this.singletonServ.catalogVersion;
    const val=searchValue.trim();
    this.searchResultValue = val;
    this.openSearchInput=true;
    this.searchQuery=val;
    if(event.keyCode==13){
      this.searchResults.length=0;
    }else{      
    if (val.length >= 3) {
      const deocdeVal =encodeURIComponent(val);
      this.headerServ
        .getCategorySearchResults(baseSite,deocdeVal)
        .subscribe(
          resp => {
            if (resp["products"]) {
              if (resp["products"].length > 0) {
                this.searchResults = resp["products"].slice(0, 3);
                this.searchCtgry = true;
                this.searchBlock = true;
              } else {
                this.searchResults = [];
                this.searchCtgry = true;
                this.searchBlock = false;
              }
            } else {
              this.searchResults = [];
              this.searchCtgry = true;
              this.searchBlock = false;
            }
          },
          err => {
            this.searchBlock = false;
          }
        );
    } else {
      this.searchResults = [];
      this.searchCtgry = true;
      this.searchBlock = false;
    }
  }
  }
  onTapAccount(){
    this.onSidemenutap();
    this.onProfileClick();
  }
  onTapLogut(){
    this.signIn=false;  
    this.singletonServ.loggedIn=false;
    const _sessionNames=Object.keys(localStorage);
    if(_sessionNames.length){
      _sessionNames.map((obj)=>{
        if(obj !='prefered_lng'){
           this.removeBrowserSessionData(obj);
        }
      });
    }
    if (isPlatformBrowser(this.platformId)) {
        this.removeBrowserSessionData("order");
        const _sessionNames=Object.keys(localStorage);
        if(_sessionNames.length){
          _sessionNames.map((obj)=>{
            if(obj !='prefered_lng'){
              this.removeBrowserSessionData(obj);
            }
          });
        }
    }
    this.singletonServ.cartObj=undefined;
    $('#storeCartCount').val('0');
    this.onResetSidemenuTap();

  }

  getStaticBanner(obj,objId){
    const baseSite=this.singletonServ.catalogVersion;
    let bannerBox = obj.bannerId.split(",");
    const _banners=[];
    let api;
    if(this.param1){
      api="https://"+this.param1;
    } else{
      api="https://c1.amplience.net"
    }
    _.forEach(bannerBox, function(value, i) {
      value.replace("/ /g", "");
      if(value.length !=2){
  if(AmpCa){
    if(AmpCa.utils){


      if(AmpCa.utils.getHtmlServiceData){      
          AmpCa.utils.getHtmlServiceData({
            auth: {
              baseUrl: api,
              id: value.trim(),
              store: "moltonbrown",
              templateName: "slot-contentWrapper",
              locale: baseSite.locale
            },
            callback: function(data) {
              const temp = {
                templatename: data
              };
              if(obj.categoryDisplayName !='Editorial'){
                _banners.push(temp);
              }else{
                obj['ampContent'].push(temp);
              }
            }
          });
    }
  }
}

  }  });
  return _banners;
  }
  onJournalLinks(event,link){
    event.stopPropagation();
    event.preventDefault();
    this.catalogmenu.map((obj, id) => {
      obj.bg = false;
    });
    this.router.navigateByUrl(link);
  }

   getAmplienceContent(slotId,index){
    if(this.previewId && this.testDKey){
      if(this.testDKey == 'MegaMenu' ){
        this.showMegaMenu = true;
        this.ampService.getContentByID(this.previewId, this.VSEId).subscribe(resp=>{
          this.catalogmenu[index].amplienceData = resp['content']; // For Desktop
          this.singletonServ.menudata[index].amplienceData = resp['content']; // data for SideNav
        });
      }
    } else if(!this.previewId){
      this.ampService.getContentByKey(slotId).subscribe(resp=>{
        this.catalogmenu[index].amplienceData = resp['content']['slotContent'][0]; // For Desktop
        this.singletonServ.menudata[index].amplienceData = resp['content']['slotContent'][0]; // data for SideNav
      });
    }    
  }
  // Display next L2/L3 Category
  showNextCategory(e, item) {
    let elm = e.target;
    if($(elm).hasClass("sub-nav-category")) {
      $(e.target).parents(".main-menu-l1").css('height','100%');
      $(elm).siblings(".main-menu").addClass("active");
      if($(".main-menu .level3").hasClass("active")){ // condition added for L3
        let menuHeight = $(elm).siblings(".main-menu").find("ul").height(); // get height of active menu
        let divHeight = menuHeight + 60; // add back button heght
        $(".menu-wrapper").css({'height': divHeight+'px', 'margin-bottom': '20px'}); // set dynamiv height
      }
      else{
        let menuHeight = $(".main-menu .active").last().find("ul").height();
        let divHeight = menuHeight + 60;
        $(".menu-wrapper").css({'height': divHeight+'px', 'margin-bottom': '0px'});   
      }
    } else {
      if(item && item!= false) {
        this.onSidemenutap();
        this.router.navigate([item]);
      }
    }
  }
  // Display previous L1/L2 Category
  showPrevCategory(e) {
    let element = e.target;
    $(element).parents(".main-menu").first().removeClass("active");
    if($(".main-menu").hasClass("active")){
      let menuHeight = $(".main-menu .active ul").height();
      let divHeight = menuHeight + 60;
      $(".menu-wrapper").css({'height': divHeight+'px', 'margin-bottom': '0'});
    }else{
      $(e.target).parents(".main-menu-l1").css('height','auto');
      $(".menu-wrapper").css({'height': '487px', 'margin-bottom': '37px'}); 

    }
  }
    //Megamenu images and img title click
    gtmPromoclicks(item){
      const gtmproperties=item.gtmProperties;
      this.gtmServe.gtmPromoclicks(gtmproperties);
     }
//Click function for l2 and 13 links - to handle ctrl + click new window open

OnLinkClick(event,url,item){
  item.bg=false;
  event.preventDefault();
  event.stopPropagation();
  if((event.ctrlKey && event.which === 1) || event.metaKey){        
  window.open(url);
  }else{
      this.router.navigate([url]);
  }
}
}

