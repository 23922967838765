import { Injectable, Inject, RendererFactory2, PLATFORM_ID,ViewEncapsulation } from '@angular/core'; 
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { map, filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { element } from 'protractor';
import { environment } from '../../environments/environment';

@Injectable()
export class SEOService {
  currentLanguage: string = "";
  contryUrls:Array<any> = environment.alternateURLs;
  baseURL: string = "";
  localeContent = [
    { country: "UK", code: "en", content: "en_GB" },
    { country: "USA", code: "us", content: "en_US" },
    { country: "Germany", code: "de", content: "de_DE" },
    { country: "Europe", code: "eu", content: "en_EU”" }
  ];

  constructor(
    @Inject(PLATFORM_ID) public platformId: Object, 
    private title: Title, 
    private meta: Meta, 
    private http: HttpClient,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private dom) {}
 setTabTitle(title:string){
      const htmlTitleContent=`<div id="descTitleContent">${title}</div>`;
      const parser = new DOMParser();
      const parsedHtml = parser.parseFromString(htmlTitleContent, 'text/html');
      const _innerText=parsedHtml.getElementById('descTitleContent').innerText;
      this.title.setTitle(_innerText);
  }
  updateTitle(title: string) {    
    let dcTitle = this.dom.querySelector("meta[name='DC.title']");
    if(dcTitle){
      dcTitle.setAttribute('content', title);
    }else {
      this.meta.updateTag({ name: 'DC.title', content: title });
    }

    let ogTitle = this.dom.querySelector("meta[property='og:title']");
    if(ogTitle){
      ogTitle.setAttribute('content', title);
    }else {
      try {
        const renderer = this.rendererFactory.createRenderer(this.dom, {
            id: '-1',
            encapsulation: ViewEncapsulation.None,
            styles: [],
            data: {}
        });
  
        const meta = renderer.createElement('meta');
        const head = this.dom.head;
  
        if (head === null) {
            throw new Error('<head> not found within DOCUMENT.');
        }
  
        renderer.setAttribute(meta, 'property', "og:title");
        renderer.setAttribute(meta, 'content', title);
        renderer.appendChild(head, meta);
  
      } catch (e) {
      }
    }
  }

  updateKeywords(keywords: string) {
    let element = this.dom.querySelector("meta[name='keywords']");
    if(element){
      element.setAttribute('content', keywords);
    }else {
      this.meta.updateTag({ name: 'keywords', content: keywords });
    }
  }

  updateMetaTitle(titleText: string) {
    let element = this.dom.querySelector("meta[name='title']");
    const htmlContent=`<div id="descContent">${titleText}</div>`;
    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(htmlContent, 'text/html');
    const _innerText=parsedHtml.getElementById('descContent').innerText;
    if(element){
      element.setAttribute('content', _innerText);
    }else {
      this.meta.updateTag({ name: 'title', content: _innerText });
    }
  }

  updateDCSUBJECT(keywords: string) {
    let element = this.dom.querySelector("meta[name='DC.subject']");
    if(element){
      element.setAttribute('content', keywords);
    }else {
      this.meta.updateTag({ name: 'DC.subject', content: keywords });
    }
  }

  updateDescription(desc: string) {
    let element = this.dom.querySelector("meta[name='description']");
    const htmlContent=`<div id="descContent">${desc}</div>`;
    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(htmlContent, 'text/html');
    const _innerText=parsedHtml.getElementById('descContent').innerText;
    if(element){
      element.setAttribute('content', _innerText);
    }else {
      this.meta.updateTag({ name: 'description', content: _innerText });
    }
  }
 
  updateDCDescription(desc: string) {
    let element = this.dom.querySelector("meta[name='DC.description']");
    const htmlContent=`<div id="descDCContent">${desc}</div>`;
    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(htmlContent, 'text/html');
    const _innerText=parsedHtml.getElementById('descDCContent').innerText;
    if(element){
      element.setAttribute('content', _innerText);
    }else {
      this.meta.updateTag({ name: 'DC.description', content: _innerText });
    }
  }
  
  updateOGDescription(desc: string) {
    let element = this.dom.querySelector("meta[property='og:description']");
    const htmlContent=`<div id="ogContent">${desc}</div>`;
    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(htmlContent, 'text/html');
    const _innerText=parsedHtml.getElementById('ogContent').innerText;
    if(element){
      element.setAttribute('content', _innerText);
    }else {
      this.meta.updateTag({ property: 'og:description', content: _innerText });
    }
  }
  
  updateOGLocale(lang?: string) {
    let country = lang || "en";
    const data = this.localeContent.filter(l => l.code == country)[0];
    let content = data.content;
    let element = this.dom.querySelector("meta[property='og:locale']");
    if(element){
      element.setAttribute('content', content);
    }else {
      this.createMetaWithProperty('og:locale', content);
    }
  }

  updateOGImage(imgUrl?: string) {
    let url: string = "";
    if(imgUrl == undefined || imgUrl == null || imgUrl == ""){
      url = this.baseURL + "/assets/MBPromoImages/Social/"+ this.currentLanguage +"/Molton-Brown-Logo_600.png";
    }else {
      url = imgUrl;
    }
    let element = this.dom.querySelector("meta[property='og:image']");
    if(element){
      element.setAttribute('content', url);
    }else {
      this.createMetaWithProperty('og:image', url);
    }
  }
  
  updateOGURL(url: string) {
    const base_url = this.baseURL + url;
    let element = this.dom.querySelector("meta[property='og:url']");
    if(element){
      element.setAttribute('content', base_url);
    }else {
      this.createMetaWithProperty('og:url', base_url);
    }
  }

  createMetaWithProperty(property: string, content: string){
    try {
      const renderer = this.rendererFactory.createRenderer(this.dom, {
          id: '-1',
          encapsulation: ViewEncapsulation.None,
          styles: [],
          data: {}
      });

      const htmlContent=`<div id="createOgContent">${content}</div>`;
      let parser = new DOMParser();
      let parsedHtml = parser.parseFromString(htmlContent, 'text/html');
      const _innerText=parsedHtml.getElementById('createOgContent').innerText;
      const meta = renderer.createElement('meta');
      const head = this.dom.head;

      if (head === null) {
          throw new Error('<head> not found within DOCUMENT.');
      }

      renderer.setAttribute(meta, 'property', property);
      renderer.setAttribute(meta, 'content', _innerText);
      renderer.appendChild(head, meta);

    } catch (e) {
    }
  }

  updateAlternateLink(type: string, url: string) {
    const host_url = this.contryUrls.filter(e => e.country == type)[0].url;
    const alternateURL = host_url + url;
    let element = this.dom.querySelector("link[hreflang='" + type + "']");
    if(element){
      element.setAttribute('href', alternateURL);
    }else {
      this.addTag({ rel: 'alternate', href: alternateURL, hreflang: type });
    }
  }

  removeAlternateTags() {
    try {
      const renderer = this.rendererFactory.createRenderer(this.dom, {
        id: '-1',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {}
      });
      const head = this.dom.head;
      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.');
      }
      const linkTags = this.dom.querySelectorAll("link[rel='alternate']");
      for (const link of linkTags) {
        renderer.removeChild(head, link);
      }
    } catch (e) {
    }
  }
  
  postCanonicalUrl(url:string){
    const element = this.dom.querySelector("link[rel='canonical']");
    if(element){
      element.setAttribute('href', url);
    }else {
      this.addTag({ rel: 'canonical', href: url });
    }
  }
  
  createCanonicalURL(url: string) {
    let element = this.dom.querySelector("link[rel='canonical']");
    const base_url = this.baseURL + url;
    if(element){
      element.setAttribute('href', base_url);
    }else {
      this.addTag({ rel: 'canonical', href: base_url });
    }
  }

  updateCanonicalUrl(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    const base_url = this.baseURL + url;
    let element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',base_url)
    this.addTag({ rel: 'canonical', href: base_url });
  }

  addTag(tag: LinkDefinition, forceCreation?: boolean) {
    try {
      const renderer = this.rendererFactory.createRenderer(this.dom, {
          id: '-1',
          encapsulation: ViewEncapsulation.None,
          styles: [],
          data: {}
      });

      const link = renderer.createElement('link');
      const head = this.dom.head;
      const selector = this._parseSelector(tag);

      if (head === null) {
          throw new Error('<head> not found within DOCUMENT.');
      }

      Object.keys(tag).forEach((prop: string) => {
          return renderer.setAttribute(link, prop, tag[prop]);
      });

      // [TODO]: get them to update the existing one (if it exists) ?
      renderer.appendChild(head, link);

    } catch (e) {
    }
  }

  private _parseSelector(tag: LinkDefinition): string {
    // Possibly re-work this
    const attr: string = tag.rel ? 'rel' : 'hreflang';
    return `${attr}="${tag[attr]}"`;
  }

  appendScript(url: string) {
    try {
      const renderer = this.rendererFactory.createRenderer(this.dom, {
          id: '-1',
          encapsulation: ViewEncapsulation.None,
          styles: [],
          data: {}
      });

      const script = renderer.createElement('script');
      const head = this.dom.head;

      if (head === null) {
          throw new Error('<head> not found within DOCUMENT.');
      }

      renderer.setAttribute(script, 'src', url);
      renderer.appendChild(head, script);

    } catch (e) {
    }
  }

  getValue(type: string, url: string, wLocation) {
    this.currentLanguage = type || 'en';
    let host;
    if(type=='us'){
      host="www.moltonbrown.com";
    } else   if(type=='en'){
      host="www.moltonbrown.co.uk";
    }  else  if(type=='eu'){
      host="www.moltonbrown.eu";
    }  else  if(type=='de'){
      host="www.moltonbrown.de";
    } 
    this.baseURL = wLocation.protocol + "//" + host;
    const seoPath = `assets/seo/${type || 'en'}.json`;
    return this.http.get(seoPath).pipe(map(response => {
            const data  = response["seo-data"].filter((item:any) =>{
                 if( item.url === url || item.trailingSlashPath === url ) {
                    return item;
                  }
              });
            return data[0];
          }, error => error))
  }
  getBrowserSessionData(id){
    if (isPlatformBrowser(this.platformId)) {
      if(localStorage.getItem(id)){
        return localStorage.getItem(id);
      }
   }
  }
  setJSONLDLogo() {
    // audit fix MOL-422
    let logo = document.querySelector("meta[property='og:image']").getAttribute("content");
    let url = document.querySelector("meta[property='og:url']").getAttribute("content");
    let logoObject = {
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": url,
      "logo": logo
    }
    // Create new Script
    let newScript = document.createElement('script');
    newScript.type = 'application/ld+json';
    newScript.id = 'JSONLD_logo';
    newScript.text = JSON.stringify(logoObject);

    // Set head variable with browser fallback
    let head = document.head || document.getElementsByTagName("head")[0];
    head.appendChild(newScript);
  }

  setJSONLDForPDP(product) {
      let pdpObject = {
        "@context": "https://schema.org/", 
        "@type": "Product", 
        "name": product.productDisplayName,
        "image": product.amplienceImageName,
        "description": product.metaDescription, 
        "brand": { 
        "@type": "Brand",   
        "name": "MoltonBrown" 
        },
        "sku": "",  
        "offers": {
        "@type": "Offer",
        "url": window.location.origin+product.productCanonicalUrl        ,
        "priceCurrency": product.price.currencyIso,
        "price": product?.price?.value?.toString(),   
        "availability": product.stock.stockLevelStatus
         },
         "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": product.productAverageRating ? product.productAverageRating.toString() : "0" ,
          "bestRating": "5",
          "worstRating": "1",
          "ratingCount": product.reviewCount ? product.reviewCount.toString() : "0"
        }      
        }
      // remove script if already
      if (document.getElementById('JSONLD_PDP')) {
        let pdpScript = (document.getElementById('JSONLD_PDP'));
        pdpScript.parentNode.removeChild(pdpScript)
      }
      // Create new Script
      let newScript = document.createElement('script');
      newScript.type = 'application/ld+json';
      newScript.id = 'JSONLD_PDP';
      newScript.text = JSON.stringify(pdpObject);
  
      // Set head variable with browser fallback
      let head = document.head || document.getElementsByTagName("head")[0];
      head.appendChild(newScript);
  }

  setJSONLDForHP(type, baseSite){
    let host;
    let telephone;
    let email;
    let availableLanguage;
    let areaServed = baseSite.isoCode;
    if(type=='us'){
      host="www.moltonbrown.com";
      telephone="866-933-2344";
      email="uscustomerservice@moltonbrown.com";
      availableLanguage="en";
    } else   if(type=='en'){
      host="www.moltonbrown.co.uk";
      telephone="+44 (0)808 178 1188";
      email="customerservice@moltonbrown.com";
      availableLanguage="en";
    }  else  if(type=='eu'){
      host="www.moltonbrown.eu";
      telephone="00 800 4585 0076";
      email="customerservice@moltonbrown.com";
      availableLanguage="en";
    }  else  if(type=='de'){
      host="www.moltonbrown.de";
      telephone="00 800 4585 0076";
      email="Kundenservice@moltonbrown.de";
      availableLanguage="de";
    } 
    
    let homePageObj = {
      "@context": "https://schema.org/",
      "@type": "Corporation",
      "name": "MoltonBrown",
      "url": host,
      "logo": "https://media.moltonbrown.co.uk/i/moltonbrown/2022-molton-brown-logo?fmt=auto&w=1080",
      "sameAs": [
        "https://www.tiktok.com/@moltonbrown",
        "https://www.instagram.com/moltonbrown",
        "https://www.youtube.com/c/moltonbrown",
        "https://www.facebook.com/moltonbrown",
        "https://x.com/moltonbrownuk"
        ],
        "contactPoint": [
          {
          "@type": "ContactPoint",
          "telephone": telephone,
          "contactType": "customer service",
          "email": email,
          "areaServed": areaServed,
          "availableLanguage": availableLanguage
          }
          ]
    }

    // remove script if already
    if (document.getElementById('JSONLD_HP')) {
      let hpScript = (document.getElementById('JSONLD_HP'));
      hpScript.parentNode.removeChild(hpScript)
    }
    // Create new Script
    let newScript = document.createElement('script');
    newScript.type = 'application/ld+json';
    newScript.id = 'JSONLD_HP';
    newScript.text = JSON.stringify(homePageObj);

    // Set head variable with browser fallback
    let head = document.head || document.getElementsByTagName("head")[0];
    head.appendChild(newScript);
  }
}

export declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
      [prop: string]: string;
  };