import { 
    Injectable,
    Inject,
    NgZone,
    PLATFORM_ID
} from "@angular/core";
import { isPlatformBrowser,DOCUMENT } from '@angular/common';
import { SingletonService } from "./singleton.service";
import { profileComponentService } from "../component/profile-form/profile.service";
import { Router } from '@angular/router';
import * as _ from "lodash";
import { pageType} from "../categorylanding-page/categorylanding-page.component";

declare var window: any;
declare var $:any;
declare var ScarabQueue: any;
@Injectable({
  providedIn: 'root'
})
export class WebExtendService {
    Prodarray;
    productDetails;
    email;
    cart: any;

    constructor(   
        @Inject(DOCUMENT) public dom, 
        public singletonServ: SingletonService,
        public profileServ: profileComponentService,
        private router : Router,
        @Inject(PLATFORM_ID) public platformId: Object
    ){
        const baseSite = this.singletonServ.catalogVersion;
    }
    
    getBrowserSessionData(key){
        if (isPlatformBrowser(this.platformId)) {
            return this.singletonServ.getStoreData(key);
        }
    }
    
    cartItems(pdtArray) {
        this.cart = pdtArray;
    }
  
    webExtendSetemail(email){
        const baseSite = this.singletonServ.catalogVersion;
        this.Prodarray = [];
        if( this.cart && this.cart.length){
            this.cart.forEach(element => {
                this.productDetails={
                    "item":element.product.code,
                    "quantity": element.quantity,
                    "price": element.basePrice.value,
                };
                this.Prodarray.push(this.productDetails);
            });
        }
        //ScarabQueue.push(['testMode']);
        ScarabQueue.push(['setEmail', email]);
        ScarabQueue.push(['cart', this.Prodarray]);
        // Identifying the visitor with email address. The email address is hashed before sending to Emarsys, as you can see in Live Event tool.
        // This command is required for all JS API funcions. It closes and fires the ScarabQueue. Should be the last call on the page, called only once.
        ScarabQueue.push(['go']);
    }
  
    webExtendUnSetemail(email){
        ScarabQueue.push(['setEmail', email]);
        ScarabQueue.push(['go']);
    }
    // setEmail web extend command
  
    webExtendOnPDPLoad(user, _code){
      const baseSite = this.singletonServ.catalogVersion;
      this.Prodarray = [];
      if( this.cart && this.cart.length){
        this.cart.forEach(element => {
            this.productDetails={
                "item":element.product.code,
                "quantity": element.quantity,
                "price": element.basePrice.value,
            };
            this.Prodarray.push(this.productDetails);
        });
      }
      //ScarabQueue.push(['testMode']);
      if (this.getBrowserSessionData(baseSite.reg)) {
        const user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
        ScarabQueue.push(['setEmail', user.email]);
      }
      ScarabQueue.push(['cart', this.Prodarray]);
      ScarabQueue.push(['view', _code]);
      ScarabQueue.push(['go']);
    }
  
    webExtendAHome() {
        const baseSite = this.singletonServ.catalogVersion;
        this.Prodarray = [];
        if(this.cart && this.cart.length){
            this.cart.forEach(element => {
                this.productDetails={
                    "item":element.product.code,
                    "quantity": element.quantity,
                    "price": element.basePrice.value,
                };
                this.Prodarray.push(this.productDetails);
            });
        }
        //ScarabQueue.push(['testMode']);
        // Identifying the visitor so we can match and update the corresponding contact
        if (this.getBrowserSessionData(baseSite.reg)) {
            const user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
            ScarabQueue.push(['setEmail', user.email]);
        }
        ScarabQueue.push(['cart', this.Prodarray]);
        ScarabQueue.push(['go']); 
    }
  
    // cart web extend command
    webExtendAddToCart() {
        const baseSite = this.singletonServ.catalogVersion;
        this.Prodarray = [];
        if(this.cart && this.cart.length){
            this.cart.forEach(element => {
                this.productDetails={
                    "item":element.product.code,
                    "quantity": element.quantity,
                    "price": element.basePrice.value,
                };
                this.Prodarray.push(this.productDetails);
            });
        }
        //ScarabQueue.push(['testMode']);
        // Identifying the visitor so we can match and update the corresponding contact
        if (this.getBrowserSessionData(baseSite.reg)) {
          const user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
          ScarabQueue.push(['setEmail', user.email]);
        }
        ScarabQueue.push(['cart', this.Prodarray]);
        ScarabQueue.push(['go']); 
    } 
  
    webExtendRemoveFromCart(cartDetails)
    {
        const baseSite = this.singletonServ.catalogVersion;
        this.Prodarray = [];
        if(cartDetails && cartDetails.entries.length){
            cartDetails.entries.forEach(element => {
                this.productDetails={
                    "item":element.product.code,
                    "quantity": element.quantity,
                    "price": element.basePrice.value,
                };
                this.Prodarray.push(this.productDetails);
            });
        }
        //ScarabQueue.push(['testMode']);
        // Identifying the visitor so we can match and update the corresponding contact
        if (this.getBrowserSessionData(baseSite.reg)) {
          const user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
          ScarabQueue.push(['setEmail', user.email]);
        }
        ScarabQueue.push(['cart', this.Prodarray]);
        ScarabQueue.push(['go']); 
    }
  
    //category command web extend
    webExtendCategoryLandingPage(catPath){
        const baseSite = this.singletonServ.catalogVersion;
        this.Prodarray = [];
        if(this.cart && this.cart.length){
            this.cart.forEach(element => {
                this.productDetails={
                    "item":element.product.code,
                    "quantity": element.quantity,
                    "price": element.basePrice.value,
                };
                this.Prodarray.push(this.productDetails);
            });
        }
        if(pageType =="Category Landing Page"){
            //ScarabQueue.push(['testMode']);
            if (this.getBrowserSessionData(baseSite.reg)) {
                const user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
                ScarabQueue.push(['setEmail', user.email]);
            }
            ScarabQueue.push(['cart', this.Prodarray]);
        
            // Passing on the category path being visited. Must match the 'category' values listed in the Product Catalog
            ScarabQueue.push(['category', catPath]);
        
            // Firing the ScarabQueue. Should be the last call on the page, called only once.
            ScarabQueue.push(['go']);
        }
    }
  
    //purchase command web extend
    webExtendPurchase(purchaseDetails:any){
        let prodId : any;
        let price: any;
        let quantity: any;
        let allItems = purchaseDetails.entries.map((obj)=>{
            prodId = (obj.product.code)?(obj.product.code):'';
            price =  obj.basePrice.value;
            quantity = obj.quantity; 
            return {
                prodId: prodId,
                price: price,
                quantity: quantity
            };      
        });
        let items = [];;
        allItems.forEach((item) => {
            items.push({
                item: item.prodId,
                price: item.price,
                quantity: item.quantity,
            });
        });
        const baseSite = this.singletonServ.catalogVersion;
    
        //ScarabQueue.push(['testMode']);
        if (this.getBrowserSessionData(baseSite.reg)) {
            const user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
            ScarabQueue.push(['setEmail', user.email]);
        } else {
            let uid = this.retreiveUser(purchaseDetails.user.uid)
            ScarabQueue.push(['setEmail', uid]);
        }
        ScarabQueue.push(['cart', []]);
        ScarabQueue.push(['purchase', {
            orderId: purchaseDetails.code,
            items
        }]);
        ScarabQueue.push(['go']);
    }

    retreiveUser(uid){
        const _user=uid.split('|');
        const _email =_user[_user.length-1];
        return _email;
    }
}