import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from "../translate.service";
import { SingletonService } from "./../services/singleton.service";

@Component({
  selector: 'app-cookiebar',
  templateUrl: './cookiebar.component.html',
  styleUrls: ['./cookiebar.component.scss']
})
export class CookiebarComponent implements OnInit {
  cookie:any;
  cookiebar:boolean
  cookieValue = 'UNKNOWN';

  constructor(public cookieService: CookieService,
    private translate: TranslateService,
    public singletonServ: SingletonService) {

   }

  ngOnInit() {
    const baseSite = this.singletonServ.catalogVersion;
    const allCookies: {} = this.cookieService.getAll();
    if (baseSite) {
    if(baseSite.isoCode=='GB'){
      if(allCookies['cookiestored-gb']){
        if(document.getElementById("cookie")){
         document.getElementById("cookie").style.display="none";
        }
      }
    }

     if(baseSite.isoCode=='US'){
      if(allCookies['cookiestored-US']){
        if(document.getElementById("cookie")){
         document.getElementById("cookie").style.display="none";
        }
      }
    }

     if(baseSite.isoCode=='EU'){
      if(allCookies['cookiestored-eu']){
        if(document.getElementById("cookie")){
         document.getElementById("cookie").style.display="none";
        }
      }
    }

     if(baseSite.isoCode=='DE'){
      if(allCookies['cookiestored-de']){
        if(document.getElementById("cookie")){
         document.getElementById("cookie").style.display="none";
        }
      }
    }    
    this.cookiebar=true
    }
  }
  acceptCookie(){
    const baseSite = this.singletonServ.catalogVersion;
    if (baseSite) {
    if(baseSite.isoCode=='GB'){
      // CookieService.set(name: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean, sameSite?: "Lax" | "None" | "Strict")
      this.cookieService.set('cookiestored-gb','cookiestored-gb',7,'/','.moltonbrown.co.uk',false,"Lax");
    }
     if(baseSite.isoCode=='US'){
      this.cookieService.set('cookiestored-US','cookiestored-US',7,'/','.moltonbrown.com',false,"Lax");
    }
   if(baseSite.isoCode=='EU'){
      this.cookieService.set('cookiestored-eu','cookiestored-eu',7,'/','.moltonbrown.eu',false,"Lax");
    }
    if(baseSite.isoCode=='DE'){
      this.cookieService.set('cookiestored-de','cookiestored-de',7,'/','.moltonbrown.de',false,"Lax");      
    }
    document.getElementById("cookie").style.display="none";
    this.cookie=document.cookie;
  }
  }
}
