import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CollectionCategorylpComponent } from './collection-categorylp.component';
import { CollectionSubcatLpComponent } from './collection-subcat-lp/collection-subcat-lp.component';

const routes: Routes = [
  {
    path:'',component: CollectionCategorylpComponent, children:[
      {path:'wild-mint-lavandin', component: CollectionSubcatLpComponent},
      {path:'wild-mint-lavandin/.', component: CollectionSubcatLpComponent},
      {path:'marvellous-mandarin', component: CollectionSubcatLpComponent},
      {path:'marvellous-mandarin/.', component: CollectionSubcatLpComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CollectionCategorylpRoutingModule { }
