<div id="view-bag-modal-wrapper" *ngIf="!mobileDevice">
  <!-- VIEW BAG MODAL -->
  <div class="modal right fade" id="viewBagModal" tabindex="-1" role="dialog" aria-labelledby="viewBagModal">
    <div class="modal-dialog modal-dialog-scrollable" role="document">
      <div class="modal-content">

        <div class="modal-header" [ngClass]="{'scroll':scrolled}">
          <button aria-label="Close" class="close" data-bs-dismiss="modal" #bagModal type="button">
            <i class="icon-close-lg"></i></button>
        </div>

        <div class="modal-body" id="bodyContent" (scroll)="scrollHandler($event)">
          <h2 id="richCartHeader" *ngIf="cartLocalData">{{cartLocalData.yourBasket}}</h2>
          <div *ngIf="cart; else emptyCart">
              <div *ngIf="cart.totalItems !== 0 ; else emptyCart">
                <div class="view-bag-loader mt-20" *ngIf="refreshBasket">
                  <div class="loading-gif w-100 text-center">
                    <p class="loading-txt" *ngIf="singletonServ.translateData"> {{singletonServ.translateData.loadingTxt.loading}} </p>
                    <img class="img-fluid pt-40" src="/assets/Redesign/loading-icon.gif">
                  </div>
                </div>
                  <ul *ngIf="!refreshBasket">
                      <li *ngFor="let entry of cart.entries;let k=index;" [hidden]="getHidden(entry)"
                      id="{{'richCart'+entry.product.code}}"
                      [ngClass]="{'entry-cta-disabled':entry.giveAway}">
                          <div class="col-md-12 product-info">
                              <div class="col-md-3 pdt-img">
                                <a class="basket-item-Image" routerLink="{{getRouterPath(entry)}}" routerLinkActive="active"
                                (click)="onShowProduct($event,entry)" [ngClass]="{'rich_cart-pointer-event':isPointer}">
                                <picture class="full-img" *ngIf="!entry.isBundle;else bundleTypeImage">
                                  <source
                                    srcset="{{entry.product.amplienceImageName}}.webp?$smallImg$ 1x, {{entry.product.amplienceImageName}}.webp?$smallImgRetina$ 2x "
                                    type="image/webp">
                                  <source
                                    srcset="{{entry.product.amplienceImageName}}?$smallImg$ 1x, {{entry.product.amplienceImageName}}?$smallImgRetina$ 2x"
                                    type="image/jpeg">
                                  <img src="{{entry.product.amplienceImageName}}?"
                                    srcset="{{entry.product.amplienceImageName}}?$smallImg$ 1x,{{entry.product.amplienceImageName}}?$smallImgRetina$ 2x"
                                    alt="{{entry.product.productDisplayName}}" crossorigin="anonymous" />
                                </picture>
                                <ng-template #bundleTypeImage>
                                  <picture *ngIf="entry.bundleTemplateId.includes('PickandMix')">
                                    <picture class="full-img">
                                      <source [srcset]="entry.bundleImage" type="image/webp">
                                      <source [srcset]="entry.bundleJPGImage" type="image/jpeg">
                                      <img [src]="entry.bundleJPGImage" alt="Bundle Image">
                                    </picture>
                                  </picture>
                                  <img class="giftBuilderImage"
                                  src="{{giftBoxDtls[entry.bundleTemplateId.slice(-1)].boxamplienceImageName}}?fmt=auto"
                                  srcset="{{giftBoxDtls[entry.bundleTemplateId.slice(-1)].boxamplienceImageName}}?fmt=auto&w=1440&sm=aspect&aspect=1:1 1x,
                                  {{giftBoxDtls[entry.bundleTemplateId.slice(-1)].boxamplienceImageName}}?fmt=auto&w=2880&sm=aspect&aspect=1:1 2x"
                                  alt="gift builder box" *ngIf="entry.bundleTemplateId.includes('GiftBuilder') && giftBoxDtls">

                                </ng-template>
                              </a>
                              </div>
                              <div class="pdt-details col-md-9" *ngIf="cartLocalData">
                                  <div class="pdt-name">
                                    <a routerLink="{{getRouterPath(entry)}}" routerLinkActive="active"
                                    (click)="onShowProduct($event,entry)" *ngIf="!entry.isBundle; else bundleType"
                                    [innerHtml]="entry.product.productDisplayName">
                                  </a>
                                  <ng-template #bundleType>
                                    <span class="order-product-name" *ngIf="entry.bundleTemplateId" >
                                      {{entry.bundleTitleName}}
                                      <ul class="bundle-list" style="list-style: outside disc">
                                        <div  *ngFor="let bottle of entry['product']; let j = index">
                                          <ng-container *ngIf="entry?.bundleTemplateId.includes('GiftBuilder'); else pickMixViewBag">
                                              <div *ngFor="let botl of bottle?.eachItemsInGiftBuilder">
                                                <li class="padding">
                                                  <span [innerHtml]="botl"></span>
                                                </li>
                                              </div>
                                          </ng-container>
                                          <ng-template #pickMixViewBag>
                                            <li
                                             *ngIf="!bottle.product.isSample"
                                             class="padding"

                                              >
                                                <span class=""
                                                >{{
                                                  getBundleProductQuntity(entry,bottle)
                                                }}&nbsp;x&nbsp;</span
                                              >
                                              <span [innerHtml]="bottle.product.productDisplayName"></span>
                                            </li>
                                          </ng-template>

                                      </div>
                                         </ul>
                                         <ul
                                         class="bundle-list"
                                         *ngIf="entry['product'][0]['isTravelPouchSelected'] && !entry?.bundleTemplateId.includes('GiftBuilder')"
                                       >
                                         <li class="padding travel-pouch"  >
                                           <span>1&nbsp;x&nbsp;</span>
                                           <span *ngIf="entry['product'][0].travelPouchProductName" [innerHtml]="entry['product'][0].travelPouchProductName"></span>
                                           <span *ngIf="!entry['product'][0].travelPouchProductName">{{ (singletonServ.translateData&&singletonServ.translateData.pickMix) ?singletonServ.translateData.pickMix.travelPouch:''}}</span>
                                         </li>
                                       </ul>
                                       </span>
                                </ng-template>

                                  </div>
                              </div>
                          </div>

                          <div class="col-md-12 product-info">
                              <div class="col-md-3" ></div>
                              <div class="col-md-9 pdt-gty-price">
                                  <div class="col-md-4 pdt-price">
                                      <ng-container *ngIf="!entry?.giveAway">
                                        <span class="d-block w-auto" [ngClass]="{'discount-price': entry.product.originalPrice && !(entry.pickAgain&&entry.promotionMessage)}" *ngIf="entry.isBundle" [innerHtml]="getBundlePrice(entry)"></span>
                                        <span class="d-block w-auto" [ngClass]="{'discount-price': entry.product.originalPrice && !(entry.pickAgain&&entry.promotionMessage)}" *ngIf="!entry.isBundle" [innerHtml]="getTotalPrice(entry)"></span>
                                      </ng-container>
                                      <span *ngIf="entry?.giveAway">{{singletonServ?.translateData?.giftMsg?.free}}</span>

                                  </div>
                                  <div class="col-md-4 pdt-qty" *ngIf="!entry?.giveAway">
                                      <div class="qty-select">
                                          <span>
                                              <div class="col-md-12 form-floating mb-custom-select">
                                                  <select *ngIf="!entry.isBundle" class="form-select  border_grey" id="qtySelect" aria-label="product quantity" [(ngModel)]="entry.quantity" (change)="onChangeQuant($event,entry)">
                                                      <option *ngFor="let qty of productQty[entry?.product?.code]" [value]="qty">{{qty}}</option>
                                                  </select>
                                                  <select *ngIf="entry.isBundle" class="form-select  border_grey" [ngClass]="{'gbSelect':entry.bundleTemplateId.includes('GiftBuilder')}" aria-label="product quantity" [(ngModel)]="entry.quantity" (change)="onChangeQuant($event,entry)">
                                                    <option *ngFor="let qty of productQty[entry?.bundleNo]" [value]="qty">{{qty}}</option>
                                                  </select>
                                              </div>
                                          </span>
                                      </div>
                                      <div class="pdt-remove"><i class="icon-bin-md" aria-label="remove quantity" (click)="onSpliceItem($event,entry,k)" *ngIf="!entry.allowedQuantity"></i></div>
                                  </div>
                              </div>
                          </div>
                          <div class="col-md-12 de-info-block">
                              <div class="col-md-3" ></div>
                              <div class="col-md-9 de-base-price" *ngIf="isocode==='de' || isocode==='eu'">
                                <span *ngIf="entry.product.basePriceForProduct"
                                [innerHtml]="entry.product.basePriceForProduct ">
                              </span>
                              </div>
                          </div>
                          <div [ngClass]="{'subscription-vbag':entry.product.subscriptionPLPIcon}">
                          <og-offer id="product-codecart-{{entry.product.code}}" location="bagmodal"></og-offer>
                          </div>
                      </li>
                  </ul>
              </div>
          </div>
          <ng-template #emptyCart>
              <div class="basketDropdownCartHeader">
                <ul>
                  <li class="emptycart-list">
                    <span>{{singletonServ?.translateData?.yourBagEmpty}}</span>
                </li>
                </ul>
              </div>
          </ng-template>
      </div>
        <div class="modal-footer" >
          <div class="row">
            <div class="col-md-12">
              <div  class="sub-totalItems">
                <div class="col-md-12">
                  <h3 class="sub-total-content" *ngIf="cartLocalData">
                    {{singletonServ?.translateData?.subTotal}}&nbsp;&nbsp;&nbsp;{{ (cart?.subTotal?.formattedValue) ? cart?.subTotal?.formattedValue : singletonServ?.translateData?.defaultSubTotal }}</h3>

                </div>
                <!-- potential points for guest and reg user non-loyalty member-->
                <div *ngIf="isAnnexCloudEnabled && !isLoyaltyEnrolled && cart && cart.totalItems!==0" class="displayPotentialPoints">
                  <div class="border-loyalty pt-1" *ngIf="!isLoyaltyEnrolled"></div>
                  <div class="row mx-0 mt-3 pt-1 align-items-center">
                    <div class="col-3 p-0 ord-rewards-club-logo">
                      <img class="img-fluid" src="/assets/Redesign/MB_Rewards Club_Colour_Stamp.svg">
                    </div>
                    <div class="col-9 p-0">
                      <div class="col-12 p-0">
                        <span class="ord-summary-headers">{{(singletonServ.translateData)?singletonServ.translateData.checkoutFlow.basketloyaltyPoints:''}}</span>
                      </div>

                      <div class="col-12 p-0"><span class="loyaltyDesc">
                        {{(singletonServ.translateData)?singletonServ.translateData.checkoutFlow.basketOverlayloyaltyPointsDesc1RegGuest:''}}&nbsp;<span class="loyaltyPoints">{{loyaltyPoints}}</span>&nbsp;{{(singletonServ.translateData)?singletonServ.translateData.checkoutFlow.basketOverlayloyaltyPointsDesc2:''}}</span></div>
                    </div>
                  </div>
                  <div class="row m-0 p-0">
                    <div class="col-12 p-0 text-center"><span><a class="loyaltyDesclink" routerLink="/store/rewards" routerLinkActive="active" target="_self" data-bs-dismiss="modal">{{(singletonServ.translateData)?singletonServ.translateData.checkoutFlow.guestOverlayloyaltyClubLink:''}}</a></span></div>
                  </div>
                  <div class="border-loyalty pt-1" *ngIf="!isLoyaltyEnrolled"></div>
                  </div>
                <!-- potential points for guest and reg user non-loyalty member-->
                <!-- potential points for reg user who is a loyalty member-->
                <div *ngIf="isAnnexCloudEnabled && isLoyaltyEnrolled && cart && cart.totalItems!==0" class="displayPotentialPoints">
                  <div class="border-loyalty pt-1" *ngIf="isLoyaltyEnrolled"></div>
                  <div class="row mx-0 mt-3 pt-1 align-items-center">
                    <div class="col-3 p-0 ord-rewards-club-logo">
                      <img class="img-fluid" src="/assets/Redesign/MB_Rewards Club_Colour_Stamp.svg">
                    </div>
                    <div class="col-9 p-0">
                      <div class="col-12 p-0">
                        <span class="ord-summary-headers">{{(singletonServ.translateData)?singletonServ.translateData.checkoutFlow.basketloyaltyPoints:''}}</span>
                      </div>
                      <div class="col-12 p-0"><span class="loyaltyDesc">
                        {{(singletonServ.translateData)?singletonServ.translateData.checkoutFlow.basketOverlayloyaltyPointsDesc1:''}}&nbsp;<span class="loyaltyPoints">{{loyaltyPoints}}</span>&nbsp;{{(singletonServ.translateData)?singletonServ.translateData.checkoutFlow.basketOverlayloyaltyPointsDesc2:''}}</span></div>
                    </div>
                    </div>
                    <div class="border-loyalty pt-1" *ngIf="isLoyaltyEnrolled"></div>
                  </div>
                <!-- potential points for reg user who is a loyalty member-->
                <div class="col-md-12 pt-24 d-flex justify-content-center" >
                  <button class="btn-black btn-bottom" (click)="onViewBasket($event);bagModal.click()">{{singletonServ?.translateData?.bagCheckout}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div><!-- modal-content -->
    </div><!-- modal-dialog -->
  </div><!-- modal -->
</div>
