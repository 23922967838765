<div id="header-submenuwrap" >
<a href="#main-content" class="skiplink" (click)="addSkipLinkHandler($event)">Skip to main content</a>

<div 
  class="header-top"
  [ngClass]="{ 'mb-menu-ML': sidemenuStatus, 'mb-menu-ML-none': !sidemenuStatus }" *ngIf="greyBannerAmpData as greyBannerData"
>
<!-- Fresh Relevance Countdown Timer -->
<div class="fr-timer" *ngIf="greyBannerData.enableTimer else amplienceGreyBanner">
  <div class="tmspslot" data-k="qa5buoy" [attr.data-slid]="frSiteCode+'-header-timer'" data-p="1" ></div>
</div>
<!-- Amplience banner text condition -->
<ng-template #amplienceGreyBanner> 
  <a (click)="onFreeDeliveryClick($event)" href="{{greyBannerData.bannerinfo.callToActionUrl}}">{{greyBannerData.bannerinfo.callToActionText}}</a>
</ng-template>

</div>

<!--Please Note, below app-cart and app-header-submenu components need to mouve to proper component in MB redesign-->
<app-header-submenu #submenuContainer [message]="message" [menuLocalData]="singletonServ.translateData" (onDiscardSubscription)="discardSubscription($event)"></app-header-submenu>
</div>
<div>
<app-cart
#cartRefElement
[cartLocalData]="singletonServ.translateData" 
[message]="message"
[renderCart]="renderCart"
></app-cart>
</div>
