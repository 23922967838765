import { Component, OnChanges, Input, SimpleChange, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import {SingletonService} from '../../services/singleton.service';
import { Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import {TranslateServiceSubService} from '../../pipe/translate-service-sub.service';
import { Router,ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from "ngx-device-detector";
import {HeaderComponentService} from "../header/header.service";
import { WebExtendService } from "src/app/services/webExtend.service";

declare var screen:any;
@Component({
  selector: 'app-mb-bread-crumb',
  templateUrl: './mb-bread-crumb.component.html',
  styleUrls: ['./mb-bread-crumb.component.scss']
})
export class MbBreadCrumbComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() breadcrumb:Array<any>;
  public routerLinkVariable = "/store";
  listData:any;
  isEmarsysEnabled: boolean;
  @Input() menuData:any;
  localData:any;
    constructor(
      public singletonServ:SingletonService,
      public titleService:Title,
      public translate: TranslateServiceSubService,
      public location: Location,
      public deviceService: DeviceDetectorService,
      public router: Router,
      public route :ActivatedRoute,
      public headerServ:HeaderComponentService,
      public webExtend:WebExtendService

      ) { }
  
   ngOnChanges(changes: { [propKey: string]: SimpleChange })  {  
      if (changes['breadcrumb']['currentValue']){
        if (changes['breadcrumb']['currentValue'] != undefined){
         this.listData=changes['breadcrumb']['currentValue'];
        }
      }
    }

    ngAfterViewInit(){
      this.createJSONLDBreadcrumblist()
    }
    ngOnInit() {
      const baseSite =this.singletonServ.catalogVersion;    
      this.singletonServ.getCatalogData().subscribe(resp => {
        this.isEmarsysEnabled = (resp["enableEmarsysFlow"]);
      },
        error => {}
      );  
    }

    onbreadcrumbClick(event,data,k){
      event.stopPropagation();
      event.preventDefault();
      if(event.ctrlKey && event.which === 1){
        const url ='/store'+data.url.replace('/c/','/');
        this.titleService.setTitle(data.titleName);
        window.open(url); 
      }else{
        if(!data.product){
          if(data.categoryDisplayName){
            if(screen.width<=874){
                const url ='/store'+data.url.replace('/c/','/');
                this.titleService.setTitle(data.titleName);
                this.router.navigate([url]);  
           } else {
                const url ='/store'+data.url.replace('/c/','/');
                this.titleService.setTitle(data.titleName);
                this.router.navigate([url]); 
           }  
          } else if (data.route){
            if(data.route.includes("https://")) window.open(data.route)
            else this.router.navigate([data.route]); 
          }
       }
      }
    }
    goToHome(){
      this.router.navigate(['store']);
    }
    // JSON-LD markup for Breadcrumbs - audit fix
    createJSONLDBreadcrumblist(){
      // Set initial position
      var position = 1;

      // Create breadcrumb object
      var breadcrumb = {
        position: 0,
        name: "",
        item: ""
      }

      // Initial array for list items
      var listArray = [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": window.location.origin + this.routerLinkVariable
      }]

      // loop through breadcrumbs
      this.listData.forEach(el => {
        if(el.name && el.route){
        let newItem = Object.create(breadcrumb);
        newItem["@type"] = "ListItem";
        position++;
        newItem.position = position;
        newItem.name = el.name;
        newItem.item =
          window.location.origin +
          this.routerLinkVariable +
          (el.route.indexOf("/c/") !== -1 ? el.route.replace("/c/", "/") : el.route);
        listArray.push(newItem);
        }
      });
      // Extract individual values for category path
      const catPath1 = JSON.stringify(listArray[1].name).replace(/"/g, '');
      const catPath2 = JSON.stringify(listArray[2].name).replace(/"/g, '');
      console.log("Bread Crumb Component - "+catPath1+">"+catPath2);
      const catPath = catPath1+" > "+catPath2 ; 
      if(this.isEmarsysEnabled) {
        this.webExtend.webExtendCategoryLandingPage(catPath);
      }

      // Create overarching Schema object
      var breadcrumbSchema = {
        "@context": "https://schema.org/",
        "@type": "BreadcrumbList",
        "itemListElement": listArray
      };

      var finalSchema = JSON.stringify(breadcrumbSchema);

      // Create new Script
      var newScript = document.createElement('script');
      newScript.type = 'application/ld+json';
      newScript.id = 'JSONLD_markup';
      newScript.text = finalSchema;

      // Set head variable with browser fallback
      var head = document.head || document.getElementsByTagName("head")[0];
      head.appendChild(newScript);
    }
  ngOnDestroy() {
    let node = document.getElementById("JSONLD_markup");
    if(node) node.parentNode.removeChild(node);
  }
}
