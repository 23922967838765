import { Inject,Injectable, NgZone,PLATFORM_ID, RendererFactory2, ViewEncapsulation  } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import {Observable, pipe, throwError} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import decode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private message: string;

  constructor(   @Inject(PLATFORM_ID) public platformId: Object,
  public _router: Router
  ){}
  /**
   * this is used to clear anything that needs to be removed
   */
  clear(): void {
    if (isPlatformBrowser(this.platformId)) {
    localStorage.clear();
    }
  }

  /**
   * check for expiration and if token is still existing or not
   * @return {boolean}
   */
  isAuthenticated(): boolean {
    if (isPlatformBrowser(this.platformId)) {
    return localStorage.getItem('token') != null && !this.isTokenExpired();
    }
  }
  isTokenExpired(): boolean {
    return false;
  }

  loginAdmin(): void {

  }

  login(): void {

  }

  /**
   * this is used to clear local storage and also the route to login
   */
  logout(): void {
    this.clear();
  }

  decode() {
    if (isPlatformBrowser(this.platformId)) {
    return decode(localStorage.getItem('token'));
    }
  }
}