import { Inject,Component, OnInit,ViewEncapsulation,ViewChild,ElementRef,HostListener, AfterViewInit } from '@angular/core';
import {FeatureComponentService} from "../../features/features.service";
import {MetaService} from "../../services/meta.service";
import { SingletonService } from "../../services/singleton.service";
import { ActivatedRoute } from '@angular/router';
import { Router } from "@angular/router";
import { GtmMethodService } from '../../services/gtmmethods.service';
import { AmplienceService } from "../../services/amplience.service";
import { SEOService } from "../../services/seo.service";
import * as _ from "lodash";
import md from 'markdown-it';
import {  DeliveryKey } from '../../amplience.constant';
import { DeviceDetectorService } from "ngx-device-detector";

declare var $:any;
declare var AmpCa: any;
declare var screen:any;

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutUsComponent implements OnInit,AfterViewInit {
  @ViewChild("aboutus") aboutus: ElementRef;
  param1: string;
  private markdown;
  ampContent: any
  deviceInfo: any;
  breadcrumb: Array<any>;
  mobileDevice: boolean;
  _path:string;
  countryCode: string;
  sitename: string;
  singleMBannerAboutUS: any;
  circleImageOverlayBannerAboutUS: any;
  additionalMsgBannerAboutUS: any;
  perfumerProfile: any;
  prodSection: any;
  videoUrl: any;
  heroBannerAboutUS: any;
  constructor(
    public singletonServ: SingletonService,
    public metaService: MetaService,
    public gtmServe: GtmMethodService,
    public router: Router,
    public featureServ:FeatureComponentService,
    private route: ActivatedRoute,
    private seoService: SEOService,
    public ampService: AmplienceService,
    public deviceService: DeviceDetectorService,
  ) { 
    this.markdown=md({html: true});
    this.route.queryParams.subscribe(params => {
      this.param1 = params['api'];    
  });
  }

  ngOnInit() {
    this.metaService.createCanonicalURL();
    this.getDeviceInfo();
    this.getBreadCrumbs();
    this.getAmplienceContent();
  }
  ngAfterViewInit(){
    const baseSite = this.singletonServ.catalogVersion;
    if(baseSite){
      this.setLang(baseSite.lngCode);
   } 
   setTimeout(function() {
    document.body.classList.add('ready');
  }, 1000);
  }
  setLang(lng) {
    const that=this; 
    this.featureServ.getStaticContent(lng).subscribe((response:any) => {
      const _data:Array<any> = response['feature']['about-us'];
      that.constructFeaturelayoutContent(_data);
    });
  }
  
  constructFeaturelayoutContent(_data:any){
    this.setAmpContent(_data);
  }
  setAmpContent(obj) {
    const that=this;
    const baseSite = this.singletonServ.catalogVersion;
      AmpCa.utils = new AmpCa.Utils();
      let api;
      if(this.param1==undefined){
        api="https://c1.adis.ws";
      }
      else{
        api="https://"+this.param1;
      }
      AmpCa.utils.getHtmlServiceData({
        auth: {
          baseUrl: api,
          id: obj.content,
          store: "moltonbrown",
          templateName: obj.templateName,
          locale: baseSite.locale
        },
        callback: function(data) {
          if (data) {
           // that.aboutus.nativeElement.innerHTML = data;
          }
        }
      });
  }
  getDeviceInfo() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    if (isMobile || isTablet) {
      this.mobileDevice = true;
    } else {
      this.mobileDevice = false;
    }
  }
  getAmplienceContent(){
    const baseSite = this.singletonServ.catalogVersion;  
    this.ampService.getContentByKey(DeliveryKey.aboutUs[baseSite.isoCode]).subscribe(resp=>{    
      this.ampContent = resp['content']; 
      this.singleMBannerAboutUS = this.ampContent.singleMessageBannerItems; 
      this.circleImageOverlayBannerAboutUS = this.ampContent.circleImageOverlayBanner;
      this.additionalMsgBannerAboutUS = this.ampContent.bottomSingleMessageBannerItems;
      this.heroBannerAboutUS = this.ampContent.heroBanner;
     
      if(this.heroBannerAboutUS.description && this.heroBannerAboutUS.description.text) this.heroBannerAboutUS.description.text = this.markdown.render(this.heroBannerAboutUS.description.text);
      if(this.circleImageOverlayBannerAboutUS.description && this.circleImageOverlayBannerAboutUS.description.text){
        this.circleImageOverlayBannerAboutUS.description.text = this.markdown.render(this.circleImageOverlayBannerAboutUS.description.text);
      } 
      this.singleMBannerAboutUS.forEach(msg => {
        if(msg.description && msg.description.text) msg.description.text = this.markdown.render(msg.description.text);
       });
    });
   
    }
    getBreadCrumbs(){
      //about-us
        if (this.mobileDevice) {
          if (this.countryCode == "DE") {
            this.breadcrumb = [
              { name: "Startseite", route: "/store/index" },
              { name: "Kundendienst", route: "/store/about-us" }
            ];
          } else {
            this.breadcrumb = [
              { name: "Home", route: "/store/index" },
              { name: "About Us", route: "/store/about-us" }
            ];
          }
        } else {
          if (this.countryCode == "DE") {
            this.breadcrumb = [
              {
                name: "Kontaktieren Sie un",
                route: "/store/about-us",
              },
            ];
          } else {
            this.breadcrumb = [
              { name: "About Us", route: "/store/about-us" },
            ];
          }
        }
      
      
    }
}
