import { Component, OnInit,Inject,PLATFORM_ID } from '@angular/core';
import {SingletonService} from '../services/singleton.service';
import {WishlistService} from '../component/wish-list/wish-list/wishlist.service';
import {profileComponentService} from '../component/profile-form/profile.service';
import { isPlatformBrowser } from '@angular/common';
import { DeviceDetectorService } from "ngx-device-detector";
import {ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-shared-wishlist',
  templateUrl: './shared-wishlist.component.html',
  styleUrls: ['./shared-wishlist.component.scss']
})
export class SharedWishlistComponent implements OnInit {
  pageLoad: boolean;
  user: any;
  favouriteList: any;
  totalProducts: number;
  currentloadedproductcount: any;
  sliceCards =12;
  siteSpecific: boolean;
  productCode: any;
  bannerTitle: any;
  deviceInfo: any;
  mobileDevice: boolean;
  breadcrumb:any;
  isocode: any;
  token: any;
    constructor(
    public singletonServ: SingletonService,
    private WishlistService: WishlistService,
    public profileServ: profileComponentService,
    @Inject(PLATFORM_ID) public platformId: Object,
    public deviceService: DeviceDetectorService,
    public route: ActivatedRoute,
  ) { }

  ngOnInit() {
   const baseSite = this.singletonServ.catalogVersion;
   const getWishlistID = this.route.snapshot.params.wishlistID;
   this.isocode=baseSite.lngCode;
   this.profileServ.generateToken(baseSite).subscribe((response) => {
        this.token = response['access_token'];
        this.pageLoad=true;
       this.getSharedWishList(getWishlistID);
    })
    if (baseSite.isoCode == "DE") {
      this.siteSpecific = false;
    } else {
      this.siteSpecific = true;
    }
   
  }
   //get share wishlist products
  getSharedWishList(wishlistID){
    const baseSite=this.singletonServ.catalogVersion;
    const id =wishlistID;
    this.WishlistService.shareWishList(baseSite,id).subscribe(
      (response:any) => {
        if ( response ) {  
          this.favouriteList=response['products'];
          this.bannerTitle = response.name;
          this.getDeviceInfo();
          this.getBreadCrumb();
          if(this.favouriteList && this.favouriteList.length){
            this.totalProducts=this.favouriteList.length;
            } 
            if(this.totalProducts>12){
            this.currentloadedproductcount = this.sliceCards;
            }
            else{
              this.currentloadedproductcount = this.totalProducts;
            }
            this.pageLoad=false;
    }
    });
  }
  
  showCards(){
    const total_difference= this.totalProducts - this.currentloadedproductcount;
    if(total_difference > 12)
    {
      this.currentloadedproductcount=this.currentloadedproductcount + 12;
    }
    else{
      this.currentloadedproductcount=this.currentloadedproductcount + total_difference;
    }
    this.sliceCards=this.sliceCards+12; 
  }
   backtotop()
  {
  $(window).scrollTop(0);
  }

  getRouterPath(data) {
    if(data.url) {
      if (data.url.indexOf('/c/') !== -1) {
        const url = '/store' + data.url.replace('/c/', '/');
        return url;
    } else {
        const url = '/store' + data.url.replace('/p/', '/');
         return url;
    }
    }
  }

  //add to cart
  addToBasket(product) {
    const baseSite = this.singletonServ.catalogVersion;
        const productObj = {
          product: { code: product['code'] },
          quantity: 1
        };
        //check for registered user
        let _user: any = {};
        if(this.singletonServ.getStoreData(baseSite.reg)){
          _user = JSON.parse(this.singletonServ.getStoreData(baseSite.reg));
        }
        //check for guest user
        else if(this.singletonServ.getStoreData(baseSite.guest)){
          _user = JSON.parse(this.singletonServ.getStoreData(baseSite.guest));
          _user.code=_user["guid"];
          _user['email']='anonymous';
        }
        
          if (_user.code) {
            this.storeProductTocart(
              productObj,
              _user.token,
              _user.code,
              _user.email
            );
          } else {
            this.registerToCart(productObj, _user.token, _user.email);
          }
  }
  storeProductTocart( body, token, code, email) {
    const baseSite=this.singletonServ.catalogVersion;
    this.profileServ
      .storeCurrentUserProducts(baseSite,body, token, code, email)
      .subscribe((response:any) => {
          const obj = {
            code: body['product']['code'],
            showCartPopUp: true,
            unstreamCartData:response
          };
          this.singletonServ.sendMessage(obj);
          const isMobile = this.deviceService.isMobile();
          if(isMobile) {
            this.productCode = body['product']['code'];
            $("#successMessage"+ this.productCode).css("display","block");
            setTimeout(function() {
              $(".successMessage").css("display","none");
            }, 5000); 
          }
        //   if(isMobile){
        //    this.singletonServ.scrollToTarget("#miniRichCart");
        //   } else{
        //    this.singletonServ.scrollToTarget("#rich_cart");
        //  }
        },
        err => {
          if(err.error){
            if(err.error["errors"]){
              if(err.error["errors"][0]){
                if(err.error["errors"][0]['type']== "InvalidTokenError") {
                  if(!baseSite.csAgent){
                  this.profileServ.generateToken(baseSite).subscribe((resp)=>{
                    const _reg=(email!='anonymous')?true:false;
                    if(_reg){
                      const user=JSON.parse(this.getBrowserSessionData(baseSite.reg));
                      user.token= resp["access_token"];
                      this.setBrowserSessionData(baseSite.reg, JSON.stringify(user));
                      this.  storeProductTocart( body, resp["access_token"], code, email) ;
                    }else{
                      const user=JSON.parse(this.getBrowserSessionData(baseSite.guest));
                      user.token= resp["access_token"];
                      this.setBrowserSessionData(baseSite.guest, JSON.stringify(user));
                      this.  storeProductTocart( body, resp["access_token"], code, email) ;
                    }
                   });
                  }else{
                    this.singletonServ.sendMessage({invalidAgentToken:true});
                  }
                }
              }
              }
             }
        }
      );
  }
  registerToCart( body, token, email) {
    const baseSite = this.singletonServ.catalogVersion;
    this.profileServ.createRegisterCart(baseSite, token, email).subscribe(
      response => {
        if (this.getBrowserSessionData(baseSite.reg)) {
          const user = JSON.parse(this.getBrowserSessionData(baseSite.reg));
          user['code'] = response['code'];
          this.setBrowserSessionData(baseSite.reg, JSON.stringify(user));
        }
        this.storeProductTocart(
          body,
          token,
          response['code'],
          email
        );
      },
      err => {
        if(err.error){
          if(err.error["errors"]){
            if(err.error["errors"][0]){
              if(err.error["errors"][0]['type']== "InvalidTokenError") {
                if(!baseSite.csAgent){
                this.profileServ.generateToken(baseSite).subscribe((resp)=>{
                  const _reg=(email!='anonymous')?true:false;
                  if(_reg){
                    const user=JSON.parse(this.getBrowserSessionData(baseSite.reg));
                    user.token= resp["access_token"];
                    this.setBrowserSessionData(baseSite.reg, JSON.stringify(user));
                    this.   registerToCart(body,resp["access_token"], email);
                  }else{
                    const user=JSON.parse(this.getBrowserSessionData(baseSite.guest));
                    user.token= resp["access_token"];
                    this.setBrowserSessionData(baseSite.guest, JSON.stringify(user));
                    this.    registerToCart(body,resp["access_token"], email);
                  }
                 });
                }else{
                  this.singletonServ.sendMessage({invalidAgentToken:true});
                }
              }
            }
            }
           }
      }
    );
  }

  getBrowserSessionData(key){
    if (isPlatformBrowser(this.platformId)) {
      return this.singletonServ.getStoreData(key);
    }
  }
  setBrowserSessionData(key,data){
    if (isPlatformBrowser(this.platformId)) {
      this.singletonServ.setStoreData(key,data);
    }
  }

  getDeviceInfo() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    if (isMobile || isTablet) {
      this.mobileDevice = true;
    } else {
      this.mobileDevice = false;
    }
  }
  
  getBreadCrumb(){
    if (this.mobileDevice) {
        this.breadcrumb = [
          { name: "Home", route: "/store/index" },
          { name: this.bannerTitle },
        ];
      }
      else {
        this.breadcrumb = [
          { name:  this.bannerTitle},
        ];
      }
    }
  
}
