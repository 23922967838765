<app-mb-bread-crumb [breadcrumb]="breadcrumb"></app-mb-bread-crumb> 
<section class="d-flex justify-content-center">
    <div class="hero-banner">
       
    </div>
    <div class="container-1">
    </div>
    <div class="container-2">
    </div>
    <div class="container-3">
    </div>
    <div class="container-4">
    </div>
    <div class="container-5">
    </div>
    <div class="container-6">
    </div>
    <div class="container-7">
    </div>
    <div class="container-8">
    </div>
</section>