<nav class="breadcrumb-nav" *ngIf="category&&category.categoryPath">
  <ul class="flex-align space-between">
      <li>
          <a class="text-link"  href="/store/journal"  >
              <span class="arrow-left"></span><span class="black-color">{{(singletonServ.translateData && singletonServ.translateData.staticPageData)? singletonServ.translateData.staticPageData.moreStories:''}}</span>    
          </a>
      </li>
      <li *ngIf="category"><a  class="text-link" href="/store/journal">{{(singletonServ.translateData && singletonServ.translateData.staticPageData)? singletonServ.translateData.staticPageData.Journal:''}}</a> / <a href="{{(category.categoryPath)?category.categoryPath:''}}" ><span class="black-color font-weight-bold">{{category.categoryName}}</span></a></li>
  </ul> 
</nav>
<div  #candlecaretip>
</div>
<div class="bcc-footer"  *ngIf="gmailMessage">
  <section class="social-section">
    <div class="content-container">
        <div class="rowComponents">
            <div class="col-sm-12">
                <h4 class="share-heading georgia italic">{{(singletonServ.translateData && singletonServ.translateData.staticPageData)?singletonServ.translateData.staticPageData.sharethisStory:''}}</h4>
            </div>
        </div>
        
        <div class="rowComponents" >
            <div class="col-sm-12" *ngIf="locationAddress">
                <div class="social-container flex-align justify-center">                    
                    <a href="https://www.facebook.com/sharer/sharer.php?u={{locationAddress.href}}" target="_blank"  class="social-btn social-btn-ie fb-color">
                        <span class="social-icons icon-fb" aria-hidden="true"></span>
                    </a>
                    <a href="https://twitter.com/intent/tweet?text={{twitterMessage}}{{locationAddress.href}}" target="_blank"  class="social-btn social-btn-ie tw-color">
                        <span class="social-icons icon-tw" aria-hidden="true"></span>
                    </a>
                     <a href="mailto:?Subject=Thought you might like this…&amp;Body={{gmailMessage}}{{locationAddress.href}}" target="_blank"  class="social-btn social-btn-ie em-color">
                        <span class="social-icons icon-em" aria-hidden="true"></span>
                    </a>
                </div>
            </div>
       
        </div>
        
    </div>    
  </section>
  </div>